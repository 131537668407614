import React, { useEffect, useState } from 'react';
import { phoneMaskNumberInit, validatePhoneNumber } from "../../../../helperFunctions";
import './AddNewClinic.scss'
import { addNewClinicFetch } from "../../../../requests/rep_requests";
import Preloader from "../../../Preloader/Preloader";
import PopUp from "../../../PopUp/PopUp";
import { useNavigate } from "react-router-dom";

const AddNewClinic = () => {

    useEffect( () => phoneMaskNumberInit() ) // PhoneMask Initialization

    let navigate = useNavigate()

    const [ preloader, setPreloader ] = useState( false )
    const [ updated, setUpdated ] = useState( false )
    const [ clinicSuccessfullyEstablished, setClinicSuccessfullyEstablished ] = useState( false )
    const [ newClinic, setNewClinic ] = useState( {
        name: '',
        first_name: '',
        last_name: '',
        phone: '',
        address: '',
        website: '',
        email: '',
        lat: null,
        lng: null,
        isEmailToBeSent: false
    } )
    const [ phoneError, setPhoneError ] = useState( '' )
    const [ emailError, setEmailError ] = useState( '' )
    const [ popupMessage, setPopupMessage ] = useState( '' )

    const inputsHandler = ( e ) => {
        let name = e.target.name
        let value = e.target.value
        setNewClinic( { ...newClinic, [ name ]: value } )
    }

    const phoneHandler = ( e ) => {
        let { valid, cleanPhoneNumber } = validatePhoneNumber( e )
        if ( valid ) {
            setPhoneError( '' )
            setNewClinic( { ...newClinic, phone: cleanPhoneNumber } )
        } else {
            setPhoneError( 'Invalid phone number' )
        }
    }

    const nameAdminNameHandler = ( e ) => {
        let name = e.target.value.split( ' ' )
        setNewClinic( { ...newClinic, first_name: name[ 0 ], last_name: name.slice( 1 ).join( ' ' ) } )
    }

    const emailHandler = ( e ) => {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        if ( !re.test( String( e.target.value ).toLowerCase() ) ) {
            setEmailError( 'Invalid email' )
        } else {
            setNewClinic( { ...newClinic, email: e.target.value } )
            setEmailError( '' )
        }
    }

    const formValidate = () => {
        if ( !newClinic.name ) return true
        if ( !newClinic.first_name ) return true
        if ( !newClinic.phone ) return true
        if ( !newClinic.email ) return true
        if ( !newClinic.lat ) return true
        if ( !newClinic.lng ) return true
        if ( phoneError ) return true
    }

    // FOR AUTOCOMPLETE ADDRESS
    const AutoComplete = ( e ) => {
        let autocomplete = new window.google.maps.places.Autocomplete( e.target )
        window.google.maps.event.addListener( autocomplete, 'place_changed', function () {
            let place = autocomplete.getPlace()
            setNewClinic( {
                ...newClinic,
                address: place.formatted_address,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            } )
        } )
    }

    const addNewClinicFUnc = () => {
        setPreloader( true )
        addNewClinicFetch( newClinic ).then( data => {
            if ( !('errors' in data) ) {
                setPopupMessage( `Clinic ${ newClinic.name } was created` )
                setClinicSuccessfullyEstablished( true )
                setUpdated( true )
                setPreloader( false )
            } else if ( 'errors' in data ) {
                setPopupMessage( `${ data?.errors }` )
                setUpdated( true )
                setPreloader( false )
            }
        } )
    }

    useEffect( () => {
        if ( clinicSuccessfullyEstablished && !updated ) {
            navigate( -1 )
        }
    }, [ updated ] )

    return (
        <div className='addNewClinic'>
            <div className='component__wrap white'>
                <div className="addNewClinic__title dashboard__component_title">
                    <h2>ADD NEW CLINIC</h2>
                </div>
                <div className='component__subtitle'>
                    <h3>Clinic Details</h3>
                </div>
                <form className="addNewClinic__form form">
                    <div className="form_field clinic_name">
                        <label htmlFor="name">Clinic Name</label>
                        <input type="text"
                               id='name'
                               autoComplete='new-password'
                               name='name'
                               onChange={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field clinic_primary_admin">
                        <label htmlFor="admin_name">Clinic Primary Admin (HCP)</label>
                        <input type="text"
                               id='admin_name'
                               autoComplete='new-password'
                               name='admin_name'
                               onChange={ ( e ) => {
                                   nameAdminNameHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field phone_number">
                        <label htmlFor="phone">Phone Number</label>
                        <input type="text"
                               id='phone'
                               name='phone'
                               onChange={ ( e ) => {
                                   phoneHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ phoneError }</p>
                    </div>
                    <div className="form_field address_field">
                        <label htmlFor="address">Address</label>
                        <input type="text"
                               id='address'
                               autoComplete='new-password'
                               name='address'
                               onChange={ ( e ) => {
                                   AutoComplete( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field website">
                        <label htmlFor="website">Website</label>
                        <input type="text"
                               id='website'
                               autoComplete='new-password'
                               name='website'
                               onChange={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field primary_admin_email">
                        <label htmlFor="email">Primary Admin Email</label>
                        <input type="text"
                               id='email'
                               name='email'
                               onChange={ ( e ) => {
                                   emailHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ emailError }</p>
                    </div>
                    <div className='form__trainingEmail'>
                        <input type="checkbox"
                               id='training_email'
                               className='form__checkbox'
                               checked={ newClinic.isEmailToBeSent }
                               onChange={ ( e ) => {
                                   setNewClinic( { ...newClinic, isEmailToBeSent: e.target.checked } )
                               } }
                        />
                        <label htmlFor="training_email">Send Training Email</label>
                    </div>
                </form>
                <button className="save_btn btn wide_btn beige_btn"
                        disabled={ formValidate() }
                        onClick={ ( e ) => {
                            e.preventDefault()
                            setPreloader( true )
                            addNewClinicFUnc()
                        } }
                >Save changes
                </button>
            </div>
            { !preloader || <Preloader/> }
            { !updated || <PopUp popUpMessage={ popupMessage }
                                 closeBtnTitle={ 'Close' }
                                 showPopUp={ setUpdated }
                                 closePopUp={ setUpdated }/> }
        </div>
    )
}

export default AddNewClinic