import React, { useEffect, useRef, useState } from 'react';
import { validatePhoneNumber } from "../../../../helperFunctions";
import IMask from "imask";
import { updateEmployee } from "../../../../requests/rep_selected_clinic_requests";
import { downloadBadge } from "../../../../requests/clinic_requests";
import { useClinics } from "../../../../stores/adminStore";

const EmployeeTableRow = ( { el } ) => {

    /************************* GLOBAL STORE ***************************/
    const currentClinicId = useClinics( state => state.currentClinicId )
    /******************************************************************/

    const [ edit, setEdit ] = useState( false )
    const [ employee, setEmployee ] = useState( {} )
    const [ phoneError, setPhoneError ] = useState( '' )
    const [ haveBeenChanges, setHaveBeenChanges ] = useState( false )

    useEffect( () => {
        setEmployee( el )
    }, [] )

    const phoneInput = useRef( null )
    useEffect( () => { // PhoneMask Initialization
        let element = phoneInput.current
        let maskOptions = {
            mask: '+{1}(000)0000000'
        }
        let mask = IMask( element, maskOptions )
        return () => mask.destroy()
    } )

    const inputHandler = ( e ) => {
        let name = e.target.name
        let value = e.target.value
        setEmployee( { ...employee, [ name ]: value } )
        setHaveBeenChanges( true )
    }

    const phoneHandler = ( e ) => {
        let { valid, cleanPhoneNumber } = validatePhoneNumber( e )
        if ( valid ) {
            setPhoneError( '' )
            setEmployee( { ...employee, phone_number: cleanPhoneNumber } )
            setHaveBeenChanges( true )
        } else {
            setPhoneError( 'Invalid phone number' )
            setHaveBeenChanges( true )
        }
    }

    const btnText = () => {
        if ( edit && !haveBeenChanges ) return 'Cancel'
        if ( edit && haveBeenChanges && !phoneError ) return 'Save'
        if ( edit && haveBeenChanges && phoneError ) return 'Cancel'
        if ( !edit ) return 'Edit'
    }

    const addNewEmployeeFunc = () => {
        const data = { ...employee, isEmailToBeSent: false }
        updateEmployee( data )
            .then( data => {
                if ( 'errors' in data ) {
                    console.log( data.errors )
                } else {
                    console.log( `${ data.name } updated` )
                }
            } )
    }

    return (
        <tr className={ 'table_row' + (edit ? ' edit' : '') }>
            <td>
                <div className='table_input_wrap'>
                    <input type="text"
                           name='name'
                           className='table_input'
                           readOnly={ !edit }
                           defaultValue={ employee.name }
                           onChange={ ( e ) => {
                               inputHandler( e )
                           } }/>
                    <br/>
                </div>
            </td>
            <td>
                <div className='table_input_wrap'>
                    <input type="text"
                           name='title'
                           className='table_input'
                           readOnly={ !edit }
                           defaultValue={ employee.title }
                           onChange={ ( e ) => {
                               inputHandler( e )
                           } }/>
                </div>
            </td>
            <td>
                <div className='table_input_wrap'>
                    <input type="tel"
                           className='table_input'
                           ref={ phoneInput }
                           readOnly={ !edit }
                           defaultValue={ employee.phone_number }
                           onChange={ ( e ) => {
                               phoneHandler( e )
                           } }/>
                    <span className='error_message'>{ phoneError }</span>
                </div>
            </td>
            <td>
                {/*<button className="table_btn download_badge"*/}
                {/*        onClick={ () => {*/}
                {/*            // setPreloader( true )*/}
                {/*            downloadBadge( el.id, currentClinicId ).then( ( data ) => {*/}
                {/*                let blob = new Blob( [ data ], { type: "application/pdf" } )*/}
                {/*                let link = document.createElement( "a" )*/}
                {/*                link.href = window.URL.createObjectURL( blob )*/}
                {/*                link.download = "Badge.pdf"*/}
                {/*                link.click()*/}
                {/*                // setPreloader( false )*/}
                {/*            } )*/}
                {/*        } }>*/}
                {/*    Download Badge*/}
                {/*</button>*/}
                <button className='table_btn'
                        onClick={ () => {
                            setEdit( !edit )
                            if ( edit && haveBeenChanges && !phoneError ) {
                                addNewEmployeeFunc()
                                setHaveBeenChanges( false )
                            }
                        } }>
                    { btnText() }
                </button>
            </td>
        </tr>
    );
};

export default EmployeeTableRow;