import React, { useEffect, useState } from 'react';
import IconSearch from "../../Icons/IconSearch";
import IconFilter from "../../Icons/IconFilter";
import IconCheckRound from "../../Icons/IconCheckRound";
import './RepDashboard.scss'
import { Link } from "react-router-dom";
import { getClinicList } from "../../../requests/rep_requests";
import Preloader from "../../Preloader/Preloader";
import { useClinics, useCompany } from "../../../stores/adminStore";

const RepDashboard = () => {

    /******************** GLOBAL STORE ***********************/
    const setCompanyId = useCompany( state => state.setCompanyId )
    const setClinicsGS = useClinics( state => state.setClinics )
    const setCurrentClinicId = useClinics( state => state.setCurrentClinicId )
    /********************************************************/

    const [ preloader, setPreloader ] = useState( true )
    const [ filterOptions, setFilterOptions ] = useState( {
        col_name: 'name',
        direction: 'desc',
        word: '',
    } )
    const [ clinics, setClinics ] = useState( [] )
    const [ searchClinic, setSearchClinic ] = useState( '' )

    useEffect( () => {
        setPreloader( true )
        getClinicList( filterOptions )
            .then( data => {
                let sortArr = data?.clinics.sort( ( a, b ) => b.top_clinic - a.top_clinic )
                setClinics( sortArr )
                setPreloader( false )
            } )
            .catch( () => {
                window.location.href = "/login"
            } )
    }, [ filterOptions ] )

    const filterColumn = ( param ) => {
        setPreloader( true )
        if ( filterOptions.direction === 'desc' ) {
            setFilterOptions( { ...filterOptions, col_name: param, direction: 'asc' } )
        } else {
            setFilterOptions( { ...filterOptions, col_name: param, direction: 'desc' } )
        }
    }

    const searchHandler = ( searchClinic, filterOptions ) => {
        setPreloader( true )
        setFilterOptions( {
            ...filterOptions,
            col_name: 'name',
            direction: 'desc',
            word: searchClinic,
        } )
        // getClinicList( filterOptions ).then( data => {
        //     if ( !('errors' in data) ) {
        //         setClinics( data?.clinics )
        //         setPreloader( false )
        //     } else {
        //         window.location.href = "/login"
        //     }
        // } )
    }

    useEffect( () => {
        localStorage.clear()
    }, [] )

    const createStoresArrAndSaveDataToGlobalStore = ( arr ) => {
        let clinicsArray = []
        arr.map( ( store, index ) => {
            clinicsArray[ index ] = {}
            clinicsArray[ index ][ 'id' ] = store.id
            clinicsArray[ index ][ 'name' ] = store.name
        } )
        setClinicsGS( clinicsArray )
        setCurrentClinicId( clinicsArray[ 0 ].id )
        localStorage.setItem( 'current_company_locations', JSON.stringify(clinicsArray) )
        localStorage.setItem( 'currentLocation_ls', clinicsArray[ 0 ].id)
    }

    return (
        <>
            <div className='dashboard'>
                <div className='component__wrap'>
                    <div className="your_clinic__title dashboard__component_title">
                        <h2>Your Clinics</h2>
                        <div className="search">
                            <form className="search_form">
                                <input type="text"
                                       placeholder='Search someone...'
                                       value={ searchClinic }
                                       onChange={ ( e ) => {
                                           setSearchClinic( e.target.value )
                                       } }
                                       className='form__input'/>
                                <IconSearch/>
                                <button className="btn wide_btn black_btn"
                                        onClick={ ( e ) => {
                                            e.preventDefault()
                                            searchHandler( searchClinic, filterOptions )
                                        } }
                                >Search
                                </button>
                            </form>
                        </div>
                    </div>
                    <table className="table your_clinic__table">
                        <thead>
                        <tr>
                            <th className='filtered' onClick={ () => {
                                filterColumn( 'name' )
                            } }>Name<IconFilter/></th>
                            <th className='filtered' onClick={ () => {
                                filterColumn( 'primary_admin' )
                            } }>Admin Email<IconFilter/></th>
                            <th className='filtered' onClick={ () => {
                                filterColumn( 'available_codes' )
                            } }>Avail Syringes<IconFilter/></th>
                            <th className='filtered' onClick={ () => {
                                filterColumn( 'last_order_date' )
                            } }>Last Order Date<IconFilter/></th>
                            <th className='filtered' onClick={ () => {
                                filterColumn( 'ready_to_order' )
                            } }>Ready to Order<IconFilter/></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            clinics.map( ( el, index ) => {
                                return <tr key={ index }>
                                    <td>
                                        <Link to={ '/selected-clinic/' + el.id }>
                                            <div onClick={ () => {
                                                createStoresArrAndSaveDataToGlobalStore( el.stores )
                                                // localStorage.clear()
                                                // localStorage.setItem( 'currentLocation_ls', null )
                                                setCompanyId( el.id )
                                            } }>
                                                { el.name }
                                            </div>
                                        </Link>
                                    </td>
                                    <td>{ el.primary_admin }</td>
                                    <td>{ el?.available_codes }</td>
                                    <td>Customer since { el.last_order_date.split( 'T' )[ 0 ] }</td>
                                    <td>{ el?.ready_to_order ? <IconCheckRound/> : null }</td>
                                    <td>
                                        <Link to={ '/selected-clinic/' + el.id }>
                                            <button className='view_btn' onClick={ () => {
                                                createStoresArrAndSaveDataToGlobalStore( el.stores )
                                                // localStorage.clear()
                                                // localStorage.setItem( 'currentLocation_ls', null )
                                                setCompanyId( el.id )
                                            } }>View
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                            } )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            { !preloader || <Preloader/> }
        </>
    );
};

export default RepDashboard;