import React, { useEffect, useState } from 'react'
import './Header.scss'
import IconMail from "../../Icons/IconMail"
import { Link, useLocation } from "react-router-dom"
import default_admin_picture from '../../../images/default_admin.png'
import {
    useAdminInfoUpdated,
    useAdminRegionStore,
    useAdminStore, useClinics
} from "../../../stores/adminStore"
import { tokenVerification } from "../../../requests/requests"
import { getRegionList, getRepAdmin } from "../../../requests/rep_requests"
import { loginValidation, tokenValid } from "../../../helperFunctions";


const Header = ( { companies } ) => {

    // WINDOW LOCATION
    const location = useLocation()
    const pageURL = location.pathname
    const enableMail = false

    /**************** From global store *************************/
    const isRepAdmin = useAdminStore( state => state.isRepAdmin )
    const repAdminLogo = useAdminStore( state => state.repAdminLogo )
    const saveRole = useAdminStore( state => state.saveRole )
    const saveAdminId = useAdminStore( state => state.saveAdminId )
    const adminRegion = useAdminRegionStore( state => state.adminRegion )
    const adminRegionId = useAdminRegionStore( state => state.adminRegionId )
    const setAdminRegion = useAdminRegionStore( state => state.setAdminRegion )
    const setAdminRegionId = useAdminRegionStore( state => state.setAdminRegionId )
    const updateAdmin = useAdminInfoUpdated( state => state.isUpdated )
    const clinics = useClinics( state => state.clinics )
    const currentClinicId = useClinics( state => state.currentClinicId )
    const setCurrentClinicId = useClinics( state => state.setCurrentClinicId )
    const setRepAdminLogo = useAdminStore( state => state.setRepAdminLogo )
    /******************************************************************/

    const [ company, setCompany ] = useState( {} )
    const [ stores, setStores ] = useState( [] )
    const [ admin, setAdmin ] = useState( {
        first_name: '',
        last_name: ''
    } )
    const [ controls, setControls ] = useState( {
        faq: false,
        getPatients: false,
        importPatients: false,
        addNewPatient: false,
        addNewClinic: false,
        addNewLocation: false,
    } )

    useEffect( () => {
        tokenValid()
        loginValidation() //check if user is login
        const pageURL = location.pathname
        if ( pageURL === '/' ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: true,
                getPatients: false,
                addNewClinic: true,
                addNewLocation: false
            } )
        } else if ( pageURL === '/patients' ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: true,
                getPatients: false,
                addNewClinic: false,
                addNewLocation: false
            } )
        } else if ( pageURL.includes( 'add-new-patient' ) ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: false,
                getPatients: false,
                addNewClinic: false,
                addNewLocation: false
            } )
        } else if ( pageURL.includes( 'patient-edit-account' ) ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: true,
                getPatients: false,
                addNewClinic: false,
                addNewLocation: false
            } )
        } else if ( pageURL === '/clinic-profile' ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: false,
                getPatients: false,
                addNewClinic: false,
                addNewLocation: false
            } )
        } else if ( pageURL === '/resources' ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: true,
                getPatients: false,
                addNewClinic: true,
                addNewLocation: false
            } )
        } else if ( pageURL.includes( '/rebates-applied' ) || pageURL.includes( '/reimbursement-shipped' ) || pageURL.includes( '/promotion' ) ) {
            setControls( {
                ...controls,
                faq: false,
                importPatients: false,
                addNewPatient: true,
                getPatients: false,
                addNewClinic: false,
                addNewLocation: false
            } )
        } else if ( pageURL.includes( '/reports' ) ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: false,
                getPatients: false,
                addNewClinic: true,
                addNewLocation: false
            } )
        } else if ( pageURL.includes( '/admin-information' ) ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: false,
                getPatients: false,
                addNewClinic: false,
                addNewLocation: false
            } )
        } else if ( pageURL.includes( '/selected-clinic' ) ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: false,
                getPatients: false,
                addNewClinic: true,
                addNewLocation: true
            } )
        } else if ( pageURL.includes( '/add-new-location' ) ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: false,
                getPatients: false,
                addNewClinic: false,
                addNewLocation: false
            } )
        } else if ( pageURL.includes( '/add-new-clinic' ) ) {
            setControls( {
                ...controls,
                faq: true,
                importPatients: false,
                addNewPatient: false,
                getPatients: false,
                addNewClinic: false,
                addNewLocation: false
            } )
        }
    }, [ location ] )

    useEffect( () => {
        if ( adminRegionId ) {
            getRegionList().then( data => {
                let admReg = data.find( reg => reg.id === adminRegionId ).name
                setAdminRegion( admReg )
            } )
        }
    }, [ adminRegionId ] )

    useEffect( () => {
        tokenVerification().then( data => {
            if ( 'errors' in data ) {
                window.location.href = '/login'
            } else {
                // saveRole( data.role ) // Admin role write to store
                // saveAdminId( data.id ) // Admin id write to store
                // setAdminRegionId( data?.region_id )
                setAdmin( { ...admin, ...data } )
            }
            return data
        } ).then( data => {
            getRepAdmin( data.id ).then( admin => {
                if ( admin.logo.url ) {
                    setRepAdminLogo( admin.logo.url )
                }
            } )
        } )
    }, [ updateAdmin ] )

    useEffect( () => {
        setCompany( companies.company )
        setStores( companies.stores )
    }, [ currentClinicId, companies ] )


    const faqBtn = <a href='https://epoxyapp.zendesk.com/hc/en-us/sections/360002443994-FAQ' className='btn'
                      target='_blank'>FAQ</a>
    const getPatientsBtn = <a href="http://localhost:3000/login" className='btn wide_btn'>Get patients report</a>
    const importPatientsBtn = <a href="http://localhost:3000/login" className='btn wide_btn'>Import patients</a>
    const addNewPatientBtn = <Link to={ '/add-new-patient' } className='btn wide_btn beige_btn'>Add new
        patient</Link>
    const addNewClinicBtn = <Link to={ '/add-new-clinic' } className='btn wide_btn beige_btn'>Add new clinic</Link>
    const addNewLocationBtn = <Link to={ '/add-new-location' } className='btn wide_btn beige_btn'>Add new
        location</Link>

    const regionRepAdmin = <div className='your_region'>
        <span className='subtitle'>Region: </span>
        <span className='region'>{ adminRegion }</span>
    </div>

    const clinicsList = <>
        <span className='subtitle'>Location: </span>
        <select className='stores_list'
                name="stores"
                id="stores"
                onChange={ ( e ) => {
                    setCurrentClinicId( +e.target.value )
                    localStorage.setItem( 'currentLocation_ls', +e.target.value )
                } }>
            { clinics.map( ( clinic, index ) => {
                return <option key={ index }
                               value={ clinic.id }
                               selected={ clinic.id === +localStorage.getItem( 'currentLocation_ls' ) }>
                    { clinic.name || 'null' }
                </option>
            } ) }
        </select>
    </>

    return (
        <div className={ 'header' }>
            <div className="header__admin">
                <Link to={ '/admin-information' } className='header__admin_edit'>
                    <p className="admin__name">{ admin.first_name + ' ' + admin.last_name }</p>
                    <div className="admin__ava">
                        <img src={ repAdminLogo || default_admin_picture } alt="ava"/>
                    </div>
                </Link>
                {
                    enableMail
                        ?
                        <div className="admin__mail">
                            <IconMail/>
                            <p className="mail__count">3</p>
                        </div>
                        :
                        null
                }
            </div>
            <div className="header__titleAndControl">
                <div className='title'>
                    { isRepAdmin
                        ?
                        <h1>{ `Welcome ${ admin.first_name }` }</h1>
                        :
                        <h1>{ company ? company.name : null }</h1>
                    }
                    <div className='address'>
                        { isRepAdmin
                            ?
                            (isRepAdmin && pageURL.includes( '/selected-clinic/' ))
                                ?
                                ([ regionRepAdmin, clinicsList ])
                                :
                                regionRepAdmin
                            :
                            clinicsList }
                    </div>
                </div>
                <div className="control">
                    { controls.faq && faqBtn }
                    { controls.getPatients && getPatientsBtn }
                    { controls.importPatients && importPatientsBtn }
                    { controls.addNewPatient && !isRepAdmin && addNewPatientBtn }
                    { controls.addNewClinic && isRepAdmin && addNewClinicBtn }
                    { controls.addNewLocation && isRepAdmin && addNewLocationBtn }
                </div>
            </div>
        </div>
    )
}

export default Header