import React, { useEffect, useRef, useState } from 'react'
import { validatePhoneNumber } from "../../../../helperFunctions";
import IMask from "imask";
import { useClinics, useCompany } from "../../../../stores/adminStore";
import { updateLocationAdmin } from "../../../../requests/rep_selected_clinic_requests";
import ChangePassword from "./ChangePassword";
import AddLocationToAdmin from "./AddLocationToAdmin";

const AdminsTableRow = ( { el, updateLocationAdminsList, setIniBlockSize } ) => {

    /******************** GLOBAL STORE ***********************/
    const companyId = useCompany( state => state.companyId )
    const clinics = useClinics( state => state.clinics )
    /********************************************************/

    const [ edit, setEdit ] = useState( false )
    const [ admin, setAdmin ] = useState( {
        email: '',
        first_name: '',
        id: null,
        last_name: '',
        locations: [],
        phone: '',
        full_name: ''
    } )
    const [ phoneError, setPhoneError ] = useState( '' )
    const [ emailError, setEmailError ] = useState( '' )
    const [ haveBeenChanges, setHaveBeenChanges ] = useState( false )

    useEffect( () => {
        setAdmin( { ...admin, ...el, full_name: (el.first_name + ' ' + el.last_name) } )
    }, [el] )

    // init block size
    useEffect( () => {
        setIniBlockSize( Math.random() )
    }, [ admin.locations ] )

    const phoneInput = useRef( null )
    useEffect( () => { // PhoneMask Initialization
        let element = phoneInput.current
        let maskOptions = {
            mask: '+{1}(000)0000000'
        }
        let mask = IMask( element, maskOptions )
        return () => mask.destroy()
    } )

    const adminNameHandler = ( e ) => {
        let name = e.target.value.split( ' ' )
        setAdmin( { ...admin, first_name: name[ 0 ], last_name: name.slice( 1 ).join( ' ' ) } )
        setHaveBeenChanges( true )
    }

    const emailHandler = ( e ) => {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        if ( !re.test( String( e.target.value ).toLowerCase() ) ) {
            setEmailError( 'Invalid email' )
        } else {
            setAdmin( { ...admin, email: e.target.value } )
            setHaveBeenChanges( true )
            setEmailError( '' )
        }
    }

    const phoneHandler = ( e ) => {
        let { valid, cleanPhoneNumber } = validatePhoneNumber( e )
        if ( valid ) {
            setPhoneError( '' )
            setAdmin( { ...admin, phone: cleanPhoneNumber } )
            setHaveBeenChanges( true )
        } else {
            setPhoneError( 'Invalid phone number' )
            setHaveBeenChanges( true )
        }
    }

    const btnText = () => {
        if ( edit && !haveBeenChanges ) return 'Cancel'
        if ( edit && haveBeenChanges && !(emailError || phoneError) ) return 'Save'
        if ( edit && haveBeenChanges && (emailError || phoneError) ) return 'Cancel'
        if ( !edit ) return 'Edit'
    }

    const addLocationToAdmin = ( newLocationList ) => {
        setAdmin( { ...admin, locations: newLocationList } )
        setHaveBeenChanges( true )
    }

    const delAdminLocation = ( locationId ) => {
        const newLocationsList = admin.locations.filter( el => el.id !== locationId )
        setAdmin( { ...admin, locations: newLocationsList } )
        setHaveBeenChanges( true )
    }

    const updateLocationAdminFunc = () => {
        const stores = ( arr ) => {
            let res = []
            arr.map( el => res.push( el.id ) )
            return res
        }
        const data = {
            admin_id: admin.id,
            company_id: companyId,
            first_name: admin.first_name,
            last_name: admin.last_name,
            email: admin.email,
            phone: admin.phone,
            store_ids: stores( admin.locations ),
        }
        updateLocationAdmin( data ).then( data => {
            if ( 'errors' in data ) console.log( data.errors )
        } )
    }

    return (
        <tr className={ 'table_row' + (edit ? ' edit' : '') }>
            <td>
                <div className='table_input_wrap'>
                    <input type="text"
                           className='table_input'
                           readOnly={ !edit }
                           defaultValue={ admin.full_name }
                           onChange={ ( e ) => adminNameHandler( e ) }
                    />
                </div>
            </td>
            <td>
                <div className='table_input_wrap'>
                    <input type="text"
                           className='table_input'
                           readOnly={ !edit }
                           defaultValue={ admin.email }
                           onChange={ ( e ) => emailHandler( e ) }
                    />
                    <span className='error_message'>{ emailError }</span>
                </div>
            </td>
            <td>
                <div className='table_input_wrap'>
                    <input type="tel"
                           ref={ phoneInput }
                           className='table_input'
                           readOnly={ !edit }
                           defaultValue={ admin.phone }
                           onChange={ ( e ) => phoneHandler( e ) }
                    />
                    <span className='error_message'>{ phoneError }</span>
                </div>
            </td>
            <td>
                <div className="admin_locations">
                    { edit ? <AddLocationToAdmin adminId={ el.id }
                                                 clinicLocations={ clinics }
                                                 addedLocations={ admin.locations }
                                                 addLocationToAdmin={ addLocationToAdmin }
                                                 updateLocationAdminsList={ updateLocationAdminsList }/> : null }
                    { admin.locations.length
                        ?
                        admin.locations.map( ( el, index ) => {
                            return <div className='admin_locations_item' key={ index }>
                                { el.name }
                                { edit && <span className='action'
                                                onClick={ () => {
                                                    delAdminLocation( el.id )
                                                } }>&#215;</span> }
                            </div>
                        } )
                        :
                        null }
                </div>
            </td>
            <td>
                <div className='buttons'>
                    <ChangePassword admin_id={ el.id }/>
                    <button className='table_btn'
                            onClick={ () => {
                                setEdit( !edit )
                                if ( edit && haveBeenChanges && !(emailError || phoneError) ) {
                                    updateLocationAdminFunc()
                                    setHaveBeenChanges( false )
                                }
                            } }>
                        { btnText() }
                    </button>
                </div>
            </td>
        </tr>
    )
}

export default AdminsTableRow;