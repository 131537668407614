import React, { useEffect, useState } from 'react';
import './Patients.scss'
import IconEdit from "../../Icons/IconEdit";
import IconCheck from "../../Icons/IconCheck";
import IconSearch from "../../Icons/IconSearch";
import { Link } from "react-router-dom";
import { getUsers } from "../../../requests/requests";
import Preloader from "../../Preloader/Preloader";
import IconFilter from "../../Icons/IconFilter";
import Pagination from "../../Pagination/Pagination";
import { useClinics, useCompany } from "../../../stores/adminStore";

const Patients = () => {

    /************************STORE***************************/
    const currentClinicId = useClinics( state => state.currentClinicId )
    const companyId = useCompany( state => state.companyId )
    /********************************************************/

    const [ preloader, setPreloader ] = useState( true )
    // PAGINATION
    const [ count, setCount ] = useState( null )
    const [ showPagination, setShowPagination ] = useState( false )
    let url = new URL( window.location.href )
    let numberPage = url.searchParams.get( "page" )

    const [ searchUser, setSearchUser ] = useState( '' )
    const [ direction, setDirection ] = useState( true )

    const [ patients, setPatients ] = useState( [] )
    const [ filterOptions, setFilterOptions ] = useState( {
        amount: 10,
        col_name: 'created_at',
        direction: 'desc',
        company_id: '',
        store_id: '',
        page: numberPage,
        word: '',
    } )

    useEffect( () => {
        if ( currentClinicId && companyId ) {
            setFilterOptions( {
                ...filterOptions,
                company_id: companyId,
                store_id: currentClinicId
            } )
        }
    }, [ currentClinicId ] )

    useEffect( () => {
        setPreloader( true )
        if ( currentClinicId ) {
            setFilterOptions( {
                ...filterOptions,
                company_id: companyId,
                store_id: currentClinicId
            } )
        }
        if ( currentClinicId && companyId && filterOptions.company_id && filterOptions.store_id ) {
            getUsers( filterOptions ).then( data => {
                if ( !('errors' in data) ) {
                    setPatients( data )
                    setPreloader( false )
                    if ( data.length ) {
                        setShowPagination( true )
                        setCount( data.pop()?.count ) // Number of pages for pagination
                    }
                } else {
                    window.location.href = "/login"
                }
            } )
        }
    }, [ filterOptions.direction,
        filterOptions.page,
        filterOptions.word,
        filterOptions.col_name,
        filterOptions.store_id,
        filterOptions.company_id
    ] )

    const filterColumn = ( param ) => {
        setPreloader( true )
        setDirection( !direction )
        direction
            ?
            setFilterOptions( { ...filterOptions, col_name: param, direction: 'desc' } )
            :
            setFilterOptions( { ...filterOptions, col_name: param, direction: 'asc' } )
    }

    const searchHandler = ( searchUserName, filterOptions ) => {
        setPreloader( true )
        setFilterOptions( {
            ...filterOptions,
            amount: 10,
            col_name: 'created_at',
            direction: 'desc',
            page: 1,
            word: searchUserName,
        } )
        getUsers( filterOptions ).then( data => {
            if ( !('errors' in data) ) {
                setPatients( data )
                setPreloader( false )
            } else {
                window.location.href = "/login"
            }
        } )
    }

    return (
        <div className='patients'>
            <div className='component__wrap'>
                <div className="patients__title dashboard__component_title">
                    <h2>Patients</h2>
                    <div className="search">
                        <form className="search_form">
                            <input type="text"
                                   placeholder='Search someone...'
                                   value={ searchUser }
                                   onChange={ ( e ) => {
                                       setSearchUser( e.target.value )
                                   } }
                                   className='form__input'/>
                            <IconSearch/>
                            <button className="btn wide_btn black_btn"
                                    onClick={ ( e ) => {
                                        e.preventDefault()
                                        searchHandler( searchUser, filterOptions )
                                    } }>Search
                            </button>
                        </form>
                    </div>
                </div>
                <table className="patients__table table">
                    <thead>
                    <tr>
                        <th className='filtered' onClick={ () => {
                            filterColumn( 'first_name' )
                        } }>Name<IconFilter/></th>
                        <th className='filtered' onClick={ () => {
                            filterColumn( 'email' )
                        } }>Email<IconFilter/></th>
                        <th className='filtered' onClick={ () => {
                            filterColumn( 'phone_number' )
                        } }>Phone<IconFilter/></th>
                        <th className='filtered' onClick={ () => {
                            filterColumn( 'created_at' )
                        } }>Patient since<IconFilter/></th>
                        <th>Verified</th>
                        <th></th>

                    </tr>
                    </thead>
                    <tbody>
                    {
                        patients.map( ( el, index ) => {
                            if ( Object.keys( el ).includes( 'id' ) ) {
                                return <tr key={ index }>
                                    <td>
                                        <Link to={ '/patients/patient-edit-account/' + el.id }>
                                            { el.first_name + ' ' + el.last_name }<br/><span>
                                        { el.visit_date
                                            ?
                                            el.visit_date.split( 'T' )[ 0 ]
                                            :
                                            '' }</span>
                                        </Link>
                                    </td>
                                    <td>{ el.email }</td>
                                    <td>{ el.phone_number }</td>
                                    <td>Customer since { el.created_at
                                        ?
                                        el.created_at.split( 'T' )[ 0 ]
                                        :
                                        '' }</td>
                                    <td>{ el.verified ? <IconCheck/> : null }</td>
                                    <td><Link to={ '/patients/patient-edit-account/' + el.id }><IconEdit/></Link></td>
                                </tr>
                            }
                        } )
                    }
                    </tbody>
                </table>
                {
                    showPagination
                        ?
                        <Pagination
                            preloader={ setPreloader }
                            state={ filterOptions }
                            count={ count }
                            amount={ filterOptions.amount }
                            interval={ 3 }
                            changePage={ setFilterOptions }
                            now_page={ filterOptions.page }/>
                        :
                        null
                }
            </div>
            { !preloader || <Preloader/> }
        </div>
    );
};

export default Patients;