import React, { useEffect, useState } from 'react';
import './AddNewLocation.scss'
import { phoneMaskNumberInit, validatePhoneNumber } from "../../../helperFunctions";
import { useNavigate } from "react-router-dom";
import Preloader from "../../Preloader/Preloader";
import PopUp from "../../PopUp/PopUp";
import { addNewLocationFetch } from "../../../requests/rep_requests";
import { useCompany } from "../../../stores/adminStore";

const AddNewLocation = () => {

    /******************** GLOBAL STORE ***********************/
    const companyId = useCompany( state => state.companyId )
    /********************************************************/

    let navigate = useNavigate()
    const [ newLocation, setNewLocation ] = useState( {
        company_id: null,
        name: '',
        address: '',
        website: '',
        phone: '',
        email: '',
        lat: null,
        lng: null,
    } )
    const [ phoneError, setPhoneError ] = useState( '' )
    const [ emailError, setEmailError ] = useState( '' )
    const [ locationSuccessfullyEstablished, setLocationSuccessfullyEstablished ] = useState( false )
    const [ updated, setUpdated ] = useState( false )
    const [ preloader, setPreloader ] = useState( false )

    useEffect( () => phoneMaskNumberInit() ) // PhoneMask Initialization

    const phoneHandler = ( e ) => {
        let { valid, cleanPhoneNumber } = validatePhoneNumber( e )
        if ( valid ) {
            setPhoneError( '' )
            setNewLocation( { ...newLocation, phone: cleanPhoneNumber } )
        } else {
            setPhoneError( 'Invalid phone number' )
        }
    }

    const inputsHandler = ( e ) => {
        let name = e.target.name
        let value = e.target.value
        setNewLocation( { ...newLocation, [ name ]: value } )
    }

    const emailHandler = ( e ) => {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        if ( !re.test( String( e.target.value ).toLowerCase() ) ) {
            setEmailError( 'Invalid email' )
        } else {
            setNewLocation( { ...newLocation, email: e.target.value } )
            setEmailError( '' )
        }
    }

    const formValidate = () => {
        if ( !newLocation.name ) return true
        if ( !newLocation.phone ) return true
        if ( !newLocation.email ) return true
        if ( !newLocation.lat ) return true
        if ( !newLocation.lng ) return true
        if ( phoneError ) return true
    }

    // FOR AUTOCOMPLETE ADDRESS
    const AutoComplete = ( e ) => {
        let autocomplete = new window.google.maps.places.Autocomplete( e.target )
        window.google.maps.event.addListener( autocomplete, 'place_changed', function () {
            let place = autocomplete.getPlace()
            setNewLocation( {
                ...newLocation,
                address: place.formatted_address,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            } )
        } )
    }

    useEffect( () => {
        setNewLocation( { ...newLocation, company_id: companyId } )
    }, [] )

    useEffect( () => {
        if ( !companyId ) {
            navigate( -1 )
        }
        if ( locationSuccessfullyEstablished && !updated ) {
            navigate( -1 )
        }
    }, [ updated ] )

    return (
        <div className='addNewClinic'>
            <div className='component__wrap white'>
                <div className="addNewClinic__title dashboard__component_title">
                    <h2>ADD NEW LOCATION</h2>
                </div>
                <div className='component__subtitle'>
                    <h3>Location Details</h3>
                </div>
                <form className="addNewClinic__form form">
                    <div className="form_field location_city">
                        <label htmlFor="location_city_name">Location City</label>
                        <input type="text"
                               id='location_city_name'
                               autoComplete='new-password'
                               name='name'
                               onChange={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field location_address">
                        <label htmlFor="address">Location Address</label>
                        <input type="text"
                               id='address'
                               autoComplete='new-password'
                               name='address'
                               onChange={ ( e ) => {
                                   AutoComplete( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field phone">
                        <label htmlFor="phone">Phone Number</label>
                        <input type="text"
                               id='phone'
                               name='phone'
                               onChange={ ( e ) => {
                                   phoneHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ phoneError }</p>
                    </div>
                    <div className="form_field location_email">
                        <label htmlFor="email">Location email</label>
                        <input type="text"
                               id='email'
                               autoComplete='new-password'
                               name='email'
                               onChange={ ( e ) => {
                                   emailHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ emailError }</p>
                    </div>
                    <div className="form_field location_website">
                        <label htmlFor="website">Website</label>
                        <input type="text"
                               id='website'
                               autoComplete='new-password'
                               name='website'
                               onChange={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                </form>
                <button className="save_btn btn wide_btn beige_btn"
                        disabled={ formValidate() }
                        onClick={ ( e ) => {
                            e.preventDefault()
                            setPreloader( true )
                            addNewLocationFetch( newLocation ).then( data => {
                                if ( !('error' in data) ) {
                                    setLocationSuccessfullyEstablished( true )
                                    setUpdated( true )
                                    setPreloader( false )
                                } else {
                                    navigate( -1 )
                                }
                            } )
                        } }
                >Save New Location
                </button>
            </div>
            { !preloader || <Preloader/> }
            { !updated || <PopUp popUpMessage={ `Location ${ newLocation.name } was created` }
                                 closeBtnTitle={ 'Close' }
                                 showPopUp={ setUpdated }
                                 closePopUp={ setUpdated }/> }
        </div>
    )
}

export default AddNewLocation