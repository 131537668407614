import React from 'react'
import './Resources.scss'
import { socialMediaResources, clinicianAssets } from './mediaLinks'
import plug_pic from '../../../images/img_file.png'

const Resources = () => {

    const base_URL_v1 = 'https://revanesserewards-media.s3.us-west-2.amazonaws.com/media/'
    const base_URL_v2 = 'https://revanesserewards-media.s3.us-west-2.amazonaws.com/resources-media/'

    return (
        <div className='resources'>
            <div className='component__wrap'>
                <div className="resources__title dashboard__component_title">
                    <h2>Resources</h2>
                </div>
                <h3 className="resources__items_group_title">Social Media Resources</h3>
                <div className="resources__items social_media">
                    <div className="resources__items_wrap social_media_resources">
                        {
                            socialMediaResources
                                ?
                                socialMediaResources.map( ( obj, index ) => {
                                    let name = obj.content.replace( /[+_%20]+/g, ' ' )
                                    let clearName = name.replace( /\.[^.$]+$/, '' )
                                    return <div className="item" key={ index }>
                                        <div className='item_wrap'>
                                            <div className='item_img'>
                                                <a href={ base_URL_v2 + obj.content } download target='_blank'>
                                                    <img src={ obj.thumb ? (base_URL_v2 + obj.thumb) : plug_pic }
                                                         alt=""/>
                                                </a>
                                            </div>
                                            <p>{ clearName }</p>
                                        </div>
                                    </div>
                                } )
                                :
                                null
                        }
                    </div>
                </div>

                <h3 className="resources__items_group_title">Clinician Assets</h3>
                <div className="resources__items">
                    <div className="resources__items_wrap clinician_assets">
                        <p className="item">Print File (Hi-Res)</p>
                        <p className="item">Standard (no bleed)</p>
                        {
                            clinicianAssets
                                ?
                                clinicianAssets.map( ( obj, index ) => {
                                    let name = obj.content.replace( /[+_%20]+/g, ' ' )
                                    let clearName = name.replace( /\.[^.$]+$/, '' )
                                    return <div className="item" key={index}>
                                        <div className='item_wrap'>
                                            <div className='item_img'>
                                                <a href={ base_URL_v2 + obj.content } download target='_blank'><img
                                                    src={ base_URL_v2 + obj.thumb }
                                                    alt=""/></a>
                                            </div>
                                            <p>{ clearName }</p>
                                        </div>
                                    </div>
                                } )
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Resources;