import React, { useEffect, useState } from 'react';
import './DashboardPromoBoxTimer.scss'

const DashboardPromoBoxTimer = ( { date } ) => {

    const calculateTimeLeft = () => {
        let difference = +new Date( date ) - +new Date()
        let timeLeft = {}
        if ( difference > 0 ) {
            timeLeft = {
                days: Math.floor( difference / (1000 * 60 * 60 * 24) ),
                hours: Math.floor( (difference / (1000 * 60 * 60)) % 24 ),
                minutes: Math.floor( (difference / (1000 * 60)) % 60 ),
                seconds: Math.floor( (difference / 1000) % 60 )
            }
            return timeLeft
        } else {
            return timeLeft
        }
    }

    const [ timeLeft, setTimeLeft ] = useState( calculateTimeLeft() )

    useEffect( () => {
        const timer = setTimeout( () => {
            setTimeLeft( calculateTimeLeft() )
        }, 1000 )
        return () => clearTimeout( timer )
    } )

    const timerComponents = []
    Object.keys( timeLeft ).forEach( ( interval, index ) => {
        if ( (interval === 'days') && timeLeft[interval] === 0 ) {
            return
        }
        timerComponents.push(
            <span key={ index }>
                { (timeLeft[ interval ] < 10)
                    ?
                    '0' + timeLeft[ interval ]
                    :
                    (!timeLeft[ interval ])
                        ?
                        '00'
                        :
                        timeLeft[ interval ] }
                { " " }
                { interval }
            </span>
        )
    } )


    return (
        <div className='timer'>
            { timerComponents.length ? timerComponents : <span>Time's up!</span> }
        </div>
    )
}

export default DashboardPromoBoxTimer;