import React, { useEffect, useState } from 'react'
import './TrainingMode.scss'
import IconClock from "../../../Icons/IconClock";
import {
    getStatusTrainingMode,
    resetTrainingStatusTrainingMode, setActiveStatusTrainingMode, setCompleteStatusTrainingMode
} from "../../../../requests/rep_selected_clinic_requests";
import { useAdminStore, useCompany } from "../../../../stores/adminStore";

const TrainingMode = () => {

    /***************** GLOBAL STORE **********************/
    const companyId = useCompany( state => state.companyId )
    const adminId = useAdminStore( state => state.adminId )
    /*****************************************************/

    const [ adminStatus, setAdminStatus ] = useState( {
        id: null,
        admin_id: null,
        active: false,
        complete: false,
        activated_at: null,
        completed_at: '',
        created_at: '',
        updated_at: '',
        company_id: null,
    } )
    const [ showWarning, setShowWarning ] = useState( false )
    const [ warningMess, setWarningMess ] = useState( '' )

    const [ status, setStatus ] = useState( {
        title: 'Training not started yet',
        color: '#131110',
        action: 'Begin Training'
    } )

    const getTrainingModeStatus = () => {
        if ( companyId ) {
            getStatusTrainingMode().then( data => {
                if ( data.result.length ) {
                    // console.log( 'training status', data )
                    setAdminStatus( data.result.find( el => el.company_id === companyId ) )
                } else {
                    // console.log( 'empty training status', data )
                    setAdminStatus( { ...adminStatus, admin_id: adminId, company_id: companyId } )
                }
            } )
        }
    }

    useEffect( () => {
        getTrainingModeStatus()
    }, [ companyId ] )

    useEffect( () => {
        let { active, complete, completed_at } = adminStatus
        if ( !active && !complete ) setStatus( {
            title: 'Training not started yet',
            color: '#131110',
            action: 'Begin Training'
        } )
        if ( active && !complete ) setStatus( {
            title: 'Training In-Session',
            color: '#DBB54D',
            action: 'Complete Training'
        } )
        if ( (!active && complete) || (active && complete) ) setStatus( {
            title: 'Training completed',
            color: '#00815A',
            action: completed_at.split( 'T' )[ 0 ]
        } )
    }, [ adminStatus ] )

    const actionsTrainingFun = () => {
        let { active, complete } = adminStatus
        if ( !active && !complete ) {
            const data = {
                active: true,
                company_id: companyId
            }
            setActiveStatusTrainingMode( data ).then( data => {
                if ( data ) {
                    getTrainingModeStatus()
                }
            } )
        }
        if ( active && !complete ) {
            const data = {
                complete: true,
                company_id: companyId
            }
            setCompleteStatusTrainingMode( data ).then( data => {
                if ( data ) {
                    getTrainingModeStatus()
                    // console.log(data)
                }
            } )
        }
    }

    const warningMessage = () => {
        let { active, complete, completed_at } = adminStatus
        if ( !active && !complete ) return 'Do you want to start training mode?'
        if ( active && !complete ) return 'Do you want to end training mode?'
    }

    return (
        <div className='training_mode' style={ { background: status.color } }>
            <div className='training_mode_status'>
                <IconClock/>
                <h2>{ status.title }</h2>
                {/*<button onClick={ () => {*/ }
                {/*    const data = { company_id: companyId }*/ }
                {/*    resetTrainingStatusTrainingMode( data )*/ }
                {/*        .then( data => {*/ }
                {/*            if ( 'result' in data ) {*/ }
                {/*                getTrainingModeStatus()*/ }
                {/*            }*/ }
                {/*        } )*/ }
                {/*} }>RESET*/ }
                {/*</button>*/ }
            </div>
            <div className={ `warning_message${ showWarning ? ' show' : '' }` }>
                <span>{ warningMessage() }</span>
                <button onClick={ () => {
                    actionsTrainingFun()
                    setShowWarning( false )
                } }>Yes
                </button>
                <button onClick={ () => {
                    setShowWarning( false )
                } }>No
                </button>
            </div>
            <button className='training_mode_action'
                    disabled={ adminStatus.complete && (adminStatus.active || !adminStatus.active) }
                    onClick={ () => {
                        // actionsTrainingFun()
                        setShowWarning( true )
                    } }>{ status.action }</button>
        </div>
    )
}

export default TrainingMode