import { getCookie } from "../helperFunctions";

export async function getClinicList( data = {} ) {
    let parameters = ''
    Object.keys( data ).forEach( value => {
        parameters += value + '=' + data[ value ] + '&'
    } )
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/dashboard/clinic_list?'
        + parameters, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function getRegionList() {
    const response = await fetch( 'https://prolleniumelite.epoxyapp.com/api/v5/regions', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } )
    return await response.json()
}

export async function getPatientsList( data = {} ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/patients?company_id='
        + data.company_id
        + '&store_id='
        + data.store_id
        + '&col_name='
        + data.col_name
        + '&direction='
        + data.direction
        + '&word='
        + data.word, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function getRepAdmin( id ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/admins/'
        + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function updateRepAdmin( data ) {
    let value = new FormData()
    value.append( 'email', data.email )
    value.append( 'first_name', data.first_name )
    value.append( 'id', data.id )
    value.append( 'last_name', data.last_name )

    if ( data.logo instanceof File ) { // checks if there is a file in the object
        value.append( 'logo', data.logo, data.logo.name )
    }

    value.append( 'phone', data.phone )
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/admins/'
        + data.id, {
            method: 'PUT',
            headers: {
                // 'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: value
        } )
    return await response.json()
}

export async function updateRepAdminPassword( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/admins/change_password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return await response.json()
}

export async function addNewClinicFetch( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/companies', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return await response.json()
}

export async function addNewLocationFetch( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/stores', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return await response.json()
}