import React, { useState } from 'react';
import { changeLocationAdminPassword } from "../../../../requests/rep_selected_clinic_requests";

const ChangePassword = ( { admin_id } ) => {

    const [ openPassBlock, setOpenPassBlock ] = useState( false )
    const [ warning, setWarning ] = useState( {
        passLength: '',
        passNotMatch: ''
    } )
    const [ passwords, setPasswords ] = useState( {
        new_password: '',
        repeat_password: ''
    } )

    const newAdminPasswordHandler = ( e ) => {
        let dirtValue = e.target.value
        let value = dirtValue.replace( /\s+/gi, '' )
        setPasswords( { ...passwords, new_password: value } )
        if ( value.length < 8 ) {
            setWarning( { ...warning, passLength: 'Minimum 8 characters' } )
        } else {
            setWarning( { ...warning, passLength: '' } )
        }
    }

    const passwordAdminRepeatHandler = ( e ) => {
        let dirtValue = e.target.value
        let value = dirtValue.replace( /\s+/gi, '' )
        setPasswords( { ...passwords, repeat_password: value } )
        if ( value !== passwords.new_password ) {
            setWarning( { ...warning, passNotMatch: 'Passwords do not match' } )
        } else {
            setWarning( { ...warning, passNotMatch: '' } )
        }
    }

    const validPassChange = () => {
        if ( !openPassBlock ) return 'Change Password'
        if ( openPassBlock &&
            passwords.new_password &&
            (passwords.new_password === passwords.repeat_password) &&
            !(warning.passLength || warning.passNotMatch) ) {
            return 'Save New Password'
        } else {
            return 'Cancel'
        }
    }

    const changeLocationAdminPasswordFunc = () => {
        const data = {
            admin_id: admin_id,
            new_password: passwords.repeat_password
        }
        if ( openPassBlock &&
            passwords.new_password &&
            (passwords.new_password === passwords.repeat_password) &&
            !(warning.passLength || warning.passNotMatch) ) {
            changeLocationAdminPassword( data )
                .then( ( data ) => {
                    console.log( data )
                } )
        }

    }

    return (
        <div className='change_pass_block'>
            <button className='change_pass table_btn' onClick={ () => {
                setOpenPassBlock( !openPassBlock )
                setPasswords( { ...passwords, new_password: '', repeat_password: '' } )
                setWarning( { ...warning, passLength: '', passNotMatch: '' } )
                changeLocationAdminPasswordFunc()
            } }>{ validPassChange() }
            </button>
            {
                openPassBlock
                    ?
                    <div className='change_pass_fields'>
                        <div className='change_pass_field'>
                            <label>New Password</label>
                            <input type="password"
                                   autoComplete="new-password"
                                   value={ passwords.new_password }
                                   onChange={ ( e ) => {
                                       newAdminPasswordHandler( e )
                                   } }/>
                            <span className='pass_warning'>{ warning.passLength }</span>
                        </div>
                        <div className='change_pass_field'>
                            <label>Repeat New Password</label>
                            <input type="password"
                                   autoComplete="new-password"
                                   value={ passwords.repeat_password }
                                   onChange={ ( e ) => {
                                       passwordAdminRepeatHandler( e )
                                   } }/>
                            <span className='pass_warning'>{ warning.passNotMatch }</span>
                        </div>
                    </div>
                    :
                    null
            }

        </div>
    )
}

export default ChangePassword;