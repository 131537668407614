import { getCookie } from "../helperFunctions";

export async function getFullCreditsDetailsRep( company_id ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/dashboard/full_credits_details?company_id='
        + company_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function getReimbursementRep( requestType, company_id, store_id ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/dashboard/'
        + requestType
        + '_product_histories?company_id='
        + company_id
        + '&store_id='
        + store_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function getPromotionsRep( request, companyId, storeId ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/dashboard/promotion_entries_'
        + request + '?company_id=' + companyId + '&store_id=' + storeId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function getPurchaseHistoryRepByStore( companyId, storeId ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/dashboard/purchase_history?company_id='
        + companyId
        + '&store_id='
        + storeId,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}