import React, { useEffect, useState } from 'react';
import IconEdit from "../../../Icons/IconEdit";
import IconFilter from "../../../Icons/IconFilter";
import { getFullCreditsDetails } from "../../../../requests/requests";
import Preloader from "../../../Preloader/Preloader";
import { Link, useNavigate } from "react-router-dom";
import { useAdminStore, useClinics, useCompany } from "../../../../stores/adminStore";
import { getFullCreditsDetailsRep } from "../../../../requests/requestd_selected_clinic_statistics";

const DashboardRebates = () => {

    /************************STORE***************************/
    const companyId = useCompany( state => state.companyId )
    const isRepAdmin = useAdminStore( state => state.isRepAdmin )
    /********************************************************/
    const navigate = useNavigate()
    const [ preloader, setPreloader ] = useState( true )
    const [ rebates, setRebates ] = useState( [] )

    const [ sort, setSort ] = useState( {
        field: 'statistic_date_formatted',
        minToMax: false
    } )

    function sortProductsBy( field, minToMax ) {
        if ( field === 'date' ) {
            return ( a, b ) => Date.parse( a[ field ] ) < Date.parse( b[ field ] ) ? minToMax ? -1 : 1 : minToMax ? 1 : -1
        } else if ( field === 'credits' ) {
            return ( a, b ) => a[ field ] < b[ field ] ? minToMax ? -1 : 1 : minToMax ? 1 : -1
        } else {
            return ( a, b ) => a[ field ].toLowerCase() < b[ field ].toLowerCase() ? minToMax ? -1 : 1 : minToMax ? 1 : -1
        }
    }

    const getAndSetState = ( data ) => {
        if ( 'errors' in data ) {
            console.log( 'errors', data )
        } else {
            let newData
            // let inventory_credits_obj = JSON.parse(data['inventory_credits'])
            // let promo_credits_obj = JSON.parse(data['promo_credits'])
            // let reward_card_credits_obj = JSON.parse(data.reward_card_credits)
            newData = JSON.parse( data.reward_card_credits )
            newData.forEach( el => {
                if ( el.customer_name ) {
                    el.customer_name_str = Object.values( el.customer_name ).join( ' ' )
                } else {
                    el.customer_name_str = ''
                }
                if ( el.hasOwnProperty( 'employee' ) ) {
                    el.employee_name = el.employee.name
                } else {
                    el.employee_name = ''
                }
            } )
            newData.sort( sortProductsBy( sort.field, sort.minToMax ) )
            setRebates( newData )
            setPreloader( false )
        }
    }


    useEffect( () => {
        setRebates( [] )
        setPreloader( true )
        if ( isRepAdmin ) {
            if ( companyId ) {
                getFullCreditsDetailsRep( companyId )
                    .then( data => getAndSetState( data ) )
            } else {
                navigate( -1 )
            }
        } else {
            if ( companyId ) {
                getFullCreditsDetails( companyId )
                    .then( data => getAndSetState( data ) )
            } else {
                navigate( -1 )
            }
        }
    }, [ companyId ] )

    useEffect( () => {
        setRebates( [ ...rebates.sort( sortProductsBy( sort.field, sort.minToMax ) ) ] )
    }, [ sort ] )

    return (
        <div className='rebates'>
            <div className='component__wrap'>
                <div className="rebates__title dashboard__component_title">
                    <h2>SYRINGES APPLIED</h2>
                </div>
                <table className="rebates__table table">
                    <thead>
                    <tr>
                        <th style={ { cursor: 'pointer' } } onClick={ () => {
                            setSort( { ...sort, field: 'customer_name_str', minToMax: !sort.minToMax } )
                        } }>Name<IconFilter/></th>
                        <th>Total Rebates<IconFilter/></th>
                        <th style={ { cursor: 'pointer' } } onClick={ () => {
                            setSort( { ...sort, field: 'employee_name', minToMax: !sort.minToMax } )
                        } }>Employee<IconFilter/></th>
                        <th style={ { cursor: 'pointer' } } onClick={ () => {
                            setSort( { ...sort, field: 'statistic_date_formatted', minToMax: !sort.minToMax } )
                        } }>Patient since<IconFilter/></th>
                        <th style={ { cursor: 'pointer' } } onClick={ () => {
                            setSort( { ...sort, field: 'credits', minToMax: !sort.minToMax } )
                        } }>Value<IconFilter/></th>
                        <th>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rebates.map( ( el, index ) => {
                            return <tr key={ index }>
                                <td>
                                    <Link
                                        to={ '/patients/patient-edit-account/' + el.profile_id }>{ el.customer_name_str }</Link>
                                    <br/><span>{ el.statistic_date_formatted }</span></td>
                                <td>{ el.totalRebates }</td>
                                <td>{ el.employee_name }</td>
                                <td>Customer since { el.statistic_date_formatted }</td>
                                <td className='right_text'>{ el.credits }</td>
                                <td>
                                    <Link to={ '/patients/patient-edit-account/' + el.profile_id }>
                                        <IconEdit/>
                                    </Link>
                                </td>
                            </tr>
                        } )
                    }
                    </tbody>
                </table>
                { rebates.length
                    ?
                    ''
                    :
                    <h3 style={ { marginTop: '20px', fontSize: '20px', textAlign: 'center' } }>Nothing found</h3>
                }
            </div>
            { !preloader || <Preloader/> }
        </div>
    );
};

export default DashboardRebates;