import React, { useEffect, useState } from 'react';
import './ClinicProfile.scss'
import PopUp from "../../PopUp/PopUp";
import {
    createStoreImage,
    getStoreInfo,
    updateStoreImage,
    updateStoreLogo,
    updateStoreInfo,
    getEmployees,
    updateEmployee,
    createNewEmployee,
    deleteEmployee, downloadBadge
} from "../../../requests/clinic_requests";
import defaultImage from '../../../images/svg/image-solid.svg'
import Preloader from "../../Preloader/Preloader";
import CropImage from "../../CropImage/CropImage";
import { generateScanCode, phoneInputMask, phoneMaskNumberInit, validatePhoneNumber } from "../../../helperFunctions";
import { useClinics } from "../../../stores/adminStore";

const ClinicProfile = ( { stores, getCompanyFunc } ) => {

        /************************STORE***************************/
        const currentClinicId = useClinics( state => state.currentClinicId )
        /********************************************************/

        // STORE STATE FROM PROPS
        const [ store, setStore ] = useState( {
            address: '',
            company_id: null,
            city_id: null,
            city: '',
            email: null,
            facebook_link: null,
            id: null,
            instagram_link: null,
            logo: {},
            name: null,
            phone: [],
            pinterest_link: null,
            twitter_link: null,
            website: null,
            work_time: null,
            youtube_link: null,
            zip: null,
        } )

        const updateEmployees = () => {
            getEmployees( currentClinicId )
                .then( data => {
                    if ( !('errors' in data) ) {
                        data.forEach( el => {
                            el.changed = false
                            el.newEmpl = false
                        } )
                        setEmployees( data )
                    }
                } )
        }

        const updateStoreInfoFunc = () => {
            setPreloader( true )
            if ( currentClinicId ) {
                let store = stores.filter( el => el.id === currentClinicId )
                // console.log(store)
                setLogo( { ...logo, logo_url: store?.logo?.url } )
                getStoreInfo( currentClinicId )
                    .then( data => {
                        setStore( data )
                        saveToState( data )
                        setPreloader( false )
                    } )
                updateEmployees()
            }
        }

        // UPDATE STORE FROM PROPS
        useEffect( () => {
            if ( stores.length ) {
                updateStoreInfoFunc()
            }
        }, [ stores, currentClinicId ] )

        const [ popUpMessage, setPopUpMessage ] = useState( 'Updated' )
        // PRELOADER
        const [ preloader, setPreloader ] = useState( true )
        // PHONE ERROR
        const [ phoneError, setPhoneError ] = useState( '' )
        // SHOW CROP IMAGE COMPONENT
        const [ cropImage, setCropImage ] = useState( false )
        // STORE CHANGED HANDLER
        const [ clinicInfoHasChanged, setClinicInfoHasChanged ] = useState( false )
        // STORE IMAGES CHANGED HANDLER
        const [ clinicImagesHasChanged, setClinicImagesHasChanged ] = useState( false )
        const [ updated, setUpdated ] = useState( false )
        const [ fieldName, setFieldName ] = useState( null )
        const [ imageToCrop, setImageToCrop ] = useState( null )
        const [ logo, setLogo ] = useState( { logo_url: '', logo_from_inp: '' } )
        // IMAGES STATE FROM STORE & FROM INPUTS AFTER CROP
        const [ images, setImages ] = useState( { // General images state
            image1: '',
            image1_id: '',
            image1_order: 1,
            image2: '',
            image2_id: '',
            image2_order: 2,
            image3: '',
            image3_id: '',
            image3_order: 3,
            image4: '',
            image4_id: '',
            image4_order: 4,
            image5: '',
            image5_id: '',
            image5_order: 5,
            image6: '',
            image6_id: '',
            image6_order: 6,
            image1_from_inp: '',
            image2_from_inp: '',
            image3_from_inp: '',
            image4_from_inp: '',
            image5_from_inp: '',
            image6_from_inp: ''
        } )

        // Employees of current store
        const [ employees, setEmployees ] = useState( [] )

        // FOR AUTOCOMPLETE ADDRESS
        const AutoComplete = ( e ) => {
            let inputName = e.target.name
            setClinicInfoHasChanged( true )
            let autocomplete = new window.google.maps.places.Autocomplete( e.target )
            window.google.maps.event.addListener( autocomplete, 'place_changed', function () {
                let place = autocomplete.getPlace()
                if ( inputName === 'city_id' ) {
                    setStore( {
                        ...store,
                        city: place.name,
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    } )
                } else if ( inputName === 'address' ) {
                    // let adr = []
                    // place.address_components.map(el => {
                    //     adr.push(el.long_name)
                    // })
                    // let address_string = adr.join(', ')
                    // setStore({...store, address: address_string})
                    setStore( { ...store, address: place.formatted_address } )
                }
            } )
        }

        const Save = () => {
            setPreloader( true )
            let data = {
                store: {
                    id: store.id,
                    address: store.address,
                    phone: store.phone,
                    lat: store.lat,
                    lng: store.lng,
                    website: store.website,
                    work_time: store.work_time,
                    facebook_link: store.facebook_link,
                    instagram_link: store.instagram_link,
                    youtube_link: store.youtube_link,
                    city: store.city
                }
            }
            updateStoreInfo( store.id, data )
                .then( ( data ) => {
                    setStore( data )
                    saveToState( data )
                    setPreloader( false )
                    setPopUpMessage( 'Updated' )
                    setUpdated( true )
                } )
                .then( () => {
                    getCompanyFunc()
                } )
        }

        const saveToState = ( data ) => {
            const pictures = data.slides
            let obj = {}
            if ( pictures.length ) {
                for ( let i = 0; i < 6; i++ ) {
                    if ( i < pictures.length ) {
                        obj = {
                            ...obj,
                            [ 'image' + (i + 1) + '_id' ]: pictures[ i ].id,
                            [ 'image' + (i + 1) + '_order' ]: pictures[ i ].appearance_order,
                            [ 'image' + (i + 1) ]: pictures[ i ].url
                        }
                    } else if ( i >= pictures.length ) {
                        obj = {
                            ...obj,
                            [ 'image' + (i + 1) + '_id' ]: '',
                            [ 'image' + (i + 1) + '_order' ]: i + 1,
                            [ 'image' + (i + 1) ]: ''
                        }
                    }
                }
            } else if ( pictures.length === 0 ) {
                for ( let i = 0; i < 6; i++ ) {
                    obj = {
                        ...obj,
                        [ 'image' + (i + 1) + '_id' ]: '',
                        [ 'image' + (i + 1) + '_order' ]: i + 1,
                        [ 'image' + (i + 1) ]: ''
                    }
                }
            }
            setImages( { ...images, ...obj } )
        }

        const SaveImages = () => {
            setPreloader( true )
            let store_id = store.id;
            if ( logo.logo_from_inp ) {
                updateStoreLogo( store_id, logo.logo_from_inp )
                    .then( () => {
                        setPopUpMessage( 'Updated' )
                        setUpdated( true )
                        setPreloader( false )
                    } )
            }
            if ( images.image1_id && images.image1_from_inp ) {
                updateStoreImage( images.image1_id, images.image1_from_inp, images.image1_order ).then( () => setPreloader( false ) )
            } else if ( !images.image1_id && images.image1_from_inp ) {
                createStoreImage( store_id, images.image1_from_inp, images.image1_order ).then( () => setPreloader( false ) )
            }

            if ( images.image2_id && images.image2_from_inp ) {
                updateStoreImage( images.image2_id, images.image2_from_inp, images.image2_order ).then( () => setPreloader( false ) )
            } else if ( !images.image2_id && images.image2_from_inp ) {
                createStoreImage( store_id, images.image2_from_inp, images.image2_order ).then( () => setPreloader( false ) )
            }

            if ( images.image3_id && images.image3_from_inp ) {
                updateStoreImage( images.image3_id, images.image3_from_inp, images.image3_order ).then( () => setPreloader( false ) )
            } else if ( !images.image3_id && images.image3_from_inp ) {
                createStoreImage( store_id, images.image3_from_inp, images.image3_order ).then( () => setPreloader( false ) )
            }

            if ( images.image4_id && images.image4_from_inp ) {
                updateStoreImage( images.image4_id, images.image4_from_inp, images.image4_order ).then( () => setPreloader( false ) )
            } else if ( !images.image4_id && images.image4_from_inp ) {
                createStoreImage( store_id, images.image4_from_inp, images.image4_order ).then( () => setPreloader( false ) )
            }

            if ( images.image5_id && images.image5_from_inp ) {
                updateStoreImage( images.image5_id, images.image5_from_inp, images.image5_order ).then( () => setPreloader( false ) )
            } else if ( !images.image5_id && images.image5_from_inp ) {
                createStoreImage( store_id, images.image5_from_inp, images.image5_order ).then( () => setPreloader( false ) )
            }

            if ( images.image6_id && images.image6_from_inp ) {
                updateStoreImage( images.image6_id, images.image6_from_inp, images.image6_order ).then( () => setPreloader( false ) )
            } else if ( !images.image6_id && images.image6_from_inp ) {
                createStoreImage( store_id, images.image6_from_inp, images.image6_order ).then( () => setPreloader( false ) )
            }
            setClinicImagesHasChanged( false )
        }

        const inputsHandler = ( e ) => {
            let fieldName = e.target.name
            let fieldValue = e.target.value
            // setClinic({...clinic, [fieldName]: fieldValue})
            setStore( { ...store, [ fieldName ]: fieldValue } )
            setClinicInfoHasChanged( true )
        }

        useEffect( () => phoneMaskNumberInit() ) // PhoneMask Initialization

        const phoneHandler = ( e ) => {
            setClinicInfoHasChanged( true )
            let { valid, cleanPhoneNumber } = validatePhoneNumber( e )
            let arr = []
            if ( valid ) {
                arr.push( cleanPhoneNumber )
                setPhoneError( '' )
                setStore( { ...store, phone: arr } )
            } else {
                setPhoneError( 'Invalid phone number' )
            }
        }

        const imageHandler = ( e ) => {
            setClinicImagesHasChanged( true )
            const inpName = e.target.name
            const file = e.target.files[ 0 ]
            const fileType = e.target.files[ 0 ].type.split( '/' )[ 0 ]

            setFieldName( inpName )
            setImageToCrop( e.target.files[ 0 ] )
            setCropImage( true )

            if ( inpName === 'logo' ) {
                let readerLogo = new FileReader()
                readerLogo.onload = ( event ) => {
                    setLogo( {
                        ...logo,
                        logo_url: event.target.result,
                        logo_from_inp: file
                    } )
                }
                readerLogo.readAsDataURL( file )
            }
            if ( fileType === 'image' ) {
                let reader = new FileReader()
                reader.onload = ( event ) => {
                    setImages( {
                        ...images,
                        [ inpName + '_from_inp' ]: file,
                        [ inpName ]: event.target.result
                    } )
                }
                reader.readAsDataURL( file )
            }
        }

        // button for clear image field
        const clearBtnShow = <span className='clear_btn' onClick={ ( e ) => {
            let nameField = e.target.previousSibling.name
            setImages( {
                ...images,
                [ nameField + '_from_inp' ]: '',
                [ nameField ]: ''
            } )
        } }>clear</span>

        const updateEmployeeFetch = ( id, name, scan_code ) => {
            let objData = {}
            objData.id = id
            objData.name = name
            objData.scan_code = scan_code
            objData.store_id = currentClinicId
            objData.employee = {
                name: name,
                scan_code: scan_code
            }
            updateEmployee( objData )
                .then( data => data )
        }

        const delEmployee = ( id ) => {
            setPreloader( true )
            deleteEmployee( id )
                .then( () => {
                    updateEmployees()
                    setPreloader( false )
                    setUpdated( true )
                    setPopUpMessage( 'Employee deleted' )
                } )
        }

        const createNewEmployeeFetch = ( name, scan_code ) => {
            setPreloader( true )
            let objData = {}
            objData.name = name
            objData.scan_code = scan_code
            objData.isEmailToBeSent = true
            objData.store_id = currentClinicId
            objData.employee = {
                name: name,
                scan_code: scan_code
            }
            createNewEmployee( objData )
                .then( () => {
                    updateEmployees()
                    setPreloader( false )
                    setPopUpMessage( 'Employee created' )
                    setUpdated( true )
                } )
        }


        return (
            <div className='clinicProfile'>
                { cropImage
                    ?
                    // Component for cropping the image before recording
                    <CropImage
                        fieldName={ fieldName } // the name of the field from which the image was obtained
                        closeElement={ setCropImage } // close component function
                        image={ imageToCrop } // image object to crop
                        setImagesState={ setImages } // set new images to state function
                        imagesState={ images } // images state
                        setLogoState={ setLogo } // set new logo function
                        logoState={ logo } // logo state
                    />
                    :
                    null }
                <div className='clinicProfile component__wrap white'>
                    <div className="clinicProfile__name dashboard__component_title">
                        <h2>{ store.city } profile</h2>
                    </div>
                    <div className='component__subtitle'>
                        <h3>Details</h3>
                    </div>
                    <form className="clinicProfile__form form">
                        <div className="form_field address">
                            <label htmlFor="address">Address</label>
                            <input type="text"
                                   id='address'
                                   name='address'
                                   defaultValue={ store.address }
                                   onChange={ ( e ) => {
                                       // inputsHandler(e)
                                       AutoComplete( e )
                                   } }
                                   className='form__input'/>
                        </div>
                        <div className="form_field phone_number">
                            <label htmlFor="phone">Phone number</label>
                            <input type="tel"
                                   id='phone'
                                   name='phone'
                                   placeholder='ten digits'
                                   defaultValue={ store.phone }
                                   onChange={ ( e ) => {
                                       phoneHandler( e )
                                   } }
                                   className='form__input'/>
                            <p className="password__error">{ phoneError }</p>
                        </div>
                        <div className="form_field location">
                            <label htmlFor="location_city">Location Name (city)</label>
                            <input type="text"
                                   disabled={ true }
                                   id='location_city'
                                   autoComplete={ 'new-password' }
                                   name='city_id'
                                   defaultValue={ store.city }
                                   onChange={ ( e ) => {
                                       // inputsHandler(e)
                                       AutoComplete( e )
                                   } }
                                   runat="server"
                                   className='form__input'/>
                        </div>
                        <div className="form_field website">
                            <label htmlFor="website">Website</label>
                            <input type="text"
                                   id='website'
                                   name='website'
                                   defaultValue={ store.website }
                                   onChange={ ( e ) => {
                                       inputsHandler( e )
                                   } }
                                   className='form__input'/>
                        </div>
                        <div className="form_field hours">
                            <label htmlFor="hours">Hours</label>
                            <input type="text"
                                   id='hours'
                                   name='work_time'
                                   defaultValue={ store.work_time }
                                   onChange={ ( e ) => {
                                       inputsHandler( e )
                                   } }
                                   className='form__input'/>
                        </div>
                        <div className="form_field facebook">
                            <label htmlFor="facebook">Facebook</label>
                            <input type="text"
                                   id='facebook'
                                   name='facebook_link'
                                   defaultValue={ store.facebook_link }
                                   onChange={ ( e ) => {
                                       inputsHandler( e )
                                   } }
                                   className='form__input'/>
                        </div>
                        <div className="form_field instagram">
                            <label htmlFor="instagram">Instagram</label>
                            <input type="text"
                                   id='instagram'
                                   name='instagram_link'
                                   defaultValue={ store.instagram_link }
                                   onChange={ ( e ) => {
                                       inputsHandler( e )
                                   } }
                                   className='form__input'/>
                        </div>
                        <div className="form_field youtube">
                            <label htmlFor="youtube">YouTube</label>
                            <input type="text"
                                   id='youtube'
                                   name='youtube_link'
                                   defaultValue={ store.youtube_link }
                                   onChange={ ( e ) => {
                                       inputsHandler( e )
                                   } }
                                   className='form__input'/>
                        </div>
                        <button className="save_btn btn wide_btn beige_btn"
                                disabled={ !clinicInfoHasChanged || phoneError }
                                onClick={ ( e ) => {
                                    e.preventDefault()
                                    Save();
                                } }>Save changes
                        </button>
                    </form>
                    <div className='component__subtitle'>
                        <h3>Images</h3>
                    </div>
                    <form className="clinicProfile__form images form">
                        <div className="form_block">
                            <div className="form_field image">
                                <label htmlFor="image1">Image 1
                                    <span>{ images.image1_from_inp.name || 'Browse' }</span>
                                </label>
                                <input type="file"
                                       id='image1'
                                       name='image1'
                                       onChange={ ( e ) => {
                                           imageHandler( e )
                                       } }
                                       className='form__input'/>
                                { images.image1 ? clearBtnShow : null }
                                <div className='preview'>
                                    <img src={ images.image1 || defaultImage } alt=''/>
                                </div>
                            </div>
                            <div className="form_field image">
                                <label htmlFor="image2">Image 2
                                    <span>{ images.image2_from_inp.name || 'Browse' }</span>
                                </label>
                                <input type="file"
                                       id='image2'
                                       name='image2'
                                       onChange={ ( e ) => {
                                           imageHandler( e )
                                       } }
                                       className='form__input'/>
                                { images.image2 ? clearBtnShow : null }
                                <div className='preview'>
                                    <img src={ images.image2 || defaultImage } alt=''/>
                                </div>
                            </div>
                            <div className="form_field image">
                                <label htmlFor="image3">Image 3
                                    <span>{ images.image3_from_inp.name || 'Browse' }</span>
                                </label>
                                <input type="file"
                                       id='image3'
                                       name='image3'
                                       onChange={ ( e ) => {
                                           imageHandler( e )
                                       } }
                                       className='form__input'/>
                                { images.image3 ? clearBtnShow : null }
                                <div className='preview'>
                                    <img src={ images.image3 || defaultImage } alt=''/>
                                </div>
                            </div>
                            <div className="form_field image">
                                <label htmlFor="image4">Image 4
                                    <span>{ images.image4_from_inp.name || 'Browse' }</span>
                                </label>
                                <input type="file"
                                       id='image4'
                                       name='image4'
                                       onChange={ ( e ) => {
                                           imageHandler( e )
                                       } }
                                       className='form__input'/>
                                { images.image4 ? clearBtnShow : null }
                                <div className='preview'>
                                    <img src={ images.image4 || defaultImage } alt=''/>
                                </div>
                            </div>
                            <div className="form_field image">
                                <label htmlFor="image5">Image 5
                                    <span>{ images.image5_from_inp.name || 'Browse' }</span>
                                </label>
                                <input type="file"
                                       id='image5'
                                       name='image5'
                                       onChange={ ( e ) => {
                                           imageHandler( e )
                                       } }
                                       className='form__input'/>
                                { images.image5 ? clearBtnShow : null }
                                <div className='preview'>
                                    <img src={ images.image5 || defaultImage } alt=''/>
                                </div>
                            </div>
                            <div className="form_field image">
                                <label htmlFor="image6">Image 6
                                    <span>{ images.image6_from_inp.name || 'Browse' }</span>
                                </label>
                                <input type="file"
                                       id='image6'
                                       name='image6'
                                       onChange={ ( e ) => {
                                           imageHandler( e )
                                       } }
                                       className='form__input'/>
                                { images.image6 ? clearBtnShow : null }
                                <div className='preview'>
                                    <img src={ images.image6 || defaultImage } alt=''/>
                                </div>
                            </div>
                        </div>
                        <div className="form_field image logo">
                            <label htmlFor="logo">Logo
                                <span>
                            { (logo.logo_url || logo.logo_from_inp) ?
                                <img src={ logo.logo_url || defaultImage } alt='logo'/> : 'Browse' }
                        </span>
                            </label>
                            <input type="file"
                                   id='logo'
                                   name='logo'
                                   onChange={ ( e ) => {
                                       imageHandler( e )
                                   } }
                                   className='form__input'/>
                            {
                                (logo.logo_url || logo.logo_from_inp)
                                    ?
                                    <span className='clear_btn' onClick={ () => {
                                        setLogo( {
                                            ...logo,
                                            logo_url: '',
                                            logo_from_inp: ''
                                        } )
                                    } }>clear</span>
                                    :
                                    null
                            }
                        </div>

                        <button className="save_btn btn wide_btn beige_btn"
                                disabled={ !clinicImagesHasChanged }
                                onClick={ ( e ) => {
                                    e.preventDefault()
                                    SaveImages()
                                } }>Save changes
                        </button>
                    </form>
                    <div className='component__subtitle'>
                        <h3>Employee Section</h3>
                    </div>
                    <form className="clinicProfileEmployee__form form" onSubmit={ e => {
                        e.preventDefault()
                    } }>
                        {
                            employees.map( ( element, index ) => {
                                return <div className='field_wrap' key={ index }>
                                    <div className='field_subwrap'>
                                        <div className="form_field badge">
                                            <label htmlFor="badge2">Badge { (index + 1) }</label>
                                            <input type="text"
                                                   id={ 'badge' + (index + 1) }
                                                   placeholder='Name'
                                                   defaultValue={ element.name }
                                                   readOnly={ !element.newEmpl }
                                                   name={ 'badge' + (index + 1) }
                                                   onBlur={ ( e ) => {
                                                       let newEmployees = [ ...employees ]
                                                       newEmployees[ index ] = {
                                                           ...employees[ index ],
                                                           name: e.target.value
                                                       }
                                                       setEmployees( newEmployees )
                                                   } }
                                                   className='form__input'/>
                                        </div>
                                        <div className="form_field code">
                                            <label htmlFor="code">Code</label>
                                            <input type="text"
                                                   id='code'
                                                   readOnly={ true }
                                                   placeholder='Click Generate below'
                                                   name='code'
                                                   value={ element.scan_code }
                                                   className='form__input'/>
                                        </div>
                                        <button className="generate_btn wide_btn btn beige_btn"
                                                onClick={ () => {
                                                    setPreloader( true )
                                                    downloadBadge( element.id, currentClinicId ).then( ( data ) => {
                                                        let blob = new Blob( [ data ], { type: "application/pdf" } )
                                                        let link = document.createElement( "a" )
                                                        link.href = window.URL.createObjectURL( blob )
                                                        link.download = "Badge.pdf"
                                                        link.click()
                                                        setPreloader( false )
                                                    } )
                                                } }>
                                            Download Badge
                                        </button>
                                        <button className="generate_btn wide_btn btn beige_btn"
                                                disabled={ element.changed }
                                                onClick={ () => {
                                                    let newEmployees = [ ...employees ]
                                                    newEmployees[ index ] = {
                                                        ...employees[ index ],
                                                        scan_code: generateScanCode(),
                                                        changed: true
                                                    }
                                                    setEmployees( newEmployees )
                                                } }>Generate
                                        </button>
                                    </div>
                                    <div className='button_wrap'>
                                        <button className='wide_btn btn beige_btn'
                                                disabled={ !element.id }
                                                onClick={ () => {
                                                    delEmployee( element.id )
                                                } }>Delete
                                        </button>
                                        <button className="generate_btn wide_btn btn beige_btn"
                                                onClick={ () => {
                                                    let newEmployees = [ ...employees ]
                                                    newEmployees[ index ] = {
                                                        ...employees[ index ],
                                                        changed: false,
                                                        newEmpl: false
                                                    }
                                                    setEmployees( newEmployees )
                                                    if ( !element.newEmpl ) { // IF EXISTING EMPLOYEE
                                                        updateEmployeeFetch( element.id, element.name, element.scan_code )
                                                    } else { // IF NEW EMPLOYEE
                                                        createNewEmployeeFetch( element.name, element.scan_code )
                                                    }

                                                } }
                                                disabled={ !element.changed }>Save changes
                                        </button>
                                    </div>
                                </div>
                            } )
                        }
                        <button className="add_new_employee_btn wide_btn btn beige_btn"
                                onClick={ () => {
                                    let obj = {
                                        newEmpl: true,
                                        name: '',
                                        scan_code: '',
                                        isEmailToBeSent: '',
                                        store_id: currentClinicId,
                                        employee: {
                                            name: '',
                                            scan_code: ''
                                        }
                                    }
                                    setEmployees( [ ...employees, obj ] )
                                } }>Add New Employee
                        </button>
                    </form>
                    {
                        updated
                            ?
                            <PopUp popUpMessage={ popUpMessage }
                                   closeBtnTitle={ 'Close' }
                                   showPopUp={ setUpdated }
                                   closePopUp={ setUpdated }/>
                            :
                            null
                    }
                </div>
                { !preloader || <Preloader/> }
            </div>
        );
    }
;

export default ClinicProfile;