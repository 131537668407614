import { getCookie } from "../helperFunctions";

const base_url = 'https://prolleniumelite.epoxyapp.com/api'
const headers = {
    'Content-Type': 'application/json',
    'API-TOKEN': getCookie( 'token' )
}

export async function getToken( data = {} ) {

    const response = await fetch( base_url
        + '/v2/admins/sign_in', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify( data )
    } );
    return await response.json();
}

export async function tokenVerification() {
    const response = await fetch( 'https://prolleniumelite.epoxyapp.com/api/v2/admins/validate_token', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } );
    return await response.json();
}

export async function forgotPassword( data ) {
    const response = await fetch( base_url
        + '/v2/admins/forgot_password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: JSON.stringify( data )
    } )
    return await response.json()
}

export async function getAdminInfo( id ) {
    const response = await fetch( base_url
        + '/v2/admins/'
        + id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } );
    return await response.json();
}

export async function updateAdminInfo( data ) {
    const response = await fetch( base_url
        + '/v2/admins/'
        + data.id, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: JSON.stringify( data )
    } );
    return await response.json();
}

export async function updateAdminPassword( data ) {
    const response = await fetch( base_url
        + '/v2/admins/change_password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: JSON.stringify( data )
    } )
    return await response.json()
}

export async function getStatistic( data = {} ) {
    let parameters = '';
    Object.keys( data ).forEach( value => {
        parameters += value + "=" + data[ value ] + "&"
    } )
    const response = await fetch( base_url
        + '/v2/statistics/amount_statistic?'
        + parameters, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } );
    return await response.json();
}


export async function getCompanyInfo() {
    const response = await fetch( base_url
        + '/v4/dashboard/main', {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } )
    return await response.json()
}

export async function getUsers( data = {} ) {
    let parameters = '';
    Object.keys( data ).forEach( value => {
        parameters += value + "=" + data[ value ] + "&"
    } )
    const response = await fetch( base_url
        + '/v2/users?'
        + parameters, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } );
    return await response.json();
}

export async function getUser( id ) {
    const response = await fetch( base_url
        + '/v2/users/'
        + id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } );
    return await response.json();
}

export async function getUserLoyaltyCards( user_id ) {
    const response = await fetch( base_url
        + '/v2/users/'
        + user_id + '/loyalty_cards'
        // + '/loyalty_cards?company_id=' + 667
        // + '&store_id=' + 791
        // + '&customer_id=' + user_id
        , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function getFullCreditsDetails( company_id ) {
    const response = await fetch( base_url
        + '/v4/dashboard/full_credits_details?company_id='
        + company_id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } )
    return await response.json()
}

export async function getCompanyInventories( company_id ) {
    const response = await fetch( base_url
        + '/v2/companies/'
        + company_id
        + '/inventories', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } )
    return await response.json()
}

export async function toUseInventories( data, actable_type ) {
    const response = await fetch( base_url
        + '/v2/'
        + actable_type
        + '/activity_with_inventory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: JSON.stringify( data )
    } )
    return await response.json()
}

export async function toRedeemCard( data ) {
    const response = await fetch( base_url
        + '/v2/credit_punch_cards/reward_with_inventory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: JSON.stringify( data )
    } )
    return await response.json()
}

export async function toSendMarketingMessage( data ) {
    // let data =new FormData()
    const response = await fetch( base_url
        + '/v2/messages', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
    } )
    return await response.json()
}

export async function updateUser( data = {} ) {
    const response = await fetch( base_url
        + '/v2/users/'
        + data.id, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: JSON.stringify( data )
    } );
    return await response.json();
}

export async function addNewPatient( data = {} ) {
    const response = await fetch( base_url
        + '/v2/users/users_by_phone', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: JSON.stringify( data )
    } );
    return await response.json();
}

export async function filterColumn( data = {} ) {
    let parameters = ''
    Object.keys( data ).forEach( value => {
        parameters += value + "=" + data[ value ] + "&"
    } )
    const response = await fetch( base_url
        + '/v2/users?'
        + parameters, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } );
    return await response.json();
}

export async function getPromotions( request ) {
    const response = await fetch( base_url
        + '/v4/dashboard/promotion_entries_'
        + request, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } )
    return await response.json()
}

export async function getReimbursement( requestType, company_id, store_id ) {
    const response = await fetch( base_url
        + '/v4/dashboard/'
        + requestType
        + '_product_histories?company_id='
        + company_id
        + '&store_id='
        + store_id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } )
    return await response.json()
}

export async function getPromoBox() {
    const response = await fetch( base_url
        + '/v4/admin_promos/admin_promo', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } )
    return await response.json()
}

export async function getPurchasingHistoryCountByStore( companyId, storeId ) {
    const response = await fetch( base_url
        + '/v4/dashboard/purchase_history_count?company_id='
        + companyId
        + '&store_id='
        + storeId,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function getPurchaseHistoryByStore( companyId, storeId ) {
    const response = await fetch( base_url
        + '/v4/dashboard/purchase_history?company_id='
        + companyId
        + '&store_id='
        + storeId,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}