import React, { useEffect, useState } from 'react';
import IconEdit from "../../../Icons/IconEdit";
import IconFilter from "../../../Icons/IconFilter";
import Preloader from "../../../Preloader/Preloader";
import { getPromotions } from "../../../../requests/requests";
import { Link } from "react-router-dom";
import { useAdminStore, useClinics, useCompany } from "../../../../stores/adminStore";
import { getPromotionsRep } from "../../../../requests/requestd_selected_clinic_statistics";

const DashboardPromotions = ( { toShow } ) => {

    /******************** GLOBAL STORE **************************/
    const isRepAdmin = useAdminStore( state => state.isRepAdmin )
    const currentClinicId = useClinics( state => state.currentClinicId )
    const companyId = useCompany( state => state.companyId )
    /***********************************************************/

    const [ preloader, setPreloader ] = useState( true )
    const [ promotions, setPromotions ] = useState( [] )

    useEffect( () => {
        setPreloader( true )
        if ( isRepAdmin ) {
            getPromotionsRep( toShow.toLowerCase(), companyId, currentClinicId )
                .then( data => {
                    if ( !('error' in data) ) {
                        setPromotions( data?.entries )
                        setPreloader( false )
                    } else {
                        console.log( data.errors )
                        setPreloader( false )
                    }
                } )
        } else {
            getPromotions( toShow.toLowerCase() )
                .then( data => {
                    if ( !('errors' in data) ) {
                        setPromotions( data?.entries )
                        setPreloader( false )
                    } else {
                        console.log( data.errors )
                        setPreloader( false )
                    }
                } )
        }
    }, [] )

    return (
        <div className='promotions'>
            <div className='component__wrap'>
                <div className="promotions__title dashboard__component_title">
                    <h2>{ 'PROMOTIONS ' + toShow }</h2>
                </div>
                <table className="promotions__table table">
                    <thead>
                    <tr>
                        <th>ID<IconFilter/></th>
                        <th>Name<IconFilter/></th>
                        <th>Location<IconFilter/></th>
                        <th>Date<IconFilter/></th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    { promotions.length ? promotions.map( ( el, index ) => {
                        return <tr key={ index }>
                            <td>{ el.id }</td>
                            <td>
                                <Link to={ '/patients/patient-edit-account/' + el.profile_id }>
                                    { el.profile_name }
                                </Link>
                            </td>
                            <td>{ el.store_name }</td>
                            <td>{ el.created_at }</td>
                            <td>
                                <Link to={ '/patients/patient-edit-account/' + el.profile_id }>
                                    <IconEdit/>
                                </Link>
                            </td>
                        </tr>
                    } ) : '' }
                    </tbody>
                </table>
                { promotions.length
                    ?
                    ''
                    :
                    <h3 style={ { marginTop: '20px', fontSize: '20px', textAlign: 'center' } }>Nothing found</h3>
                }
            </div>
            { !preloader || <Preloader/> }
        </div>
    )
}

export default DashboardPromotions;