import React from 'react';
import './DashboardCard.scss'
import IconPlus from "../../../Icons/IconPlus";
import { Link } from "react-router-dom";

const DashboardCard = ( { link, count, icon, title, onClick } ) => {

    return (
        link
            ?
            <Link className='card pointer' to={ link }>
                <div className="icon">{ icon }</div>
                <div className="count"><p>{ count }</p></div>
                <div className="title"><p>{ title }</p></div>
                <div className="add_btn"><IconPlus/></div>
            </Link>
            :
            <div className={ onClick ? 'card pointer' : 'card' } onClick={ onClick }>
                <div className="icon">{ icon }</div>
                <div className="count"><p>{ count }</p></div>
                <div className="title"><p>{ title }</p></div>
                { onClick ? <div className="add_btn"><IconPlus/></div> : null }
            </div>
    );
};

export default DashboardCard;