import React, { useEffect, useState } from 'react';
import './SIdebar.scss'
import IconDashboard from "../../Icons/IconDashboard";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import IconPatients from "../../Icons/IconPatients";
import IconClinic from "../../Icons/IconClinic";
import IconResources from "../../Icons/IconResources";
import IconLogout from "../../Icons/IconLogout";
import { logout } from "../../../helperFunctions";
import IconReport from "../../Icons/IconReport";
import { useAdminStore, useCompany } from "../../../stores/adminStore";
import IconReturn from "../../Icons/IconReturn";

const Sidebar = () => {

    // Admin role from store
    /*********************** GLOBAL STORE ***********************/
    const isRepAdmin = useAdminStore( state => state.isRepAdmin )
    const resetRole = useAdminStore( state => state.resetRole )
    const companyId = useCompany( state => state.companyId )
    /***********************************************************/

    const navigate = useNavigate()

    // WINDOW LOCATION
    const location = useLocation()
    const pageURL = location.pathname

    const closeSidebar = () => {
        let contrPan = document.querySelector( '.controlPanel' )
        contrPan.classList.toggle( 'open_sidebar' )
    }

    const [ statsBtn, setStatsBtn ] = useState( false )

    const checkFunc = () => {
        if ( isRepAdmin ) {
            if ( pageURL.includes( '/patients/patient-edit-account/' ) ) return true
            if ( pageURL.includes( '/rebates-applied' ) ) return true
            if ( pageURL.includes( '/reimbursement-shipped' ) ) return true
            if ( pageURL.includes( '/promotion-purchased' ) ) return true
            if ( pageURL.includes( '/promotion-redeemed' ) ) return true
            if ( pageURL.includes( '/purchasing-history' ) ) return true
        } else {
            return false
        }
    }

    useEffect( () => {
        setStatsBtn( checkFunc() )
    }, [ pageURL ] )

    return (
        <div className='sidebar'>
            <div className="burger_menu" onClick={ () => {
                closeSidebar()
            } }>
                <span className="line_top line">
                </span>
                <span className="line_middle line">
                </span>
                <span className="line_bottom line">
                </span>
            </div>
            <nav className={ 'sidebar__menu' }>
                <ul>
                    {
                        statsBtn
                            ?
                            <li>
                                <div className='link logout'
                                     onClick={ () => {
                                         if ( companyId ) {
                                             navigate( `/selected-clinic/${ companyId }` )
                                         } else {
                                             navigate( -1 )
                                         }
                                     } }><IconReturn/>Stats
                                </div>
                            </li>
                            : null
                    }

                    <li>
                        <NavLink to={ '/' } className={ 'link' }><IconDashboard/>Dashboard</NavLink>
                    </li>
                    {
                        isRepAdmin
                            ?
                            <li><NavLink to={ '/reports' } className={ 'link' }><IconReport/>Reports</NavLink></li>
                            :
                            <>
                                <li><NavLink to={ '/patients?page=1' }
                                             className={ 'link' }><IconPatients/>Patients</NavLink></li>
                                <li><NavLink to={ '/clinic-profile' } className={ 'link' }><IconClinic/>Clinic
                                    Profile</NavLink></li>
                            </>
                    }


                    <li>
                        <NavLink to={ '/resources' } className={ 'link' }><IconResources/>Resources</NavLink>
                    </li>
                    <li>
                        <div className='link logout'
                             onClick={ () => {
                                 logout()
                                 resetRole()
                                 navigate( '/login' )
                             } }><IconLogout/>Logout
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;