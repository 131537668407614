import React, {useEffect, useState} from 'react';
import './AddNewPatient.scss'
import PopUp from "../../PopUp/PopUp";
import {addNewPatient} from "../../../requests/requests";
import Preloader from "../../Preloader/Preloader";
import {phoneMaskNumberInit, validatePhoneNumber} from "../../../helperFunctions";
import {useNavigate} from "react-router-dom";
import {useAdminStore, useClinics, useCompany} from "../../../stores/adminStore";
import {addNewPatientInRepAdmin} from "../../../requests/rep_selected_clinic_requests";

const AddNewPatient = () => {

    /************************STORE***************************/
    const isRepAdmin = useAdminStore(state => state.isRepAdmin)
    const currentClinicId = useClinics(state => state.currentClinicId)
    const companyId = useCompany(state => state.companyId)
    /********************************************************/

    const navigate = useNavigate()
    const [preloader, setPreloader] = useState(false)
    const [newPatient, setNewPatient] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        zip: '',
        gender: 0,
        birth_date: '',
    })
    const [phoneError, setPhoneError] = useState('')
    const [updated, setUpdated] = useState(false)
    const [popUpMess, setPopUpMess] = useState('Updated')
    const [newPatientCreated, setNewPatientCreated] = useState(false)
    const [newPatientId, setNewPatientId] = useState(null)

    const inputsHandler = (e) => {
        let name = e.target.name
        let val = e.target.value
        if (name === 'gender') {
            let numVal = Number(val)
            setNewPatient({...newPatient, [name]: numVal})
        } else if (name === 'birth_date') {
            let dateVal = new Date(val).toISOString()
            setNewPatient({...newPatient, [name]: dateVal})
        } else {
            setNewPatient({...newPatient, [name]: val})
        }
    }

    useEffect(() => phoneMaskNumberInit()) // PhoneMask Initialization

    const phoneHandler = (e) => {
        let {valid, cleanPhoneNumber} = validatePhoneNumber(e)
        if (valid) {
            setPhoneError('')
            setNewPatient({...newPatient, phone_number: cleanPhoneNumber})
        } else {
            setPhoneError('Invalid phone number')
        }
    }

    useEffect(() => {
        // if ( !(isRepAdmin && companyId && currentClinicId) ) navigate( -1 )
        // console.log( 'newPatientCreated', newPatientCreated, 'updated', updated, 'newPatientId', newPatientId )
        if (newPatientCreated && !updated && newPatientId) {
            navigate('/patients/patient-edit-account/' + newPatientId)
        }
    }, [updated])

    const newPatientAdd = () => {
        setPreloader(true)
        if (companyId && currentClinicId) {
            addNewPatient({
                user: newPatient,
                company_id: companyId,
                store_id: currentClinicId
            }).then(data => {
                if (!('errors' in data)) {
                    setPreloader(false)
                    setPopUpMess('A new user has been created.')
                    setNewPatientId(data.id)
                    setUpdated(true)
                    setNewPatientCreated(true)
                } else {
                    setPreloader(false)
                    setPopUpMess('A USER WITH THESE CREDENTIALS ALREADY EXISTS')
                    setUpdated(true)
                }
            })
        }
    }

    const newPatientForRep = () => {
        setPreloader(true)
        const data = {...newPatient, company_id: companyId, store_id: currentClinicId}
        addNewPatientInRepAdmin(data)
            .then((data) => {
                console.log(data)
                if (!('errors' in data)) {
                    setPreloader(false)
                    setPopUpMess('A new user has been created.')
                    setNewPatientId(data.id)
                    setUpdated(true)
                    setNewPatientCreated(true)
                } else {
                    setPreloader(false)
                    setPopUpMess('A USER WITH THESE CREDENTIALS ALREADY EXISTS')
                    setUpdated(true)
                }
            })
    }

    // console.log( 'currentClinicId', currentClinicId )
    // console.log( 'companyId', companyId )

    return (
        <div className='addNewPatient'>
            <div className='component__wrap white'>
                <div className="addNewPatient__title dashboard__component_title">
                    <h2>ADD NEW PATIENT</h2>
                </div>
                <div className='component__subtitle'>
                    <h3>New Patient Form</h3>
                </div>
                <form className="addNewPatient__form form">
                    <div className="form_field first_name">
                        <label htmlFor="first_name">First Name</label>
                        <input type="text"
                               id='first_name'
                               autoComplete='new-password'
                               name='first_name'
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                    </div>
                    <div className="form_field last_name">
                        <label htmlFor="last_name">Last Name</label>
                        <input type="text"
                               id='last_name'
                               autoComplete='new-password'
                               name='last_name'
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                    </div>
                    <div className="form_field phone_number">
                        <label htmlFor="phone">Phone number</label>
                        <input type="tel"
                               id='phone'
                               name='phone_number'
                               onInput={(e) => {
                                   phoneHandler(e)
                               }}
                               className='form__input'/>
                        <p className="password__error">{phoneError}</p>
                    </div>
                    <div className="form_field zip_code">
                        <label htmlFor="zip_code">Zip Code</label>
                        <input type="text"
                               id='zip_code'
                               autoComplete='new-password'
                               name='zip'
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                    </div>
                    <div className="form_field gender">
                        <label htmlFor="gender">Gender</label>
                        <select name="gender"
                                id="gender"
                                onChange={(e) => {
                                    inputsHandler(e)
                                }}
                                className='form__input'>
                            <option value="0">Male</option>
                            <option value="1">Female</option>
                        </select>
                    </div>
                    <div className="form_field birthday">
                        <label htmlFor="birthday">Birthday</label>
                        <input type="date"
                               id='birthday'
                               max={'2099-12-31'}
                               min={'1900-01-01'}
                               name='birth_date'
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                    </div>
                </form>
                <button className="save_btn btn wide_btn beige_btn"
                        disabled={!newPatient.first_name || !newPatient.last_name || !newPatient.phone_number || phoneError}
                        onClick={() => {
                            if (isRepAdmin) {
                                newPatientForRep()
                            } else {
                                newPatientAdd()
                            }
                        }}
                >Save changes
                </button>
                {
                    updated
                        ?
                        <PopUp popUpMessage={popUpMess}
                               closeBtnTitle={'Close'}
                               showPopUp={setUpdated}
                               closePopUp={setUpdated}/>
                        :
                        null
                }
                {!preloader || <Preloader/>}
            </div>
        </div>
    )
}

export default AddNewPatient;