import React from 'react';
import DashboardCard from "../Dashboard/DashboardCards/DashboardCard";
import IconRebates from "../../Icons/IconRebates";
import IconClinic from "../../Icons/IconClinic";
import IconSyringe from "../../Icons/IconSyringe";
import IconBus from "../../Icons/IconBus";
import {
    getClinicListReport,
    getDetailedReport,
    getMonthlySummaryReport,
    getOrderListReport
} from "../../../requests/requests_reports";

const Reports = () => {

    return (
        <div className='component__wrap'>
            <div className="dashboard__title dashboard__component_title">
                <h2>Reports</h2>
            </div>
            <div className="cards">
                <DashboardCard icon={ <IconClinic/> }
                               count={ 107 }
                               title={ 'Clinic List' }
                               onClick={ () => getClinicListReport() }/>
                {/*<DashboardCard icon={ <IconRebates/> }*/}
                {/*               count={ 17 }*/}
                {/*               title={ 'Recent Orders (Previous 90 days)' }*/}
                {/*               onClick={ () => {*/}
                {/*                   const currentDate = new Date().toISOString()*/}
                {/*                   getDetailedReport( '2022-01-01T00:00:00.000Z', currentDate )*/}
                {/*               } }/>*/}
                <DashboardCard icon={ <IconSyringe/> }
                               count={ 0 }
                               title={ 'Order History' }
                               onClick={ () => {
                                   const currentDate = new Date().toISOString()
                                   getOrderListReport( '1259', '2022-01-01T00:00:00.000Z', currentDate )
                               } }/>
                {/*<DashboardCard icon={ <IconBus/> }*/}
                {/*               count={ 100 }*/}
                {/*               title={ 'Rewards Syringes Shipped To-Date' }*/}
                {/*               onClick={ () => {*/}
                {/*                   const currentDate = new Date().toISOString()*/}
                {/*                   getMonthlySummaryReport( '1259', '2022-01-01T00:00:00.000Z', currentDate )*/}
                {/*               } }/>*/}
            </div>
        </div>
    );
};

export default Reports;