import React from 'react';

const IconReturn = () => {
    return (
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
             focusable="false"
             aria-hidden="true"
             viewBox="0 0 24 24"
             data-testid="LeaderboardIcon">
            <path d="M7.5 21H2V9h5.5v12zm7.25-18h-5.5v18h5.5V3zM22 11h-5.5v10H22V11z"></path>
        </svg>
    );
};

export default IconReturn;