import { getCookie } from "../helperFunctions";

export async function getRepSelectedClinicFirst( company_id ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/statistics?store_id=&company_id='
        +
        company_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function getRepSelectedClinic( company_id, location_id = '' ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/statistics?store_id='
        + location_id + '&company_id=' + company_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function getLocationAdmins( location_id ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/location_admins?store_id='
        + location_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return await response.json()
}

export async function addNewPatientInRepAdmin( data = {} ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/patients/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return await response.json();
}

export async function updateUserForRep( data = {}, user_id ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/patients/'
        +
        user_id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return await response.json();
}

export async function updateSelectedClinic( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/company_full_update', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return await response.json()
}

export async function updateLocationAdmin( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/location_admins', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return response.json()
}

export async function addLocationAdmin( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/location_admins', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return response.json()
}

export async function changeLocationAdminPassword( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/location_admins/change_password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return response.json()
}

export async function getEmployeesList( sore_id, col_name, direction ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/employees?store_id='
        + sore_id
        + '&col_name='
        + col_name
        + '&direction='
        + direction, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
        } )
    return response.json()
}

export async function addNewEmployee( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/employees/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return response.json()
}

export async function updateEmployee( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/employees/'
        + data.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return response.json()
}

export async function getStatusTrainingMode() {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/trainings/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } )
    return response.json()
}

// data={active: true}
export async function setActiveStatusTrainingMode( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/trainings/set_training_active', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return response.ok
}

// data={complete: active}
export async function setCompleteStatusTrainingMode( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/trainings/set_training_complete', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return response.ok
}


export async function resetTrainingStatusTrainingMode( data ) {
    const response = await fetch(
        'https://prolleniumelite.epoxyapp.com/api/v5/trainings/reset_training', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            },
            body: JSON.stringify( data )
        } )
    return response.json()
}