import React, {useEffect, useRef, useState} from 'react';
import IconMinus from "../../../Icons/IconMinus";
import IconPlus from "../../../Icons/IconPlus";
import IconFilter from "../../../Icons/IconFilter";
import './PatientList.scss'
import IconSearch from "../../../Icons/IconSearch";
import {getPatientsList} from "../../../../requests/rep_requests";
import Preloader from "../../../Preloader/Preloader";
import {Link} from "react-router-dom";
import {useClinics, useCompany} from "../../../../stores/adminStore";

const PatientList = () => {

    /******************** GLOBAL STORE ********************************/
    const currentClinicId = useClinics(state => state.currentClinicId)
    const companyId = useCompany(state => state.companyId)
    /******************************************************************/

    const [preloader, setPreloader] = useState(true)
    const [patients, setPatients] = useState([])
    const [showPatientList, setShowPatientList] = useState(false)
    const [filterOptions, setFilterOptions] = useState({
        col_name: 'name',
        direction: 'desc',
        company_id: '',
        store_id: '',
        word: '',
    })
    const [direction, setDirection] = useState(true)
    const [searchUser, setSearchUser] = useState('')

    const getPatientsListFunc = () => {
        getPatientsList(filterOptions).then(data => {
            setPatients(data);
            setPreloader(false)
        }).catch(() => {
            window.location.href = "/login"
        })
    }

    useEffect(() => {
        if (companyId && currentClinicId) {
            setFilterOptions({...filterOptions, company_id: companyId, store_id: currentClinicId})
        }
    }, [companyId, currentClinicId])

    useEffect(() => {
        if (filterOptions.company_id && filterOptions.store_id) {
            getPatientsListFunc()
        }
    }, [filterOptions])

    const filterColumn = (param) => {
        setPreloader(true)
        setDirection(!direction)
        direction
            ?
            setFilterOptions({...filterOptions, col_name: param, direction: 'desc'})
            :
            setFilterOptions({...filterOptions, col_name: param, direction: 'asc'})
    }

    const searchHandler = (searchUserName, filterOptions) => {
        setPreloader(true)
        setFilterOptions({
            ...filterOptions,
            col_name: 'name',
            direction: 'desc',
            word: searchUserName,
        })
        getPatientsList(filterOptions).then(data => {
            setPatients(data);
            setPreloader(false)
        }).catch(() => {
            window.location.href = "/login"
        })
    }

    /********  To open and close a block  *********/
    const wrapper = useRef(null)
    const searchBlock = useRef(null)
    const tableBlock = useRef(null)
    useEffect(() => {
        if (showPatientList) {
            wrapper.current.style.height = searchBlock.current.clientHeight + tableBlock.current.clientHeight + 'px'
        } else {
            wrapper.current.style.height = 0
        }
    }, [showPatientList, patients])
    /**********************************************/

    return (
        <div className="component__wrap">
            <div className='component__subtitle'>
                <h3>Patient List</h3>
                <button className="show__form_btn"
                        style={{border: 'none'}}
                        onClick={() => setShowPatientList(!showPatientList)}
                >
                    {showPatientList ? <IconMinus/> : <IconPlus/>}
                </button>
            </div>
            <div ref={wrapper} className='table_wrap patient_list'>
                <div ref={searchBlock} className="search">
                    <form className="search_form">
                        <input type="text"
                               placeholder='Search someone...'
                               value={searchUser}
                               onChange={(e) => {
                                   setSearchUser(e.target.value)
                               }}
                               className='form__input'/>
                        <IconSearch/>
                        <button className="btn wide_btn black_btn"
                                onClick={(e) => {
                                    e.preventDefault()
                                    searchHandler(searchUser, filterOptions)
                                }}
                        >Search
                        </button>
                    </form>
                    <Link to={'/add-new-patient'}
                          className="new_patient_btn btn wide_btn beige_btn">
                        Add New Patient</Link>
                </div>
                <table ref={tableBlock} className='patient_list__table table'>
                    <thead>
                    <tr>
                        <th className='filtered'
                            onClick={() => {
                                filterColumn('name')
                            }}
                        >Name<IconFilter/></th>
                        <th className='filtered'
                            onClick={() => {
                                filterColumn('email')
                            }}
                        >Email<IconFilter/></th>
                        <th className='filtered'
                            // onClick={ () => {
                            //     filterColumn( 'email' )
                            // } }
                        >Phone Number<IconFilter/></th>
                        <th className='filtered'></th>
                    </tr>
                    </thead>
                    <tbody>
                    {patients.map((el, index) => {
                        return <tr key={index}>
                            <td>{el.first_name + ' ' + el.last_name}<br/><span>{el.created_at.split('T')[0]}</span>
                            </td>
                            <td>{el.email}</td>
                            <td>{el.phone_number}</td>
                            <td>
                                <Link to={'/patients/patient-edit-account/' + el.id}>
                                    <button className='view_btn'>View</button>
                                </Link>
                            </td>
                        </tr>
                    })
                    }
                    </tbody>
                </table>
                {patients.length
                    ?
                    ''
                    :
                    <h3 style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        paddingTop: '20px',
                        fontSize: '20px',
                        textAlign: 'center'
                    }}>Nothing found</h3>}
            </div>
            {!preloader || <Preloader/>}
        </div>
    );
};

export default PatientList;