import React from 'react';

const IconReport = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 4445 5218"
             shapeRendering="geometricPrecision"
             textRendering="geometricPrecision"
             imageRendering="optimizeQuality"
             fillRule="evenodd"
             clipRule="evenodd">
            <path
                d="M1339 3691c-74 0-134-60-134-134s60-134 134-134h996c74 0 134 60 134 134s-60 134-134 134h-996zm0-1896c-74 0-134-60-134-134s60-134 134-134h1767c74 0 134 60 134 134s-60 134-134 134H1339zM311 0h3823c86 0 164 35 220 91s91 134 91 220v4595c0 86-35 164-91 220s-134 91-220 91H311c-86 0-164-35-220-91S0 4992 0 4906V311c0-86 35-164 91-220S225 0 311 0zm3823 268H311c-12 0-23 5-31 13s-13 19-13 31v4595c0 12 5 23 13 31s19 13 31 13h3823c12 0 23-5 31-13s13-19 13-31V312c0-12-5-23-13-31s-19-13-31-13zM1339 2743c-74 0-134-60-134-134s60-134 134-134h1767c74 0 134 60 134 134s-60 134-134 134H1339z"
                fill="currentColor">
            </path>
        </svg>
    );
};

export default IconReport;