import React, { useEffect, useRef, useState } from 'react'
import './AdminDetails.scss'
import IconMinus from "../../../Icons/IconMinus"
import IconPlus from "../../../Icons/IconPlus"
import IconFilter from "../../../Icons/IconFilter"
import AddNewAdmin from "./AddNewAdmin";
import AdminsTableRow from "./AdminsTableRow";
import { getLocationAdmins } from "../../../../requests/rep_selected_clinic_requests";
import { useClinics } from "../../../../stores/adminStore";

const AdminDetails = () => {

    /******************** GLOBAL STORE ********************************/
    const currentClinicId = useClinics( state => state.currentClinicId )
    /******************************************************************/

    const [ showAdminDetails, setShowAdminDetails ] = useState( false )
    const [ admins, setAdmins ] = useState( [] )
    const [ initBlockSize, setIniBlockSize ] = useState( null )

    /********  To open and close a block  *********/
    const wrapper = useRef( null )
    const tableBlock = useRef( null )
    const newAdminBlock = useRef( null )
    useEffect( () => {
        if ( showAdminDetails ) {
            wrapper.current.style.height = tableBlock.current.clientHeight + newAdminBlock.current.clientHeight + 30 + 'px'
        } else {
            wrapper.current.style.height = 0
        }
    }, [ showAdminDetails, admins, initBlockSize ] )
    /**********************************************/

    const getLocationAdminsFunc = () => {
        getLocationAdmins( currentClinicId )
            .then( ( data ) => {
                if ( 'errors' in data ) {
                    console.log( data )
                } else {
                    setAdmins( data?.admins )
                }
            } )
    }

    useEffect( () => {
        if ( !currentClinicId ) return
        getLocationAdminsFunc()
    }, [ currentClinicId ] )

    return (
        <div className="component__wrap">
            <div className='component__subtitle'>
                <h3>Admin Details</h3>
                <button className="show__form_btn"
                        style={ { border: 'none' } }
                        onClick={ () => {
                            setShowAdminDetails( !showAdminDetails )
                        } }
                >
                    { showAdminDetails ? <IconMinus/> : <IconPlus/> }
                </button>
            </div>
            <div ref={ wrapper } className='table_wrap admin_details'>
                <table ref={ tableBlock } className='admin_details__table table'>
                    <thead>
                    <tr>
                        <th className='filtered'>Name<IconFilter/></th>
                        <th className='filtered'>Admin Email<IconFilter/></th>
                        <th className='filtered'>Phone Number<IconFilter/></th>
                        <th className='filtered'>Location</th>
                        <th className='filtered'></th>
                    </tr>
                    </thead>
                    <tbody>
                    { admins.map( ( el, index ) => {
                        return <AdminsTableRow el={ el } key={ index }
                                               setIniBlockSize={ setIniBlockSize }
                                               updateLocationAdminsList={ getLocationAdminsFunc }/>
                    } ) }
                    </tbody>
                </table>
                <div ref={ newAdminBlock }>
                    <AddNewAdmin updateLocationAdminsList={ getLocationAdminsFunc }/>
                </div>
            </div>
        </div>
    )
}

export default AdminDetails