import React from 'react';
import './DashboardCards.scss'
import IconRebates from "../../../Icons/IconRebates";
import IconSyringe from "../../../Icons/IconSyringe";
import IconBus from "../../../Icons/IconBus";
import IconCard from "../../../Icons/IconCard";
import IconLabel from "../../../Icons/IconLabel";
import IconGift from "../../../Icons/IconGift";
import DashboardCard from "./DashboardCard";

const DashboardCards = ( { company, purchasingHistory } ) => {

    return (
        <div className="cards">
            <DashboardCard icon={ <IconSyringe/> }
                           count={ (company?.purchased_syringes_remaining || '0')
                               + '/'
                               + (Math.floor( company?.available_credits ) || '0') }
                           title={ 'Purchased Syringes Remaining/Available Credit' }/>
            <DashboardCard icon={ <IconRebates/> }
                           count={ company?.rebates_applied }
                           title={ 'Total Credits Used' }
                           link={ '/dashboard/rebates-applied' }/>
            <DashboardCard icon={ <IconSyringe/> }
                           count={ company?.reimbursements_owed }
                           title={ 'Rewards Syringes Earned' }/>
            <DashboardCard icon={ <IconBus/> }
                           count={ company?.reimbursements_shipped }
                           title={ 'Rewards Syringes Shipped To-Date' }
                           link={ '/dashboard/reimbursement-shipped' }/>
            <DashboardCard icon={ <IconCard/> }
                           count={ company?.total_patients }
                           title={ 'Total Patients' }/>
            <DashboardCard icon={ <IconLabel/> }
                           count={ company?.promotion_purchased }
                           title={ 'Promotions Purchased' }
                           link={ '/dashboard/promotion-purchased' }/>
            <DashboardCard icon={ <IconGift/> }
                           count={ company?.promotion_redeemed }
                           title={ 'Promotions Redeemed' }
                           link={ '/dashboard/promotion-redeemed' }/>
            <DashboardCard icon={ <IconCard/> }
                           count={ purchasingHistory?.purchased_product }
                           title={ 'Purchasing History' }
                           link={ '/dashboard/order-history' }/>
        </div>
    )
}

export default DashboardCards