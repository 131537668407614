import React, { useEffect, useRef, useState } from 'react';
import IconMinus from "../../../Icons/IconMinus";
import IconPlus from "../../../Icons/IconPlus";
import IconFilter from "../../../Icons/IconFilter";
import './EmployeeDetails.scss'
import AddNewEmployee from "./AddNewEmployee";
import EmployeeTableRow from "./EmployeeTableRow";
import { useClinics } from "../../../../stores/adminStore";
import { getEmployeesList } from "../../../../requests/rep_selected_clinic_requests";

const EmployeeDetails = () => {

    /******************** GLOBAL STORE ********************************/
    const currentClinicId = useClinics( state => state.currentClinicId )
    /******************************************************************/

    const [ employees, setEmployees ] = useState( [] )
    const [ fetchParams, setFetchParams ] = useState( {
        col_name: 'phone_number',
        direction: 'desc'
    } )
    const [ showEmployees, setShowEmployees ] = useState( false )

    /********  To open and close a block  *********/
    const wrapper = useRef( null )
    const newEmployeeBlock = useRef( null )
    const tableBlock = useRef( null )
    useEffect( () => {
        if ( showEmployees ) {
            wrapper.current.style.height = newEmployeeBlock.current.clientHeight + tableBlock.current.clientHeight + 42 + 'px'
        } else {
            wrapper.current.style.height = 0
        }
    }, [ showEmployees, employees ] )
    /**********************************************/

    const getEmployeesListFunc = () => {
        if ( !currentClinicId ) return
        getEmployeesList( currentClinicId, fetchParams.col_name, fetchParams.col_name )
            .then( data => {
                if ( !data || ('errors' in data) ) {
                    console.log('errors', data )
                } else {
                    setEmployees( data )
                }
            } )
    }

    useEffect( () => {
        getEmployeesListFunc()
    }, [ currentClinicId ] )


    return (
        <div className="component__wrap">
            <div className='component__subtitle'>
                <h3>Employee Details</h3>
                <button className="show__form_btn"
                        style={ { border: 'none' } }
                        onClick={ () => {
                            setShowEmployees( !showEmployees )
                        } }
                >
                    { showEmployees ? <IconMinus/> : <IconPlus/> }
                </button>
            </div>
            <div ref={ wrapper } className='table_wrap employee_details'>
                <div ref={ newEmployeeBlock }>
                    <AddNewEmployee getEmployeesListFunc={ getEmployeesListFunc }/>
                </div>
                <table ref={ tableBlock } className='employee_details__table table'>
                    <thead>
                    <tr>
                        <th className='filtered'>Name<IconFilter/></th>
                        <th className='filtered'>Employee Title<IconFilter/></th>
                        <th className='filtered'>Phone Number<IconFilter/></th>
                        <th className='filtered'></th>
                    </tr>
                    </thead>
                    <tbody>
                    { employees.map( ( el, index ) => {
                        return <EmployeeTableRow el={ el } key={ index }/>
                    } ) }
                    </tbody>
                </table>
                { employees.length ? '' : <h3 style={ {
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    paddingTop: '20px',
                    fontSize: '20px',
                    textAlign: 'center'
                } }>Nothing found</h3> }
            </div>
        </div>
    )
}

export default EmployeeDetails;