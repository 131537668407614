import React, { useEffect, useState } from 'react'
import './ControlPanel.scss'
import Sidebar from "./SIdebar/Sidebar"
import Header from "./Header/Header"
import Dashboard from "./Dashboard/Dashboard"
import { Route, Routes } from "react-router-dom"
import DashboardRebates from "./Dashboard/DashboardRebates/DashboardRebates"
import Patients from "./Patients/Patients"
import DashboardPromotions from "./Dashboard/DashboardPromotions/DashboardPromotions"
import AdminInformation from "./AdminInformation/AdminInformation"
import AddNewPatient from "./AddNewPatient/AddNewPatient"
import ClinicProfile from "./ClinicProfile/ClinicProfile"
import Resources from "./Resources/Resources"
import PatientEdit from "./Patients/PatientEdit/PatientEdit"
import { getCompanyInfo, getPurchasingHistoryCountByStore } from "../../requests/requests"
import Preloader from "../Preloader/Preloader"
import DashboardPurchasedHistory from "./Dashboard/DashboardOrderHistory/DashboardPurchasedHistory"
import Reports from "./Reports/Reports"
import AddNewClinic from "./Dashboard/AddNewClinic/AddNewClinic"
import SelectedClinicRep from "./SelectedClinicRep/SelectedClinicRep"
import RepDashboard from "./DashboardRep/RepDashboard";
import DashboardSyringesShipped from "./Dashboard/DashboardSyringesShipped/DashboardSyringesShipped";
import AddNewLocation from "./AddNewLocation/AddNewLocation";
import { useAdminStore, useClinics, useCompany } from "../../stores/adminStore"

const ControlPanel = () => {

    /******************** GLOBAL STORE **************************/
    const isRepAdmin = useAdminStore( state => state.isRepAdmin )
    const isRoleChecked = useAdminStore( state => state.isRoleChecked )
    const currentClinicId = useClinics( state => state.currentClinicId )
    const setClinics = useClinics( state => state.setClinics )
    const setCurrentClinicId = useClinics( state => state.setCurrentClinicId )
    const companyId = useCompany( state => state.companyId )
    const setCompanyId = useCompany( state => state.setCompanyId )
    /***********************************************************/

    const [ preloader, setPreloader ] = useState( true )
    const [ company, setCompany ] = useState( {} )
    const [ stores, setStores ] = useState( [] )
    const [ purchasingHistory, setPurchasingHistory ] = useState( null )

    const getCompanyFunc = () => {
        setPreloader( true )
        getCompanyInfo().then( data => {
            if ( 'errors' in data ) {
                setPreloader( false )
                window.location.href = '/login'
            } else {
                setCompanyId( data.company.id )
                let clinicsArr = []
                data.stores.map( ( store, index ) => {
                    clinicsArr[ index ] = {}
                    clinicsArr[ index ][ 'id' ] = store.id
                    clinicsArr[ index ][ 'name' ] = store.name
                    clinicsArr[ index ][ 'city' ] = store.city?.name
                } )
                setClinics( clinicsArr )
                setCurrentClinicId( clinicsArr[ 0 ].id )
                setCompany( data )
                setStores( data.stores )
                setPreloader( false )
            }
        } )
    }

    useEffect( () => {
        if ( !isRepAdmin && isRoleChecked ) {
            getCompanyFunc()
        } else {
            setPreloader( false )
        }
    }, [] )

    useEffect( () => {
        if ( companyId && currentClinicId && !isRepAdmin ) {
            getPurchasingHistoryCountByStore( companyId, currentClinicId )
                .then( data => setPurchasingHistory( data ) )
        }
    }, [ currentClinicId ] )

    return (
        <div className='controlPanel open_sidebar'>
            <Sidebar/>
            <Header companies={ company }/>
            <div className="content">
                <Routes>
                    <Route path='/patients/patient-edit-account/:id'
                           element={ <PatientEdit/> }/>
                    <Route path='/add-new-patient'
                           element={ <AddNewPatient/> }/>
                    <Route path='/admin-information'
                           element={ <AdminInformation/> }/>
                    <Route path='/resources'
                           element={ <Resources/> }/>
                    {
                        isRepAdmin
                            ?
                            <>
                                <Route path='/'
                                       element={ <RepDashboard/> }/>
                                <Route path='/reports'
                                       element={ <Reports/> }/>
                                <Route path='/add-new-clinic'
                                       element={ <AddNewClinic/> }/>
                                <Route path='/selected-clinic/:id'
                                       element={ <SelectedClinicRep/> }/>
                                <Route path='/add-new-location'
                                       element={ <AddNewLocation/> }/>
                                <Route path='/rebates-applied'
                                       element={ <DashboardRebates/> }/>
                                <Route path='/reimbursement-shipped'
                                       element={ <DashboardSyringesShipped/> }/>
                                <Route path='/promotion-purchased'
                                       element={ <DashboardPromotions
                                           toShow={ 'Purchased' }/> }/>
                                <Route path='/promotion-redeemed'
                                       element={ <DashboardPromotions
                                           toShow={ 'Redeemed' }/> }/>
                                <Route path='/purchasing-history'
                                       element={ <DashboardPurchasedHistory/> }/>
                            </>
                            :
                            <>
                                <Route path='/'
                                       element={ <Dashboard
                                           company={ company }
                                           purchasingHistory={ purchasingHistory }
                                           updateCompanyFunc={ getCompanyFunc }/> }/>
                                <Route path='/dashboard/rebates-applied'
                                       element={ <DashboardRebates/> }/>
                                <Route path='/dashboard/reimbursement-shipped'
                                       element={ <DashboardSyringesShipped/> }/>
                                <Route path='/dashboard/promotion-purchased'
                                       element={ <DashboardPromotions
                                           toShow={ 'Purchased' }/> }/>
                                <Route path='/dashboard/promotion-redeemed'
                                       element={ <DashboardPromotions
                                           toShow={ 'Redeemed' }/> }/>
                                <Route path='/patients'
                                       element={ <Patients/> }/>
                                <Route path='/clinic-profile'
                                       element={ <ClinicProfile
                                           stores={ stores }
                                           getCompanyFunc={ getCompanyFunc }/> }/>
                                <Route path='/dashboard/order-history'
                                       element={ <DashboardPurchasedHistory/> }/>
                            </>
                    }

                </Routes>
            </div>
            { !preloader || <Preloader/> }
        </div>
    )
}

export default ControlPanel