import './App.scss';
import { Route, Routes, useNavigate } from 'react-router-dom'
import Login from "./components/Login/Login";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import ContactUs from "./components/ContactUs/ContactUs";
import React, { useEffect, useState } from "react";
import Preloader from "./components/Preloader/Preloader";
import { tokenVerification } from "./requests/requests";
import { useAdminRegionStore, useAdminStore } from "./stores/adminStore";

function App() {

    /****************From global store*************************/
    const saveRole = useAdminStore( state => state.saveRole )
    const saveAdminId = useAdminStore( state => state.saveAdminId )
    const setAdminRegionId = useAdminRegionStore( state => state.setAdminRegionId )
    //******************************************************************

    const location = window.location.href
    const navigate = useNavigate()

    const [ roleCheck, setRoleCheck ] = useState( false )
    const [ error, setError ] = useState( false )

    useEffect( () => {
        tokenVerification().then( data => {
            if ( 'errors' in data ) {
                setError( true )
                navigate( '/login' )
            } else {
                saveRole( data.role ) // Admin role write to store
                saveAdminId( data.id ) // Admin id write to store
                setAdminRegionId( data?.region_id )
                setRoleCheck( true )
                setError( false )
            }
        } )
    }, [ location ] )

    if ( !roleCheck && !error ) return <Preloader/>
    if ( !roleCheck && error ) {
        return (
            <div className="App">
                <Routes>
                    <Route path='/login' element={ <Login/> }/>
                </Routes>
            </div>
        )
    }
    if ( roleCheck ) {
        return (
            <div className="App">
                <Routes>
                    <Route path='/login' element={ <Login/> }/>
                    <Route path='/*' element={ <ControlPanel/> }/>
                    <Route path='/contact-us' element={ <ContactUs/> }/>
                </Routes>
            </div>
        )
    }
}

export default App;
