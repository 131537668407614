import React, { useEffect, useState } from 'react';
import './AddNewAdmin.scss'
import { useClinics, useCompany } from "../../../../stores/adminStore";
import { addLocationAdmin } from "../../../../requests/rep_selected_clinic_requests";

const AddNewAdmin = ( { updateLocationAdminsList } ) => {

    /******************** GLOBAL STORE ***********************/
    const currentClinicId = useClinics( state => state.currentClinicId )
    const companyId = useCompany( state => state.companyId )
    /********************************************************/

    const [ emailError, setEmailError ] = useState( '' )
    const [ showNewAdminInputs, setShowNewAdminInputs ] = useState( false )
    const [ newAdmin, setNewAdmin ] = useState( {
        first_name: '',
        last_name: '',
        email: '',
    } )

    useEffect( () => {
        if ( !companyId || !currentClinicId ) return
        setNewAdmin( { ...newAdmin, company_id: companyId, store_ids: currentClinicId } )
    }, [ companyId, currentClinicId ] )

    const inputsHandler = ( e ) => {
        let name = e.target.name
        let value = e.target.value
        setNewAdmin( { ...newAdmin, [ name ]: value } )
    }

    const emailHandler = ( e ) => {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        if ( !re.test( String( e.target.value ).toLowerCase() ) ) {
            setEmailError( 'Invalid email' )
        } else {
            setNewAdmin( { ...newAdmin, email: e.target.value } )
            setEmailError( '' )
        }
    }

    const verifNewAdminFields = () => {
        if ( !showNewAdminInputs ) return 'Add New Admin'
        if ( showNewAdminInputs && newAdmin.first_name && newAdmin.last_name && newAdmin.email && !emailError ) return 'Save'
        if ( showNewAdminInputs && (!newAdmin.first_name || !newAdmin.last_name || !newAdmin.email || emailError) ) return 'Cancel'
    }

    const addNewLocationAdminFunc = () => {
        addLocationAdmin( newAdmin )
            .then( data => {
                if ( 'errors' in data ) {
                    console.log( 'errors in newAdmin' )
                } else {
                    updateLocationAdminsList()
                }
            } )
    }

    return (
        <div className='add_new_admin'>
            <div className='inputs'>
                <div className={ 'inputs_wrapper' + (showNewAdminInputs ? '' : ' close') }>
                    <div className="form_field email">
                        <label>First Name</label>
                        <input type="text"
                               name='first_name'
                               value={ newAdmin.first_name }
                               onChange={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field email">
                        <label>Last Name</label>
                        <input type="text"
                               name='last_name'
                               value={ newAdmin.last_name }
                               onChange={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field email">
                        <label>Email</label>
                        <input type="email"
                               name='email'
                               value={ newAdmin.email }
                               onChange={ ( e ) => {
                                   emailHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ emailError }</p>
                    </div>
                </div>
            </div>
            <button className='btn wide_btn beige_btn'
                    onClick={ () => {
                        setNewAdmin( { ...newAdmin, first_name: '', last_name: '', email: '' } )
                        setShowNewAdminInputs( !showNewAdminInputs )
                        if ( showNewAdminInputs && newAdmin.first_name && newAdmin.last_name && newAdmin.email && !emailError ) {
                            addNewLocationAdminFunc()
                        }
                    } }>{ verifNewAdminFields() }</button>
        </div>
    );
};

export default AddNewAdmin;