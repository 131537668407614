import React, {useEffect, useState} from 'react';
import './Pagination.scss'

const Pagination = (props) => {

    const [pages, setPages] = useState([])

    useEffect(() => {
        if (props.count !== 0) {
            let res = [];
            const iter_delay = Math.ceil(props.count / props.amount);
            let delimiter_insert = false;
            for (let i = 1; i <= iter_delay; i++) {
                if ((props.now_page < i + props.interval && props.now_page > i - props.interval) || (i === 1 || i === 2) || (i === iter_delay || i === iter_delay - 1)) {
                    res.push(<li className={(+props.now_page === i) ? "pag_item active" : "pag_item"}
                                 key={i}
                                 onClick={() => {
                                     window.history.pushState("", "", "/patients?page=" + i)
                                     props.changePage({...props.state, page: i})
                                     props.preloader(true)
                                 }}>{i}</li>)
                    delimiter_insert = false
                } else {
                    if (!delimiter_insert) {
                        res.push(<li className={"pag-item"} key={i}>...</li>)
                        delimiter_insert = true;
                    }
                }
            }
            setPages(res)
        }
    }, [props.count, props.now_page])

    return (
        <ul className="pagination">
            {pages}
        </ul>
    );
};

export default Pagination;