import React, { useEffect, useState } from 'react';
import './AdminInformation.scss'
import PopUp from "../../PopUp/PopUp";
import { tokenVerification, updateAdminInfo, updateAdminPassword } from "../../../requests/requests";
import Preloader from "../../Preloader/Preloader"
import { phoneMaskNumberInit, phoneNumFromDb, validatePhoneNumber } from "../../../helperFunctions";
import { useAdminInfoUpdated, useAdminStore } from "../../../stores/adminStore";
import { getRepAdmin, updateRepAdmin, updateRepAdminPassword } from "../../../requests/rep_requests";
import default_ava from '../../../images/default_admin.png'

const AdminInformation = () => {

    // Admin role from store
    const isRepAdmin = useAdminStore( state => state.isRepAdmin )
    const adminId = useAdminStore( state => state.adminId )
    const setRepAdminLogo = useAdminStore( state => state.setRepAdminLogo )
    const updateAdmin = useAdminInfoUpdated( state => state.updateAdmin )
    // const isRepAdminUpdated = useAdminInfoUpdated( state => state.isUpdated )
    /**********************************************************/
    // const default_ava = '/static/media/default_admin.20799d4f.jpg'
    const [ preloader, setPreloader ] = useState( true )
    const [ newPass, setNewPass ] = useState( '' )
    const [ newPassError, setNewPassError ] = useState( '' )
    const [ newPassRepeat, setNewPassRepeat ] = useState( '' )
    const [ newPassRepeatError, setNewPassRepeatError ] = useState( '' )
    const [ phoneError, setPhoneError ] = useState( '' )
    const [ updated, setUpdated ] = useState( false )
    const [ changes, setChanges ] = useState( false )
    const [ adminInfo, setAdminInfo ] = useState( {
        id: null,
        first_name: '',
        last_name: '',
        role: null,
        phone: '',
        email: '',
        region_id: null,
        logo: {
            url: null
        }
    } )
    const [ repAdminAva, setRepAdminAva ] = useState( '' ) // temporary storage of the uploaded image from the input

    const newPassHandler = ( e ) => {
        let val = e.target.value
        val = val.replace( /\s+/gi, '' )
        setNewPass( val )
        setChanges( true )
        if ( val.length < 8 ) {
            setNewPassError( 'At least six characters' )
        } else {
            setNewPassError( '' )
        }
        if ( val === newPassRepeat ) {
            setNewPassRepeatError( "" )
        }
    }

    const newPassRepeatHandler = ( e ) => {
        let val = e.target.value
        val = val.replace( /\s+/gi, '' )
        setNewPassRepeat( val )
        setChanges( true )
        if ( e.target.value !== newPass ) {
            setNewPassRepeatError( "Mismatch passwords" )
        } else {
            setNewPassRepeatError( "" )
        }
    }

    const adminInfoHandler = ( e ) => {
        setAdminInfo( { ...adminInfo, [ e.target.name ]: e.target.value } )
        setChanges( true )
    }

    useEffect( () => {
        phoneMaskNumberInit() // PhoneMask Initialization
        // getAdmin()
    }, [] )

    useEffect( () => {
        getAdmin()
    }, [ adminId ] )

    const phoneHandler = ( e ) => {
        setChanges( true )
        let { valid, cleanPhoneNumber } = validatePhoneNumber( e )
        if ( valid ) {
            setPhoneError( '' )
            setAdminInfo( { ...adminInfo, phone: cleanPhoneNumber } )
        } else {
            setPhoneError( 'Invalid phone number' )
        }
    }

    const getAdmin = () => {
        if ( adminId ) {
            getRepAdmin( adminId ).then( data => {
                if ( 'errors' in data ) {
                    window.location.href = '/login'
                } else {
                    data.phone = phoneNumFromDb( data.phone ) //////////////////////////////////////////////////////
                    // console.log('data', data)
                    setAdminInfo( { ...adminInfo, ...data } )
                    if ( data.logo.url ) {
                        setRepAdminLogo( data.logo.url )
                    }
                    setPreloader( false )
                }
            } )
        } else {
            tokenVerification().then( data => {
                if ( 'errors' in data ) {
                    window.location.href = '/login'
                } else {
                    setAdminInfo( { ...adminInfo, ...data } )
                    setPreloader( false )
                }
            } )
        }
    }

    const saveUpdates = ( adminInfo ) => {
        setPreloader( true )
        if ( (newPass && newPassRepeat) && (!newPassError && !newPassRepeatError) ) {
            updateAdminPassword( { new_password: newPass } )
                .then( () => {
                    setNewPass( '' )
                    setNewPassRepeat( '' )
                } )
        }
        updateAdminInfo( adminInfo ).then( data => {
            setAdminInfo( { ...adminInfo, ...data } )
            updateAdmin()
            setPreloader( false )
            setUpdated( true )
            setChanges( false )
        } )
    }

    const updateRepUpdates = ( adminInfo ) => {
        setPreloader( true )
        if ( (newPass && newPassRepeat) && (!newPassError && !newPassRepeatError) ) {
            updateRepAdminPassword( { new_password: newPass } )
                .then( () => {
                    setNewPass( '' )
                    setNewPassRepeat( '' )
                } )
        }
        updateRepAdmin( adminInfo ).then( data => {
            setAdminInfo( { ...adminInfo, ...data } )
            updateAdmin()
            setPreloader( false )
            setUpdated( true )
            setChanges( false )
        } )
    }

    return (
        <div className='adminInfo'>
            <div className='component__wrap'>
                <div className="adminInfo__name dashboard__component_title">
                    <h2>{ isRepAdmin ? 'YOUR PROFILE' : (adminInfo.first_name + ' ' + adminInfo.last_name) }</h2>
                </div>
                <div className='component__subtitle'>
                    <h3>{ isRepAdmin ? 'Rep Details' : 'Admin Information' }</h3>
                </div>
                <form className="adminInfo__form form">
                    <div className="form_field first_name">
                        <label htmlFor="first_name">First Name</label>
                        <input type="text"
                               id='first_name'
                               name='first_name'
                               defaultValue={ adminInfo.first_name }
                               onChange={ ( e ) => {
                                   adminInfoHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field last_name">
                        <label htmlFor="last_name">Last Name</label>
                        <input type="text"
                               id='last_name'
                               name='last_name'
                               defaultValue={ adminInfo.last_name }
                               onChange={ ( e ) => {
                                   adminInfoHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field email">
                        <label htmlFor="email">Email</label>
                        <input type="email"
                               id='email'
                               name='email'
                               defaultValue={ adminInfo.email }
                               onChange={ ( e ) => {
                                   adminInfoHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field password">
                        <label htmlFor="password">Password</label>
                        <input type="password"
                               id='password1'
                               name='password'
                               autoComplete="new-password"
                               value={ newPass }
                               onChange={ ( e ) => {
                                   newPassHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ newPassError }</p>
                    </div>
                    <div className="form_field password">
                        <label htmlFor="password_repeat">Repeat Password</label>
                        <input type="password"
                               id='password_repeat'
                               name='passRepeat'
                               autoComplete="new-password"
                               value={ newPassRepeat }
                               onChange={ ( e ) => {
                                   newPassRepeatHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ newPassRepeatError }</p>
                    </div>
                    <div className="form_field phone_number">
                        <label htmlFor="phone">Phone Number</label>
                        <input type="text"
                               id='phone'
                               name='phone'
                               defaultValue={ adminInfo.phone }
                               onChange={ ( e ) => {
                                   phoneHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ phoneError }</p>
                    </div>
                    { isRepAdmin
                        ?
                        <div className="form_field ava">
                            <div className="admin_ava">
                                {/*<img src={ adminInfo?.logo || adminInfo?.logo?.url || default_ava } alt=""/>*/ }
                                <img src={ repAdminAva || adminInfo?.logo?.url || default_ava } alt=""/>
                            </div>
                            <div className="browse_btn">
                                <label className='label'
                                       htmlFor="avatar">Browse</label>
                                <input type="file"
                                       id='avatar'
                                       accept='image/png, image/jpeg, image/jpg, image/gif'
                                       onChange={ ( e ) => {
                                           let file = e.target.files[ 0 ]
                                           setAdminInfo( { ...adminInfo, logo: file } )
                                           setChanges( true )
                                           let reader = new FileReader()
                                           reader.onload = () => {
                                               setRepAdminAva( reader.result )
                                           }
                                           reader.readAsDataURL( file )
                                       } }
                                       hidden={ true }
                                />
                            </div>

                        </div>
                        :
                        null }
                </form>
                <button className="save_btn btn wide_btn beige_btn"
                        disabled={ newPassError || newPassRepeatError || (newPass !== newPassRepeat) || phoneError || !changes }
                        onClick={ () => {
                            setAdminInfo( { ...adminInfo, password: newPassRepeat } )
                            if ( isRepAdmin ) {
                                updateRepUpdates( adminInfo )
                            } else {
                                saveUpdates( adminInfo )
                            }

                        } }>Save changes
                </button>
                {
                    updated
                        ?
                        <PopUp popUpMessage={ 'Updated' }
                               closeBtnTitle={ 'Close' }
                               showPopUp={ setUpdated }
                               closePopUp={ setUpdated }/>
                        :
                        null
                }
                { !preloader || <Preloader/> }
            </div>
        </div>
    );
};

export default AdminInformation;