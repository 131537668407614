import React, { useEffect, useState } from 'react';
import ClinicDetails from "./ClinicDetails/ClinicDetails";
import PatientList from "./PatientList/PatientList";
import AdminDetails from "./AdminDetails/AdminDetails";
import EmployeeDetails from "./EmployeeDetails/EmployeeDetails";
import Statistics from "./Statistics/Statistics";
import { getRepSelectedClinic } from "../../../requests/rep_selected_clinic_requests";
import { useAdminStore, useClinics, useCompany } from "../../../stores/adminStore";
import { useNavigate } from "react-router-dom";
import PopUp from "../../PopUp/PopUp";
import TrainingMode from "./TrainingMode/TrainingMode";
import Preloader from "../../Preloader/Preloader";


const SelectedClinicRep = () => {

    const idArr = window.location.href.split( '/' )
    const currentCompanyId = Number( idArr[ idArr.length - 1 ] )
    const navigate = useNavigate()

    /******************** GLOBAL STORE ***********************/
    const isRepAdmin = useAdminStore( state => state.isRepAdmin )
    const currentClinicId = useClinics( state => state.currentClinicId )
    const setClinics = useClinics( state => state.setClinics )
    const setCurrentClinicId = useClinics( state => state.setCurrentClinicId )
    const setCompanyId = useCompany( state => state.setCompanyId )
    /********************************************************/

    const [ currentLocation, setCurrentLocation ] = useState( {} )
    const [ company, setCompany ] = useState( {} )
    const [ hcpAdmin, setHcpAdmin ] = useState( {} )
    const [ somethingWentWrong, setSomethingWentWrong ] = useState( false )
    const [ haveProblem, setHaveProblem ] = useState( false )
    const [ preloader, setPreloader ] = useState( false )

    useEffect( () => {
        let currentLocation_ls = localStorage.getItem( 'currentLocation_ls' )
        let locationsArrayLS = JSON.parse( localStorage.getItem( 'current_company_locations' ) )
        setClinics( locationsArrayLS )
        setCurrentClinicId( currentLocation_ls )
        setPreloader(true)
        setCompanyId(currentCompanyId)
        getRepSelectedClinic( currentCompanyId, (currentLocation_ls || locationsArrayLS[0].id) )
            .then( company => {
                if ( 'errors' in company ) {
                    setPreloader( false )
                    setSomethingWentWrong( true )
                    setHaveProblem( true )
                } else {
                    setPreloader( false )
                    setCompany( company )
                    setHcpAdmin( company.hcp_admin )
                    let desiredLocation = company.stores.filter( el => el.id === (Number(currentLocation_ls) || Number(locationsArrayLS[ 0 ].id)) )[0]
                    setCurrentLocation( desiredLocation )
                }
            } )

    }, [currentClinicId] )

    useEffect( () => {
        if ( haveProblem && !somethingWentWrong ) navigate( -1 )
    }, [ somethingWentWrong ] )

    return (
        <div className='selected_clinic_rep'>
            <div className="component__wrap">
                <div className="dashboard__component_title">
                    <h2>Clinic { company?.company?.name }</h2>
                </div>
                { isRepAdmin ? <TrainingMode/> : null }
            </div>
            <Statistics company={ company }/>
            <ClinicDetails currentLocation={ currentLocation } hcp_admin={ hcpAdmin }/>
            <PatientList/>
            <AdminDetails/>
            <EmployeeDetails/>
            { !somethingWentWrong || <PopUp popUpMessage={ 'Something went wrong' }
                                            closeBtnTitle={ 'Return' }
                                            showPopUp={ setSomethingWentWrong }
                                            closePopUp={ setSomethingWentWrong }/> }
            { !preloader || <Preloader/> }
        </div>
    )
}

export default SelectedClinicRep;