import React, {useEffect, useState} from 'react'
import './ContactUs.scss'
import logo from "../../images/Vertical_logo.png";
import {Link} from "react-router-dom";
import {send} from 'emailjs-com';
import PopUp from "../PopUp/PopUp";
import Preloader from "../Preloader/Preloader";

const ContactUs = () => {

    const onSubmit = () => {
        setPreloader(true)
        let mail = Object.assign(contactUs, options)
        send('service_puy6oil', 'template_1zfmb2q', mail, 'user_fiBT0AeWOK3FdcvQ9h3im')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text)
                setContactUs({
                    name: '',
                    email: '',
                    phone_number: '',
                    company: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    contactUs_message: '',
                })
                setOptions({
                    colleague: false,
                    request: false,
                    social: false,
                    meeting: false,
                    representative: false,
                    other: false,
                })
                setPreloader(false)
                setPopUpMess('Information has been sent.')
                setHasBeenSent(true)
                setOptionsError(false)
                setFieldError(false)
            })
            .catch((err) => {
                setPreloader(false)
                console.log('FAILED...', err)
                setPopUpMess('Something went wrong!')
                setHasBeenSent(true)
            })
    }

    const [preloader, setPreloader] = useState(false)
    const [popUpMess, setPopUpMess] = useState('Something went wrong!')
    const [contactUs, setContactUs] = useState({
        name: '',
        email: '',
        phone_number: '',
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        contactUs_message: '',
    })
    const [options, setOptions] = useState({
        colleague: false,
        request: false,
        social: false,
        meeting: false,
        representative: false,
        other: false,
    })
    const [optionsError, setOptionsError] = useState(false)
    const [firstRender, setFirstRender] = useState(true)
    const [fieldError, setFieldError] = useState(false)
    const [hasBeenSent, setHasBeenSent] = useState(false)

    const inputsHandler = (e) => {
        let name = e.target.name
        let value = e.target.value
        setContactUs({...contactUs, [name]: value})
    }

    const inputsOptionsHandler = (e) => {
        let name = e.target.name
        let value = e.target.checked
        setOptions({...options, [name]: value})
    }

    const submitHandler = (obj) => {
        (Object.values(obj).includes('') || optionsError)
            ?
            setFieldError(true)
            :
            onSubmit() // PASTE SEND FORM FUNCTION
    }

    const emptyFields = (obj) => {
        Object.values(obj).includes(true)
            ?
            setOptionsError(false)
            :
            setOptionsError(true)
    }

    useEffect(() => {
        setFirstRender(false)
        if (!firstRender) {
            emptyFields(options)
        }
    }, [options])

    return (
        <div className='contactUs'>
            <div className="burger_menu_contactUs">
                <span className="line_top line">
                </span>
                <span className="line_middle line">
                </span>
                <span className="line_bottom line">
                </span>
            </div>
            <div className="contactUs__logo">
                <Link to={'/'}>
                    <img src={logo} alt="logo"/>
                </Link>
            </div>
            <div className="contactUs__form_block">
                <h1 className="title">CONTACT US</h1>
                <p className="description">IF YOU A MEDICAL AESTHETICS PROVIDER WHO WOULD LIKE MORE INFORMATION
                    ABOUT
                    MAKING THE REVANESSE&reg; PORTFOLIO OF PRODUCTS AVAILABLE TO YOUR PATIENTS, OR IF YOU HAVE
                    QUESTIONS
                    ABOUT AN ORDER, PLEASE USE THE FORM BELOW TO SEND US A MESSAGE.</p>
                <form className="contactUs__form form">
                    <div className={"form_field name " + ((fieldError && !contactUs.name) ? 'error' : '')}>
                        <label htmlFor="name">Name**</label>
                        <input type="text"
                               id='name'
                               name='name'
                               value={contactUs.name}
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                        {(fieldError && !contactUs.name) ?
                            <span className='field_error'>This is a required field.</span> : null}
                    </div>
                    <div className={"form_field email " + ((fieldError && !contactUs.email) ? 'error' : '')}>
                        <label htmlFor="email">Email*</label>
                        <input type="text"
                               id='email'
                               name='email'
                               value={contactUs.email}
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                        {(fieldError && !contactUs.email) ?
                            <span className='field_error'>This is a required field.</span> : null}
                    </div>
                    <div
                        className={"form_field phone_number " + ((fieldError && !contactUs.phone_number) ? 'error' : '')}>
                        <label htmlFor="phone_number">Phone*</label>
                        <input type="text"
                               id='phone_number'
                               name='phone_number'
                               value={contactUs.phone_number}
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                        {(fieldError && !contactUs.phone_number) ?
                            <span className='field_error'>This is a required field.</span> : null}
                    </div>
                    <div className={"form_field company " + ((fieldError && !contactUs.company) ? 'error' : '')}>
                        <label htmlFor="company">Company*</label>
                        <input type="text"
                               id='company'
                               name='company'
                               value={contactUs.company}
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                        {(fieldError && !contactUs.company) ?
                            <span className='field_error'>This is a required field.</span> : null}
                    </div>
                    <div className={"form_field address " + ((fieldError && !contactUs.address) ? 'error' : '')}>
                        <label htmlFor="address">Address*</label>
                        <input type="text"
                               id='address'
                               name='address'
                               value={contactUs.address}
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                        {(fieldError && !contactUs.address) ?
                            <span className='field_error'>This is a required field.</span> : null}
                    </div>
                    <div className={"form_field city " + ((fieldError && !contactUs.city) ? 'error' : '')}>
                        <label htmlFor="city">City*</label>
                        <input type="text"
                               id='city'
                               name='city'
                               value={contactUs.city}
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                        {(fieldError && !contactUs.city) ?
                            <span className='field_error'>This is a required field.</span> : null}
                    </div>
                    <div className={"form_field state " + ((fieldError && !contactUs.state) ? 'error' : '')}>
                        <label htmlFor="state">State*</label>
                        <input type="text"
                               id='state'
                               name='state'
                               value={contactUs.state}
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                        {(fieldError && !contactUs.state) ?
                            <span className='field_error'>This is a required field.</span> : null}
                    </div>
                    <div className={"form_field zip " + ((fieldError && !contactUs.zip) ? 'error' : '')}>
                        <label htmlFor="zip">Zip code*</label>
                        <input type="text"
                               id='zip'
                               name='zip'
                               value={contactUs.zip}
                               onChange={(e) => {
                                   inputsHandler(e)
                               }}
                               className='form__input'/>
                        {(fieldError && !contactUs.zip) ?
                            <span className='field_error'>This is a required field.</span> : null}
                    </div>
                    <div className="form_field options" id='form_field_options'>
                        <label htmlFor='form_field_options'>How did you hear about us? *</label>
                        <div className='option'>
                            <input type="checkbox"
                                   id='colleague'
                                   className='checkbox'
                                   name='colleague'
                                   checked={options.colleague}
                                   onChange={(e) => {
                                       inputsOptionsHandler(e)
                                   }}
                            />
                            <label htmlFor="colleague">Colleague/Professional Referral</label>
                        </div>
                        <div className='option'>
                            <input type="checkbox"
                                   id='request'
                                   className='checkbox'
                                   name='request'
                                   checked={options.request}
                                   onChange={(e) => {
                                       inputsOptionsHandler(e)
                                   }}
                            />
                            <label htmlFor="request">Patient/Client Request</label>
                        </div>
                        <div className='option'>
                            <input type="checkbox"
                                   id='social'
                                   className='checkbox'
                                   name='social'
                                   checked={options.social}
                                   onChange={(e) => {
                                       inputsOptionsHandler(e)
                                   }}
                            />
                            <label htmlFor="social">Social Media</label>
                        </div>
                        <div className='option'>
                            <input type="checkbox"
                                   id='meeting'
                                   className='checkbox'
                                   name='meeting'
                                   checked={options.meeting}
                                   onChange={(e) => {
                                       inputsOptionsHandler(e)
                                   }}
                            />
                            <label htmlFor="meeting">Tradeshow/Society Meeting</label>
                        </div>
                        <div className='option'>
                            <input type="checkbox"
                                   id='representative'
                                   className='checkbox'
                                   name='representative'
                                   checked={options.representative}
                                   onChange={(e) => {
                                       inputsOptionsHandler(e)
                                   }}
                            />
                            <label htmlFor="representative">Sales Representative</label>
                        </div>
                        <div className='option'>
                            <input type="checkbox"
                                   id='other'
                                   className='checkbox'
                                   name='other'
                                   checked={options.other}
                                   onChange={(e) => {
                                       inputsOptionsHandler(e)
                                   }}
                            />
                            <label htmlFor="other">Other</label>
                        </div>
                        {optionsError ? <span className='field_error'>Make a choice</span> : null}
                    </div>
                    <div
                        className={"form_field message " + ((fieldError && !contactUs.contactUs_message) ? 'error' : '')}>
                        <label htmlFor="contactUs_message">Message *</label>
                        <textarea id='contactUs_message'
                                  name='contactUs_message'
                                  value={contactUs.contactUs_message}
                                  onChange={(e) => {
                                      inputsHandler(e)
                                  }}
                                  className='form__input'/>
                        {(fieldError && !contactUs.contactUs_message) ?
                            <span className='field_error'>This is a required field.</span> : null}
                    </div>
                    <div className="btn_wrap">
                        <button className="save_btn btn black_btn"
                                onClick={(e) => {
                                    e.preventDefault()
                                    emptyFields(options)
                                    submitHandler(contactUs)
                                }}>Send
                        </button>
                    </div>
                </form>
            </div>
            <div className="contactUs__contacts">
                <div className='corporate_office contacts'>
                    <h2>US CORPORATE OFFICE</h2>
                    <h3>PROLLENIUM US</h3>
                    <p>A Davision of Prollenium Medical Technologies Inc.</p>
                    <p>9121 Anson Way, Suite 200</p>
                    <p>Releigh, NC 27615</p>
                    <p>info@prollenium.com</p>
                    <p>877.202.0987</p>
                </div>
                <div className='global_contact contacts'>
                    <h2>GLOBAL HEADQUARTERS</h2>
                    <h3>PROLLENIUM MEDICAL TECHNOLOGIES INC.</h3>
                    <p>138 Industrial Parkway North</p>
                    <p>Aurora, Ontario, L4G 4C3</p>
                    <p>info@prollenium.com</p>
                    <p>877.202.0987</p>
                </div>
            </div>
            {
                hasBeenSent
                    ?
                    <PopUp popUpMessage={popUpMess}
                           closeBtnTitle={'Close'}
                           showPopUp={setHasBeenSent}
                           closePopUp={setHasBeenSent}/>
                    :
                    null
            }
            {!preloader || <Preloader/>}
        </div>
    );
};

export default ContactUs