import React, { useEffect, useState } from 'react'
import './DashboardPromoBox.scss'
import { getPromoBox } from "../../../../requests/requests"
import DashboardPromoBoxTimer from "./DashboardPromoBoxTimer";

const DashboardPromoBox = () => {

    const [ state, setState ] = useState( {
        image_url: '',
        active: false,
        headline: '',
        body: '',
        button: '',
        expiration: '',
        button_url: '',
        is_button_active: true
    } )

    const [ timeIsLeft, setTimeIsLeft ] = useState( false )

    useEffect( () => {
        getPromoBox().then( data => {
            setState( data )
            const timeLeft = +new Date( data?.expiration ) - +new Date()
            if ( timeLeft > 0 ) {
                setTimeIsLeft( true )
            }
        } )
    }, [] )

    // We put links and make them clickable
    const parseMessage = ( message ) => {
        let res = []
        message.replace( /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim, ( m, link, text ) => {
            res.push( link ? <a href={ (link[ 0 ] === "w" ? "//" : "") + link } key={ res.length }>{ link }</a> : text )
        } )
        return res
    }

    if ( state?.active && state?.expiration && timeIsLeft ) {
        return (
            <div className='promo_box'>
                <div className="promo_box__image">
                    <img src={ state?.image_url } alt=""/>
                    <DashboardPromoBoxTimer date={ state?.expiration }/>
                </div>
                <div className="promo_box__info">
                    <div className='text'>
                        <h2 className="headline">{ state?.headline }</h2>
                        <p className='body'>{ parseMessage( state?.body ) }</p></div>
                </div>
                <a href={ state?.button_url }
                   target="_blank"
                   rel="noopener noreferrer"
                   className={ state?.is_button_active
                       ? 'btn wide_btn beige_btn claim_offer_btn'
                       : 'btn wide_btn beige_btn claim_offer_btn disabled' }>
                    { state?.button }
                </a>
            </div>
        )
    } else {
        return ''
    }
}

export default DashboardPromoBox;