export const socialMediaResources = [
    {
        content: '1x1+(Me+Time)+In-Feed+Post.jpg',
        thumb: '1x1_(Me_Time)_In-Feed_Post-thumb.jpg'
    },
    {
        content: '9x16+(Me+Time)+Story.jpg',
        thumb: '9x16_(Me_Time)_Story-thumb.jpg'
    },
    {
        content: '1x1+(More+Tempting)+In-Feed+Post.jpg',
        thumb: '1x1_(More_Tempting)_In-Feed_Post-thumb.jpg'
    },
    {
        content: '9x16+(More+Tempting)+Story.jpg',
        thumb: '9x16_(More_Tempting)_Story-thumb.jpg'
    },
    {
        content: '1x1+(Quality+Time)+In-Feed+Post.jpg',
        thumb: '1x1_(Quality_Time)_In-Feed_Post-thumb.jpg'
    },
    {
        content: '9x16+(Quality+Time)+Story.jpg',
        thumb: '9x16_(Quality_Time)_Story-thumb.jpg'
    },
    {
        content: '1x1+(Treat+Yourself)+Reel.mp4',
        thumb: '1x1_(Treat_Yourself)_Reel-thumb.jpg'
    },
    {
        content: '9x16+(Treat+Yourself)+Reel.mp4',
        thumb: '9x16_(Treat_Yourself)_Reel-thumb.jpg'
    },
    {
        content: 'May+Long+Weekend+Reel+1-1+w_logo.mp4',
        thumb: 'May+Long+Weekend+Reel+1-1+w_logo_thmb.jpg'
    },
    {
        content: 'May+Long+Weekend+Reel+V2+w_logo.mp4',
        thumb: 'May+Long+Weekend+Reel+V2+w_logo_thmb.jpg'
    },
    {
        content: 'Post-BestFriendDay.jpg',
        thumb: 'Post-BestFriendDay_thmb.jpg'
    },
    {
        content: 'Post-Client-Meme.jpg',
        thumb: 'Post-Client-Meme_thmb.jpg'
    },
    {
        content: 'Post-ComplimentMeme.jpg',
        thumb: 'Post-ComplimentMeme_thmb.jpg'
    },
    {
        content: 'Post-DermalFiller-Pool.jpg',
        thumb: 'Post-DermalFiller-Pool_thmb.jpg'
    },
    {
        content: 'Post-DremalFiller.jpg',
        thumb: 'Post-DremalFiller_thmb.jpg'
    },
    {
        content: 'Post-Gif-Sunglasses.gif',
        thumb: 'Post-Gif-Sunglasses_thmb.jpg'
    },
    {
        content: 'Post-KissingDay.jpg',
        thumb: 'Post-KissingDay_thmb.jpg'
    },
    {
        content: 'Post-MemeTedTalk.jpg',
        thumb: 'Post-MemeTedTalk_thmb.jpg'
    },
    {
        content: 'Post-MothersDay.jpg',
        thumb: 'Post-MothersDay_thmb.jpg'
    },
    {
        content: 'Post-NewSpring.jpg',
        thumb: 'Post-NewSpring_thmb.jpg'
    },
    {
        content: 'Post-NewSpring-boxes.jpg',
        thumb: 'Post-NewSpring-boxes_thmb.jpg'
    },
    {
        content: 'Post-Reminder.jpg',
        thumb: 'Post-Reminder_thmb.jpg'
    },
    {
        content: 'Post-Reminder-BeachChair.jpg',
        thumb: 'Post-Reminder-BeachChair_thmb.jpg'
    },
    {
        content: 'Post-Reminder-Pool.jpg',
        thumb: 'Post-Reminder-Pool_thmb.jpg'
    },
    {
        content: 'Post-TwoWeekFiller.jpg',
        thumb: 'Post-TwoWeekFiller_thmb.jpg'
    },
    {
        content: 'Post-TwoWeeksHair.jpg',
        thumb: 'Post-TwoWeeksHair_thmb.jpg'
    },
    {
        content: 'Post-TwoWeeks-Sunglasses.jpg',
        thumb: 'Post-TwoWeeks-Sunglasses_thmb.jpg'
    },
    {
        content: 'Post-Vid-Sunglasses.mp4',
        thumb: 'Post-Vid-Sunglasses_thmb.jpg'
    },
    {
        content: 'Story-BestFriendDay.jpg',
        thumb: 'Story-BestFriendDay_thmb.jpg'
    },
    {
        content: 'Story-Client-Meme.jpg',
        thumb: 'Story-Client-Meme_thmb.jpg'
    },
    {
        content: 'Story-DermalFiller.jpg',
        thumb: 'Story-DermalFiller_thmb.jpg'
    },
    {
        content: 'Story-DermalFiller-Pool.jpg',
        thumb: 'Story-DermalFiller-Pool_thmb.jpg'
    },
    {
        content: 'Story-KissingDay.jpg',
        thumb: 'Story-KissingDay_thmb.jpg'
    },
    {
        content: 'Story-Meme-Compliment.jpg',
        thumb: 'Story-Meme-Compliment_thmb.jpg'
    },
    {
        content: 'Story-Meme-TedTalk.jpg',
        thumb: 'Story-Meme-TedTalk_thmb.jpg'
    },
    {
        content: 'Story-NewSpring-Boxes.jpg',
        thumb: 'Story-NewSpring-Boxes_thmb.jpg'
    },
    {
        content: 'Story-NewSpring-Boxes-2.jpg',
        thumb: 'Story-NewSpring-Boxes-2_thmb.jpg'
    },
    {
        content: 'Story-Pool-Reminder.jpg',
        thumb: 'Story-Pool-Reminder_thmb.jpg'
    },
    {
        content: 'Story-Reminder-BeachChair.jpg',
        thumb: 'Story-Reminder-BeachChair_thmb.jpg'
    },
    {
        content: 'Story-Reminder-Hands.jpg',
        thumb: 'Story-Reminder-Hands_thmb.jpg'
    },
    {
        content: 'Story-TwoWeeks-Chair.jpg',
        thumb: 'Story-TwoWeeks-Chair_thmb.jpg'
    },
    {
        content: 'Story-TwoWeeks-Hair.jpg',
        thumb: 'Story-TwoWeeksHair_thmb.jpg'
    },
    {
        content: 'Story-TwoWeeks-Sunglasses.jpg',
        thumb: 'Story-TwoWeeks-Sunglasses_thmb.jpg'
    },
    {
        content: '20_Years-Post.jpg',
        thumb: '20_Years-Post_thumb.png'
    },
    {
        content: '20_Years-Story.jpg',
        thumb: '20_Years-Story_thumb.png'
    },
    {
        content: 'Beauty-Goals-Post.jpg',
        thumb: 'Beauty-Goals-Post_thumb.png'
    },
    {
        content: 'Beauty-Goals-Story.jpg',
        thumb: 'Beauty-Goals-Story_thumb.png'
    },
    {
        content: 'Before_After-1-Post.jpg',
        thumb: 'Before_After-1-Post_thumb.png'
    },
    {
        content: 'Before_After-1-Story.jpg',
        thumb: 'Before_After-1-Story_thumb.png'
    },
    {
        content: 'Before_After-2-Post.jpg',
        thumb: 'Before_After-2-Post_thumb.png'
    },
    {
        content: 'Before_After-2-Story.jpg',
        thumb: 'Before_After-2-Story_thumb.png'
    },
    {
        content: 'Before_After-3-Post.jpg',
        thumb: 'Before_After-3-Post_thumb.png'
    },
    {
        content: 'Before_After-3-Story.jpg',
        thumb: 'Before_After-3-Story_thumb.png'
    },
    {
        content: 'Client_Review_1-Post.jpg',
        thumb: 'Client_Review_1-Post_thumb.png'
    },
    {
        content: 'Client_Review_1-Story.jpg',
        thumb: 'Client_Review_1-Story_thumb.png'
    },
    {
        content: 'Client_Review_2-Post.jpg',
        thumb: 'Client_Review_2-Post_thumb.png'
    },
    {
        content: 'Client_Review_2-Story.jpg',
        thumb: 'Client_Review_2-Story_thumb.png'
    },
    {
        content: 'Duration-Post.jpg',
        thumb: 'Duration-Post_thumb.png'
    },
    {
        content: 'Duration-Story.jpg',
        thumb: 'Duration-Story_thumb.png'
    },
    {
        content: 'Individual-Post.jpg',
        thumb: 'Individual-Post_thumb.png'
    },
    {
        content: 'Individual-Story.jpg',
        thumb: 'Individual-Story_thumb.png'
    },
    {
        content: 'Lips-Blue-Post.jpg',
        thumb: 'Lips-Blue-Post_thumb.png'
    },
    {
        content: 'Lips-Blue-Story.jpg',
        thumb: 'Lips-Blue-Story_thumb.png'
    },
    {
        content: 'Lips_Candle-Post.jpg',
        thumb: 'Lips_Candle-Post_thumb.png'
    },
    {
        content: 'Lips_Candle-Story.jpg',
        thumb: 'Lips_Candle-Story_thumb.png'
    },
    {
        content: 'Lips_Pink-Post.jpg',
        thumb: 'Lips_Pink-Post_thumb.png'
    },
    {
        content: 'Lips_Pink-Story.jpg',
        thumb: 'Lips_Pink-Story_thumb.png'
    },
    {
        content: 'Lips_Versa_Post.jpg',
        thumb: 'Lips_Versa_Post_thumb.png'
    },
    {
        content: 'Lips_Versa_Story.jpg',
        thumb: 'Lips_Versa_Story_thumb.png'
    },
    {
        content: 'Me_Time-Post.jpg',
        thumb: 'Me_Time-Post_thumb.png'
    },
    {
        content: 'Me_Time-Story.jpg',
        thumb: 'Me_Time-Story_thumb.png'
    },
    {
        content: 'Pool-Summer-Post.jpg',
        thumb: 'Pool-Summer-Post_thumb.png'
    },
    {
        content: 'Pool-Summer-Story.jpg',
        thumb: 'Pool-Summer-Story_thumb.png'
    },
    {
        content: 'Shape_Look-Post.jpg',
        thumb: 'Shape_Look-Post_thumb.png'
    },
    {
        content: 'Shape_Look-Story.jpg',
        thumb: 'Shape_Look-Story_thumb.png'
    },
    {
        content: 'Treat-Areas-1-Post.jpg',
        thumb: 'Treat-Areas-1-Post_thumb.png'
    },
    {
        content: 'Treat-Areas-1-Story.jpg',
        thumb: 'Treat-Areas-1-Story_thumb.png'
    },
    {
        content: 'Treat_Areas_Opt-2-Post.jpg',
        thumb: 'Treat_Areas_Opt-2-Post_thumb.png'
    },
    {
        content: 'Treat_Areas_Opt-2-Story.jpg',
        thumb: 'Treat_Areas_Opt-2-Story_thumb.png'
    },
    {
        content: 'Treat_Areas_Opt-3-Post.jpg',
        thumb: 'Treat_Areas_Opt-3-Post_thumb.png'
    },
    {
        content: 'Treat_Areas_Opt-3-Story.jpg',
        thumb: 'Treat_Areas_Opt-3-Story_thumb.png'
    },
    {
        content: 'Versa_Black-2-Post.jpg',
        thumb: 'Versa_Black-2-Post_thumb.png'
    },
    {
        content: 'Versa_Black-2-Story.jpg',
        thumb: 'Versa_Black-2-Story_thumb.png'
    },
    {
        content: 'Versa_Black-Post.jpg',
        thumb: 'Versa_Black-Post_thumb.png'
    },
    {
        content: 'Versa_Black-Story.jpg',
        thumb: 'Versa_Black-Story_thumb.png'
    },
    {
        content: 'Versa_White-Post.jpg',
        thumb: 'Versa_White-Post_thumb.png'
    },
    {
        content: 'Versa_White-Story.jpg',
        thumb: 'Versa_White-Story_thumb.png'
    },
    {
        content: 'Your_Look-Post.jpg',
        thumb: 'Your_Look-Post_thumb.png'
    },
    {
        content: 'Your_Look-Story.jpg',
        thumb: 'Your_Look-Story_thumb.png'
    },
    /*******************22-02-2023********************/
    {content: "Education-Mistakes_1080x1350_Slide1.jpg", thumb: "Thumb-Education-Mistakes_1080x1350_Slide1.png"},
    {content: "Education-Mistakes_1080x1350_Slide2.jpg", thumb: "Thumb-Education-Mistakes_1080x1350_Slide2.png"},
    {content: "Education-Mistakes_1080x1350_Slide3.jpg", thumb: "Thumb-Education-Mistakes_1080x1350_Slide3.png"},
    {content: "Education-Mistakes_1080x1350_Slide4.jpg", thumb: "Thumb-Education-Mistakes_1080x1350_Slide4.png"},
    {content: "Education-Mistakes_1080x1350_Slide5.jpg", thumb: "Thumb-Education-Mistakes_1080x1350_Slide5.png"},
    {content: "Education-Mistakes_1080x1920_Slide1.jpg", thumb: "Thumb-Education-Mistakes_1080x1920_Slide1.png"},
    {content: "Education-Mistakes_1080x1920_Slide2.jpg", thumb: "Thumb-Education-Mistakes_1080x1920_Slide2.png"},
    {content: "Education-Mistakes_1080x1920_Slide3.jpg", thumb: "Thumb-Education-Mistakes_1080x1920_Slide3.png"},
    {content: "Education-Mistakes_1080x1920_Slide4.jpg", thumb: "Thumb-Education-Mistakes_1080x1920_Slide4.png"},
    {content: "Education-Mistakes_1080x1920_Slide5.jpg", thumb: "Thumb-Education-Mistakes_1080x1920_Slide5.png"},
    {content: "Education-Questions_Slide1_1080x1350.mp4", thumb: "Thumb-Education-Questions_Slide1_1080x1350.png"},
    {content: "Education-Questions_Slide2_1080x1350+(1).jpg", thumb: "Thumb-Education-Questions_Slide2_1080x1350 (1).png"},
    {content: "Education-Right-For-Me_1080x1350.jpg", thumb: "Thumb-Education-Right-For-Me_1080x1350.png"},
    {content: "Education-Right-For-Me_1080x1920.jpg", thumb: "Thumb-Education-Right-For-Me_1080x1920.png"},
    {content: "Face-Shapes_1080x1350_Slide1.jpg", thumb: "Thumb-Face-Shapes_1080x1350_Slide1.png"},
    {content: "Face-Shapes_1080x1350_Slide2.jpg", thumb: "Thumb-Face-Shapes_1080x1350_Slide2.png"},
    {content: "Face-Shapes_1080x1350_Slide3.jpg", thumb: "Thumb-Face-Shapes_1080x1350_Slide3.png"},
    {content: "Face-Shapes_1080x1350_Slide4.jpg", thumb: "Thumb-Face-Shapes_1080x1350_Slide4.png"},
    {content: "Face-Shapes_1080x1350_Slide5.jpg", thumb: "Thumb-Face-Shapes_1080x1350_Slide5.png"},
    {content: "Face-Shapes_1080x1350_Slide6.jpg", thumb: "Thumb-Face-Shapes_1080x1350_Slide6.png"},
    {content: "Face-Shapes_1080x1920_Slide1.jpg", thumb: "Thumb-Face-Shapes_1080x1920_Slide1.png"},
    {content: "Face-Shapes_1080x1920_Slide2.jpg", thumb: "Thumb-Face-Shapes_1080x1920_Slide2.png"},
    {content: "Face-Shapes_1080x1920_Slide3.jpg", thumb: "Thumb-Face-Shapes_1080x1920_Slide3.png"},
    {content: "Face-Shapes_1080x1920_Slide4.jpg", thumb: "Thumb-Face-Shapes_1080x1920_Slide4.png"},
    {content: "Face-Shapes_1080x1920_Slide5.jpg", thumb: "Thumb-Face-Shapes_1080x1920_Slide5.png"},
    {content: "Face-Shapes_1080x1920_Slide6.jpg", thumb: "Thumb-Face-Shapes_1080x1920_Slide6.png"},
    {content: "Injectors-Glossary_1080x1350_Slide1.jpg", thumb: "Thumb-Injectors-Glossary_1080x1350_Slide1.png"},
    {content: "Injectors-Glossary_1080x1350_Slide2.jpg", thumb: "Thumb-Injectors-Glossary_1080x1350_Slide2.png"},
    {content: "Injectors-Glossary_1080x1350_Slide3.jpg", thumb: "Thumb-Injectors-Glossary_1080x1350_Slide3.png"},
    {content: "Injectors-Glossary_1080x1920_Slide1.jpg", thumb: "Thumb-Injectors-Glossary_1080x1920_Slide1.png"},
    {content: "Injectors-Glossary_1080x1920_Slide2.jpg", thumb: "Thumb-Injectors-Glossary_1080x1920_Slide2.png"},
    {content: "Injectors-Glossary_1080x1920_Slide3.jpg", thumb: "Thumb-Injectors-Glossary_1080x1920_Slide3.png"},
    {content: "Injectors-Glossary_1080x1920_Slide4.jpg", thumb: "Thumb-Injectors-Glossary_1080x1920_Slide4.png"},
    {content: "Injectors-Glossary_1080x1920_Slide5.jpg", thumb: "Thumb-Injectors-Glossary_1080x1920_Slide5.png"},
    {content: "Injectors-Glossary_1080x1920_Slide6.jpg", thumb: "Thumb-Injectors-Glossary_1080x1920_Slide6.png"},
    {content: "Injectors-Glossary_1080x1920_Slide7.jpg", thumb: "Thumb-Injectors-Glossary_1080x1920_Slide7.png"},
    {content: "International-Womens-Day.gif", thumb: "Thumb-International-Womens-Day.png"},
    {content: "International-Womens-Day.mp4", thumb: "Thumb-International-Womens-Day.png"},
    {content: "Lip-Appreciation_1080x1350.jpg", thumb: "Thumb-Lip-Appreciation_1080x1350.png"},
    {content: "Lip-Appreciation_1080x1920.jpg", thumb: "Thumb-Lip-Appreciation_1080x1920.png"},
    {content: "Love-Language_1080x1350_Slide1.jpg", thumb: "Thumb-Love-Language_1080x1350_Slide1.png"},
    {content: "Love-Language_1080x1350_Slide2.jpg", thumb: "Thumb-Love-Language_1080x1350_Slide2.png"},
    {content: "Love-Language_1080x1350_Slide3.jpg", thumb: "Thumb-Love-Language_1080x1350_Slide3.png"},
    {content: "Love-Language_1080x1350_Slide4.jpg", thumb: "Thumb-Love-Language_1080x1350_Slide4.png"},
    {content: "Love-Language_1080x1350_Slide5.jpg", thumb: "Thumb-Love-Language_1080x1350_Slide5.png"},
    {content: "Love-Language_1080x1920_Slide1.jpg", thumb: "Thumb-Love-Language_1080x1920_Slide1.png"},
    {content: "Love-Language_1080x1920_Slide2.jpg", thumb: "Thumb-Love-Language_1080x1920_Slide2.png"},
    {content: "Love-Language_1080x1920_Slide3.jpg", thumb: "Thumb-Love-Language_1080x1920_Slide3.png"},
    {content: "Love-Language_1080x1920_Slide4.jpg", thumb: "Thumb-Love-Language_1080x1920_Slide4.png"},
    {content: "Love-Language_1080x1920_Slide5.jpg", thumb: "Thumb-Love-Language_1080x1920_Slide5.png"},
    {content: "Product_Lips_1080x1080.jpg", thumb: "Thumb-Product_Lips_1080x1080.png"},
    {content: "Product_Lips_1080x1350.jpg", thumb: "Thumb-Product_Lips_1080x1350.png"},
    {content: "Product_Versa_1080x1350.jpg", thumb: "Thumb-Product_Versa_1080x1350.png"},
    {content: "Product_Versa_1080x1920.jpg", thumb: "Thumb-Product_Versa_1080x1920.png"},
    {content: "Reminder-Book-Now_1080x1350.jpg", thumb: "Thumb-Reminder-Book-Now_1080x1350.png"},
    {content: "Reminder-Book-Now_1080x1920.jpg", thumb: "Thumb-Reminder-Book-Now_1080x1920.png"},
    {content: "Reminder-Consult_1080x1350_Opt1.jpg", thumb: "Thumb-Reminder-Consult_1080x1350_Opt1.png"},
    {content: "Reminder-Consult_1080x1350_Opt2.jpg", thumb: "Thumb-Reminder-Consult_1080x1350_Opt2.png"},
    {content: "Reminder-Consult_1080x1920_Opt1.jpg", thumb: "Thumb-Reminder-Consult_1080x1920_Opt1.png"},
    {content: "Reminder-Consult_1080x1920_Opt2.jpg", thumb: "Thumb-Reminder-Consult_1080x1920_Opt2.png"},
    {content: "Reminder-Valentines_1080x1920.jpg", thumb: "Thumb-Reminder-Valentines_1080x1920.png"},
    {content: "Self-Care-Sunday_1080x1350_Slide1.jpg", thumb: "Thumb-Self-Care-Sunday_1080x1350_Slide1.png"},
    {content: "Self-Care-Sunday_1080x1350_Slide2.jpg", thumb: "Thumb-Self-Care-Sunday_1080x1350_Slide2.png"},
    {content: "Self-Care-Sunday_1080x1350_Slide3.jpg", thumb: "Thumb-Self-Care-Sunday_1080x1350_Slide3.png"},
    {content: "Self-Care-Sunday_1080x1350_Slide4.jpg", thumb: "Thumb-Self-Care-Sunday_1080x1350_Slide4.png"},
    {content: "Self-Care-Sunday_1080x1920_Slide1.jpg", thumb: "Thumb-Self-Care-Sunday_1080x1920_Slide1.png"},
    {content: "Self-Care-Sunday_1080x1920_Slide2.jpg", thumb: "Thumb-Self-Care-Sunday_1080x1920_Slide2.png"},
    {content: "Self-Care-Sunday_1080x1920_Slide3.jpg", thumb: "Thumb-Self-Care-Sunday_1080x1920_Slide3.png"},
    {content: "Self-Care-Sunday_1080x1920_Slide4.jpg", thumb: "Thumb-Self-Care-Sunday_1080x1920_Slide4.png"},
    {content: "Testimonial_1080x1350_Opt1.jpg", thumb: "Thumb-Testimonial_1080x1350_Opt1.png"},
    {content: "Testimonial_1080x1350_Opt2.jpg", thumb: "Thumb-Testimonial_1080x1350_Opt2.png"},
    {content: "Testimonial_1080x1920_Opt1.jpg", thumb: "Thumb-Testimonial_1080x1920_Opt1.png"},
    {content: "Testimonial_1080x1920_Opt2.jpg", thumb: "Thumb-Testimonial_1080x1920_Opt2.png"},

    /******************** 08-04-2023 *********************/
    {content: "April_Fools_Day_Option_1.jpg", thumb: "April_Fools_Day_Option_1_thumb.png"},
    {content: "April_Fools_Day_Option_2.jpg", thumb: "April_Fools_Day_Option_2_thumb.png"},
    {content: "Beauty_Goals.jpg", thumb: "Beauty_Goals_thumb.png"},
    {content: "Beauty_Goals_Story.jpg", thumb: "Beauty_Goals_Story_thumb.png"},
    {content: "Best_Friend_Day.jpg", thumb: "Best_Friend_Day_thumb.png"},
    {content: "Best_Friend_Day_Story.jpg", thumb: "Best_Friend_Day_Story_thumb.png"},
    {content: "Christina_Testimonial_Slide_1_US.jpg", thumb: "Christina_Testimonial_Slide_1_US_thumb.png"},
    {content: "Christina_Testimonial_Slide_2_US.jpg", thumb: "Christina_Testimonial_Slide_2_US_thumb.png"},
    {content: "Christina_Testimonial_StoryUS.jpg", thumb: "Christina_Testimonial_Story_US_thumb.png"},
    {content: "Designed_for_very_iteration_of_beauty_US.jpg", thumb: "Designed_for_very_iteration_of_beauty_US_thumb.png"},
    {content: "Designed_for_very_iteration_of_beauty_US_Story.jpg", thumb: "Designed_for_very_iteration_of_beauty_US_Story_thumb.png"},
    {content: "Earth_Day_Option_1.jpg", thumb: "Earth_Day_Option_1_thumb.png"},
    {content: "Earth_Day_Option_2.jpg", thumb: "Earth_Day_Option_2_thumb.png"},
    {content: "Happy_Easter.jpg", thumb: "Happy_Easter_thumb.png"},
    {content: "Happy_International_Nurses_Day.jpg", thumb: "Happy_International_Nurses_Day_thumb.png"},
    {content: "Happy_National_Doctor's_Day_US.jpg", thumb: "Happy_National_Doctor's_Day_US_thumb.png"},
    {content: "Happy_National_Doctor's_Day_US_Story.jpg", thumb: "Happy_National_Doctor's_Day_US_Story_thumb.png"},
    {content: "Is_filler_for_me.jpg", thumb: "Is_filler_for_me_thumb.png"},
    {content: "Is_filler_for_me_Story.jpg", thumb: "Is_filler_for_me_Story_thumb.png"},
    {content: "Janet_Testimonial_Slide_1_US.jpg", thumb: "Janet_Testimonial_Slide_1_US_thumb.png"},
    {content: "Janet_Testimonial_Slide_2_US.jpg", thumb: "Janet_Testimonial_Slide_2_US_thumb.png"},
    {content: "Janet_Testimonial_Story_US.jpg", thumb: "Janet_Testimonial_Story_US_thumb.png"},
    {content: "Katerina_Testimonial_Slide_1_US.jpg", thumb: "Katerina_Testimonial_Slide_1_US_thumb.png"},
    {content: "Katerina_Testimonial_Slide_2_US.jpg", thumb: "Katerina_Testimonial_Slide_2_US_thumb.png"},
    {content: "Katerina_Testimonial_Story_US.jpg", thumb: "Katerina_Testimonial_Story_US_thumb.png"},
    {content: "Let's_Find_You_US.jpg", thumb: "Let's_Find_You_US_thumb.png"},
    {content: "Let's_Find_You_US_Story.jpg", thumb: "Let's_Find_You_US_Story_thumb.png"},
    {content: "MET_GALA_DAY_1080p.mp4", thumb: "MET_GALA_DAY_1080p_thumb.png"},
    {content: "Mother's_Day.jpg", thumb: "Mother's_Day_thumb.png"},
    {content: "Mother's_Day_Story.jpg", thumb: "Mother's_Day_Story_thumb.png"},
    {content: "Reminder_to_Book.jpg", thumb: "Reminder_to_Book_thumb.png"},
    {content: "Reminder_to_Book_Story.jpg", thumb: "Reminder_to_Book_Story_thumb.png"},
    {content: "Reminder_to_book-Me_Time.jpg", thumb: "Reminder_to_book-Me_Time_thumb.png"},
    {content: "Reminder_to_book-Me_Time_Story.jpg", thumb: "Reminder_to_book-Me_Time_Story_thumb.png"},
    {content: "Revanesse%C2%AE_Lips%E2%84%A2%2B.jpg", thumb: "Revanesse%C2%AE_Lips%E2%84%A2%2B_thumb.png"},
    {content: "Revanesse%C2%AE_Versa%E2%84%A2%2B.jpg", thumb: "Revanesse%C2%AE_Versa%E2%84%A2%2B_thumb.png"},
    {content: "Small_Business_Day.jpg", thumb: "Small_Business_Day_thumb.png"},
    {content: "Summer_is_calling_US.jpg", thumb: "Summer_is_calling_US_thumb.png"},
    {content: "Summer_is_calling_US_Story.jpg", thumb: "Summer_is_calling_US_Story_thumb.png"},
    {content: "USA_-_Q2_Clinician_Assets.pdf", thumb: ""},
    {content: "USA_-_Q2_Clinician_Assets.pptx", thumb: ""},
    {content: "Breast+Cancer+Awareness+Post+1.png", thumb: "Breast+Cancer+Awareness+Post+1-thumb.jpg"},
    {content: "Breast+Cancer+Awareness+Story+1.png", thumb: "Breast+Cancer+Awareness+Story+1-thumb.jpg"},
    {content: "Breast+Cancer+Awareness+Story+2.png", thumb: "Breast+Cancer+Awareness+Story+2-thumb.jpg"},
    {content: "Breast+Cancer+Awareness+Story+3.png", thumb: "Breast+Cancer+Awareness+Story+3-thumb.jpg"},
    {content: "Breast+Cancer+Awareness+Post+2.png", thumb: "Breast+Cancer+Awareness+Post+2-thumb.jpg"},
    {content: "Breast+Cancer+Awareness+Post+3.png", thumb: "Breast+Cancer+Awareness+Post+3-thumb.jpg"},
    {content: "Girl+Math+1.png", thumb: "Girl+Math+1-thumb.jpg"},
    {content: "Girl+Math+2.png", thumb: "Girl+Math+2-thumb.jpg"},
    {content: "iMessage+-+Carousel+1.png", thumb: "iMessage+-+Carousel+1-thumb.jpg"},
    {content: "iMessage+-+Carousel+2.png", thumb: "iMessage+-+Carousel+2-thumb.jpg"},
    {content: "iMessage+-+Carousel+3.png", thumb: "iMessage+-+Carousel+3-thumb.jpg"},
    {content: "It's+Time+for+Appointment.mov", thumb: "It's+Time+for+Appointment-thumb.jpg"},
    {content: "October+2023+-+Suggested+Post+Copy.pdf", thumb: ""},

    /******************** 21-03-2024 *********************/
    {content: "Sequence+01_1.mp4", thumb: "Sequence+01_1-thumb.jpg"},
    {content: "Instagram+story+-+68.png", thumb: "Instagram+story+-+68-thumb.jpg"},
    {content: "Instagram+story+-+94.png", thumb: "Instagram+story+-+94-thumb.jpg"},
    {content: "Instagram+post+-+97.png", thumb: "Instagram+post+-+97-thumb.jpg"},
    {content: "Instagram+story+-+92.png", thumb: "Instagram+story+-+92-thumb.jpg"},
    {content: "Instagram+story+-+69.png", thumb: "-thumbInstagram+story+-+69.jpg"},
    {content: "Instagram+post+-+25.png", thumb: "Instagram+post+-+25-thumb.jpg"},
    {content: "Instagram+post+-+96.png", thumb: "Instagram+post+-+96-thumb.jpg"}
]


export const clinicianAssets = [
    {
        content: 'Lips%20In-Clinic%20Video.mp4',
        thumb: 'Lips%20In-Clinic%20Video-thmb_thmb.jpg'
    },
    {
        content: 'Rewards%20In-Clinic%20Video.mp4',
        thumb: 'Rewards-In-Clinic-thmb.jpg'
    },
    {
        content: 'Portfolio%20In-Clinic%20Video.mp4',
        thumb: 'Portfolio%20In-Clinic%20Video_thmb.jpg'
    },
]
