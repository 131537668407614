import { tokenVerification } from "./requests/requests";
import IMask from "imask";

export function setCookie( name, value, days ) {
    let expires = "";
    if ( days ) {
        let date = new Date();
        date.setTime( date.getTime() + (days * 24 * 60 * 60 * 1000) );
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie( name ) {
    let nameEQ = name + "=";
    let ca = document.cookie.split( ';' );
    for ( let i = 0; i < ca.length; i++ ) {
        let c = ca[ i ];
        while ( c.charAt( 0 ) === ' ' ) c = c.substring( 1, c.length );
        if ( c.indexOf( nameEQ ) === 0 ) return c.substring( nameEQ.length, c.length );
    }
    return null;
}

export function loginValidation() {
    let url = new URL( window.location.href )
    if ( url.pathname.replace( /\\|\//g, '' ) !== "login" ) {
        if ( getCookie( 'token' ) === null ) {
            window.location.href = '/login'
        }
    } else {
        if ( getCookie( 'token' ) !== null ) {
            window.location.href = '/'
        }
    }
}

export function tokenValid() {
    tokenVerification().then( data => {
        if ( 'errors' in data ) {
            window.location.href = '/login'
        } else {
            return data.id
        }
    } )
}

export function logout() {
    setCookie( 'token', '', -1000 )
}

export function validatePhoneNumber( e ) {
    const re = /\+1\(\d{3}\)\d{7}/
    const phoneNumber = e.target.value
    const valid = re.test( phoneNumber )
    const cleanPhoneNumber = phoneNumber.replace( /([\s()])|(^\+.)/g, '' )
    return {
        valid: valid,
        cleanPhoneNumber: cleanPhoneNumber
    }
}

export function phoneMaskNumberInit() {
    let element = document.getElementById( 'phone' )
    let maskOptions = {
        mask: '+{1}(000)0000000'
    }
    let mask = IMask( element, maskOptions )
    return () => mask.destroy()
}

export function phoneNumFromDb( number ) {
    if ( number.length <= 10 ) {
        let arrNumber = number.split( '' )
        arrNumber.splice( 3, 0, ')' )
        arrNumber.splice( 0, 0, '+1(' )
        return arrNumber.join( '' )
    }
    return number
}

export function generateScanCode() {
    return (Math.random() * 1e32).toString( 36 ).substring( 2, 10 )
}