import React from 'react';

const IconRebates = () => {
    return (
        <svg aria-hidden="true"
             focusable="false"
             width='100%'
             height='100%'
             role="img"
             viewBox="0 0 640 512">
            <path fill="currentColor"
                  d="M608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zm-16 272c-35.35 0-64 28.65-64 64H112c0-35.35-28.65-64-64-64V176c35.35 0 64-28.65 64-64h416c0 35.35 28.65 64 64 64v160zM320 160c-44.18 0-80 42.98-80 96 0 53.01 35.81 96 80 96 44.17 0 80-42.97 80-96 0-53.02-35.82-96-80-96z"
                  className="">
            </path>
        </svg>
    );
};

export default IconRebates;