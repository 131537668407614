import React, { useEffect, useRef, useState } from 'react';
import './PatientEdit.scss'
import IconMinus from "../../../Icons/IconMinus";
import IconPlus from "../../../Icons/IconPlus";
import PopUp from "../../../PopUp/PopUp";
import {
    getUser,
    getCompanyInventories,
    getUserLoyaltyCards,
    updateUser, toUseInventories, toRedeemCard,
} from "../../../../requests/requests";
import Preloader from "../../../Preloader/Preloader";
import { validatePhoneNumber } from "../../../../helperFunctions";
import { useNavigate } from "react-router-dom";
import { useAdminStore, useClinics, useCompany } from "../../../../stores/adminStore";
import { updateUserForRep } from "../../../../requests/rep_selected_clinic_requests";
import IMask from "imask";

const PatientEdit = () => {

    const navigate = useNavigate()

    /************************STORE***************************/
    const isRepAdmin = useAdminStore( state => state.isRepAdmin )
    const currentClinicId = useClinics( state => state.currentClinicId )
    const companyId = useCompany( state => state.companyId )
    /********************************************************/

    // console.log(`currentClinicId ${currentClinicId}; companyId ${companyId}`)

    const [ preloader, setPreloader ] = useState( true )
    const [ updated, setUpdated ] = useState( false )
    const [ updatedText, setUpdatedText ] = useState( 'Updated' )
    const idArr = window.location.href.split( '/' )
    const searchId = Number( idArr[ idArr.length - 1 ] )
    // const currentPatient = patientsRecent.find(user => user.id === searchId)

    const clearImageField = <span className='clear_btn' onClick={ () => {
        setMarketing( { ...marketing, image: '' } )
        setShowImage( '' )
    } }>clear</span>

    useEffect( () => {
        if ( !currentClinicId && !companyId ) {
            navigate( -1 )
        }
        getCurrentUserFunc()
    }, [] )

    // PhoneMask Initialization
    const phoneInput = useRef( null )
    useEffect( () => { // PhoneMask Initialization
        let element = phoneInput.current
        let maskOptions = {
            mask: '+{1}(000)0000000'
        }
        let mask = IMask( element, maskOptions )
        return () => mask.destroy()
    } )

    useEffect( () => {
        getAndSortInventoriesFunc()
    }, [ currentClinicId ] )

    useEffect( () => { // Return to the patients page after bonus update
        if ( cardsUpdate ) {
            // navigate( '/patients?page=1' )
            navigate( -1 )
        }
    }, [ updated ] )

    const [ patient, setPatient ] = useState( {} )
    const [ phoneError, setPhoneError ] = useState( '' )
    const [ buttonsBlock, setButtonsBlock ] = useState( true )
    const [ showRebates, setShowRebates ] = useState( true )
    const [ showAccount, setShowAccount ] = useState( false )
    const [ showMarketing, setShowMarketing ] = useState( false )
    const [ marketing, setMarketing ] = useState( { message: '', image: '' } )
    const [ showImage, setShowImage ] = useState( '' )
    const [ rebateCards, setRebateCards ] = useState( [] )
    const [ selectedCardId, setSelectedCardId ] = useState( null )
    const [ currentCardObj, setCurrentCardObj ] = useState( {} )
    const [ adjustVersaRebates, setAdjustVersaRebates ] = useState( 0 )
    const [ currentVersaBalance, setCurrentVersaBalance ] = useState( '' )
    const [ greenCredits, setGreenCredits ] = useState( [] )
    const [ notScannedInventories, setNotScannedInventories ] = useState( [] )
    const [ redCredits, setRedCredits ] = useState( [] )
    const selectRef = useRef( null )
    const [ cardsUpdate, setCardsUpdate ] = useState( false )
    const [ patientStoreId, setPatientStoreId ] = useState( null )
    const [ patientCompanyId, setPatientCompanyId ] = useState( null )

    // We generate an 'onChange' event in select to simulate the selection of the last card
    useEffect( () => {
        if ( rebateCards.length ) {
            let nativeSelectValueSetter = Object.getOwnPropertyDescriptor( window.HTMLSelectElement.prototype, "value" ).set
            nativeSelectValueSetter.call( selectRef.current, rebateCards[ 0 ].progress_id )
            let ev2 = new Event( 'change', { bubbles: true } )
            selectRef.current.dispatchEvent( ev2 )
        }
    }, [ rebateCards ] )

    const inputsHandler = ( e ) => {
        let name = e.target.name
        let value = e.target.value
        if ( name === 'gender' ) {
            let numVal = Number( value )
            setPatient( { ...patient, [ name ]: numVal } )
        } else if ( name === 'birth_date' ) {
            let dateVal = new Date( value ).toISOString()
            setPatient( { ...patient, [ name ]: dateVal } )
        } else {
            setPatient( { ...patient, [ name ]: value } )
        }
    }
    const phoneHandler = ( e ) => {
        let { valid, cleanPhoneNumber } = validatePhoneNumber( e )
        if ( valid ) {
            setPhoneError( '' )
            setPatient( { ...patient, phone_number: cleanPhoneNumber } )
        } else {
            setPhoneError( 'Invalid phone number' )
        }
    }
    const downLoadImageHandler = ( e ) => {
        if ( e.target.files[ 0 ].type.split( '/' )[ 0 ] === 'image' ) {
            setMarketing( { ...marketing, image: e.target.files[ 0 ] } )
            let reader = new FileReader()
            reader.onload = ( e ) => {
                setShowImage( e.target.result )
            }
            reader.readAsDataURL( e.target.files[ 0 ] )
        } else {
            setMarketing( { ...marketing, image: '' } )
        }
    }
    const saveUserChanges = () => {
        setPreloader( true )
        updateUser( patient ).then( data => {
            // console.log( 'patient', patient )
            if ( !('errors' in data) ) {
                setPreloader( false )
                setUpdated( true )
            } else {
                console.log( 'error', data )
                setPreloader( false )
                setUpdatedText( data?.errors?.user?.phone_number || data?.errors?.user?.email )
                setUpdated( true )
            }
        } )
    }

    const updateUserAccount = () => {
        setPreloader( true )
        const data = {
            company_id: patientCompanyId,
            store_id: patientStoreId,
            first_name: patient.first_name,
            last_name: patient.last_name,
            phone_number: patient.phone_number,
            zip: patient.zip,
            gender: patient.gender,
            birth_date: patient.birth_date,
            email: patient.email
        }
        updateUserForRep( data, searchId )
            .then( data => {
                if ( 'errors' in data ) {
                    console.log( 'error', data )
                    setPreloader( false )
                    setUpdatedText( 'Something went wrong' )
                    setUpdated( true )
                } else {
                    setPreloader( false )
                    setUpdated( true )
                }
            } )
    }

    const getCurrentUserFunc = () => {
        getUser( searchId ).then( data => {
            if ( !('error' in data) ) {
                // console.log( data )
                setPatientStoreId( data?.store?.id )
                setPatientCompanyId( data?.promo?.company_id )
                setButtonsBlock( false )
                setPatient( data )
            }
            setPreloader( false )
            getLoyaltyCardsFunc()
        } )
    }
    const getLoyaltyCardsFunc = () => {
        // For convenience, we create imaginary cards from the progress of each card
        getUserLoyaltyCards( searchId )
            .then( data => {
                if ( 'error' in data ) {
                    console.log( data )
                } else {
                    let rebates_cards = []
                    data.forEach( card => {
                        let card_type = card.actable_type
                        let card_id = card.card.id
                        let card_name = card.card.name
                        let card_amount = card.card.amount
                        if ( card.list_progresses !== null ) {
                            card.list_progresses?.forEach( el => {
                                let newCard = {}
                                let type
                                switch ( card_type ) {
                                    case 'PunchCard':
                                        type = 'punch_cards'
                                        break
                                    case 'PointCard':
                                        type = 'point_cards'
                                        break
                                    case 'CreditPunchCard':
                                        type = 'credit_punch_cards'
                                        break
                                    case 'MembershipPrepaid':
                                        type = 'membership_prepaids'
                                        break
                                    case 'CreditCard':
                                        type = 'credit_cards'
                                        break
                                    case 'Membership':
                                        type = 'memberships'
                                        break
                                    default :
                                        type = 'punch_cards'
                                        break
                                }
                                newCard.card_type = type
                                newCard.card_id = card_id
                                newCard.amount = card_amount
                                newCard.card_name = card_name
                                newCard.progress_id = el.id
                                newCard.progress = el.progress
                                rebates_cards.push( newCard )
                            } )
                        } else {
                            let newEmptyCard = {}
                            let type
                            switch ( card_type ) {
                                case 'PunchCard':
                                    type = 'punch_cards'
                                    break
                                case 'PointCard':
                                    type = 'point_cards'
                                    break
                                case 'CreditPunchCard':
                                    type = 'credit_punch_cards'
                                    break
                                case 'MembershipPrepaid':
                                    type = 'membership_prepaids'
                                    break
                                case 'CreditCard':
                                    type = 'credit_cards'
                                    break
                                case 'Membership':
                                    type = 'memberships'
                                    break
                                default :
                                    type = 'punch_cards'
                                    break
                            }
                            newEmptyCard.card_type = type
                            newEmptyCard.card_id = card_id
                            newEmptyCard.amount = card_amount
                            newEmptyCard.card_name = card_name
                            newEmptyCard.progress_id = -1
                            newEmptyCard.progress = 0
                            rebates_cards.push( newEmptyCard )
                        }
                    } )
                    setRebateCards( rebates_cards )
                }
            } )
    }
    const getAndSortInventoriesFunc = () => {
        if ( companyId ) {
            getCompanyInventories( companyId ).then( ( data ) => {
                if ( 'error' in data ) {
                    console.log( data.error )
                } else {
                    let greenCredits = []
                    let redCredits = []
                    let notScanned = []
                    data.forEach( el => { // separate green and red inventories
                        if ( !el.scanned ) {
                            notScanned.push( el )
                            notScanned.sort( function ( a, b ) {
                                if ( a.code_value > b.code_value ) {
                                    return -1
                                }
                                if ( a.code_value < b.code_value ) {
                                    return 1
                                }
                                return 0
                            } )
                        }
                        if ( el.for_stats ) { // Create an array of not scanned (unused) inventories
                            greenCredits.push( el )
                            greenCredits.sort( function ( a, b ) {
                                if ( a.code_value > b.code_value ) {
                                    return -1
                                }
                                if ( a.code_value < b.code_value ) {
                                    return 1
                                }
                                return 0
                            } )
                        } else {
                            redCredits.push( el )
                        }
                    } )
                    setGreenCredits( greenCredits )
                    setRedCredits( redCredits )
                    setNotScannedInventories( notScanned )
                    setPreloader( false )
                }
            } )
        }
    }
    const currentVersaBalanceValueFunc = ( e ) => {
        if ( e.target.value !== '' ) {
            setSelectedCardId( e.target.value ) // to select cards
            let card = rebateCards.find( el => el.progress_id === +e.target.value )
            setCurrentCardObj( card )
            setCurrentVersaBalance( card.progress )
        } else {
            setSelectedCardId( '' )
            setCurrentCardObj( {} )
            setCurrentVersaBalance( '' )
        }
    }
    const applyCreditsChangesFunc = () => {
        setPreloader( true )
        let usedInventories = notScannedInventories.slice( 0, adjustVersaRebates ) // Array of inventories to use
        let idsUsedInventories = [] // New array for ids of inventories to use
        usedInventories.forEach( el => {
            idsUsedInventories.push( el.id )
        } )
        let data = {
            progress: adjustVersaRebates,
            user_id: patient.id,
            store_id: currentClinicId,
            company_id: companyId,
            progress_id: (currentCardObj.progress_id === -1) ? null : currentCardObj.progress_id,
            loyalty_card_id: currentCardObj.card_id,
            inventory_ids: idsUsedInventories
        }
        toUseInventories( data, currentCardObj.card_type )
            .then( () => {
                getAndSortInventoriesFunc()
                getLoyaltyCardsFunc()
                selectRef.current.value = ''
                setAdjustVersaRebates( 0 )
                setSelectedCardId( '' )
                setCurrentVersaBalance( '' )
                setUpdated( true )
            } )
            .then( () => {
                setCardsUpdate( true )
            } )
    }
    const toRedeemCardFunc = () => {
        setPreloader( true )
        if ( notScannedInventories.length ) {
            let inventoryId = notScannedInventories.slice( 0, 1 )[ 0 ].id
            let data = {}
            data.store_id = currentClinicId
            data.company_id = companyId
            data.progress_id = currentCardObj.progress_id
            data.user_id = patient.id
            data.inventory_ids = [ inventoryId ]
            data[ currentCardObj.card_type ] = {}
            toRedeemCard( data ).then( () => {
                getAndSortInventoriesFunc()
                getLoyaltyCardsFunc()
                selectRef.current.value = ''
                setAdjustVersaRebates( 0 )
                setSelectedCardId( '' )
                setCurrentVersaBalance( '' )
                setUpdated( true )
            } )
        }
    }

    const toSendMarketingFunc = () => {
        let message = {}
        message.type_message_id = "6"
        message.city_id = 64
        message.headline = "test"
        message.body = "test"
        message.expiration = "Sat Dec 04 2021 00:00:00 GMT+0300"
        message.send_at = "Fri Dec 03 2021 17:33:00 GMT+0300"
    }

    return (
        <div className='patientEdit'>
            <div className='component__wrap white'>
                {/*<button className='return_btn btn wide_btn beige_btn' onClick={ () => {*/ }
                {/*    navigate( -1 )*/ }
                {/*} }>&#8701; Return*/ }
                {/*</button>*/ }
                <div className="patientEdit__name dashboard__component_title">
                    <h2>{ (patient.first_name || patient.last_name) ? (patient.first_name + ' ' + patient.last_name) : '' }</h2>
                </div>
                <div className="patientEdit__purchases">
                    <div className="patientEdit__purchases_block purchases">
                        <span>Purchases</span>
                        <p>0</p>
                    </div>
                    <div className="patientEdit__purchases_block phone_number">
                        <span>Phone Number</span>
                        <p>{ patient.phone_number }</p>
                    </div>
                    <div className="patientEdit__purchases_block customer_since">
                        <span>Customer since:</span>
                        <p>{ patient.created_at ? patient.created_at.split( 'T' )[ 0 ] : '' }</p>
                    </div>
                </div>
                <div className='component__subtitle'>
                    <h3>Adjust Rebates</h3>
                    <button className="show__form_btn"
                            style={ { border: 'none' } }
                            disabled={ buttonsBlock }
                            onClick={ () => {
                                setShowRebates( !showRebates )
                            } }>{ showRebates ? <IconMinus/> : <IconPlus/> }</button>
                </div>
                <form className={ showRebates ? "patientEditRebate__form form" : "patientEditRebate__form form close" }>
                    <div className="form_field first_name rebate_cards">
                        <label htmlFor="rebate_card">Rebate Card</label>
                        <select name="rebate_card"
                                ref={ selectRef }
                                disabled={ !notScannedInventories.length || !rebateCards.length }
                                id="rebate_card"
                                onChange={ ( e ) => {
                                    setAdjustVersaRebates( 0 )
                                    currentVersaBalanceValueFunc( e )
                                } }
                                className='form__input'>
                            <option value=''></option>
                            { rebateCards.map( ( el, index ) => {
                                return <option key={ index }
                                               value={ el.progress_id }>{ el.card_name + (el.progress === 5 ? ' full' : '') }</option>
                            } ) }
                        </select>
                        { notScannedInventories.length ? null : <p>No product available</p> }

                    </div>
                    <div className="form_field first_name">
                        <label htmlFor="rebate_versa">Adjust Rebates</label>
                        <input type="number"
                               min={ 0 }
                               max={ notScannedInventories.length }
                            // disabled={ !selectedCardId }
                               disabled={ !notScannedInventories.length || !rebateCards.length }
                               id='rebate_versa'
                               name='rebate_versa'
                               value={ adjustVersaRebates }
                               onKeyUp={ ( e ) => {
                                   e.target.value = e.target.value.replace( /\D/g, "" ) // enter only numbers
                                   if ( e.target.value > notScannedInventories.length ) {
                                       e.target.value = notScannedInventories.length
                                   }
                               } }
                               onChange={ ( e ) => {
                                   setAdjustVersaRebates( +e.target.value )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field first_name">
                        <label htmlFor="current_balance">Current Rebate Balance</label>
                        <input type="text"
                               // disabled={ !selectedCardId }
                               disabled={ !notScannedInventories.length || !rebateCards.length }
                               readOnly={ true }
                               id='current_balance'
                               name='current_balance'
                               value={ currentVersaBalance }
                               className='form__input'/>
                    </div>
                    <div className="available_credits">
                        <h3>Available Credits</h3>
                        <div className="credits green">
                            <p>Green <span>Credits (purchased):</span></p>
                            <p>{ greenCredits.length }</p>
                        </div>
                        {/*<div className="credits red">*/ }
                        {/*    <p>Red <span>Credits (purchased):</span></p>*/ }
                        {/*    <p>{redCredits.length}</p>*/ }
                        {/*</div>*/ }
                    </div>
                    <button className="save_btn btn wide_btn beige_btn"
                            disabled={ !((Object.keys( currentCardObj ).length !== 0) && greenCredits.length && ((+adjustVersaRebates !== 0) || (currentCardObj.amount === currentCardObj.progress)))}
                            onClick={ ( e ) => {
                                e.preventDefault()
                                if ( (currentCardObj.amount === currentCardObj.progress) && (+adjustVersaRebates === 0) ) {
                                    toRedeemCardFunc()
                                } else {
                                    applyCreditsChangesFunc()
                                }
                            } }>{ ((currentCardObj.amount === currentCardObj.progress) && (+adjustVersaRebates === 0)) ? 'Redeem' : 'Apply' }
                    </button>
                </form>
                <div className='component__subtitle'>
                    <h3>Edit Account</h3>
                    <button className="show__form_btn"
                            style={ { border: 'none' } }
                            disabled={ buttonsBlock }
                            onClick={ () => {
                                setShowAccount( !showAccount )
                            } }>{ showAccount ? <IconMinus/> : <IconPlus/> }</button>
                </div>
                <form
                    className={ showAccount ? "patientEditProfile__form form" : "patientEditProfile__form form close" }>
                    <div className="form_field first_name">
                        <label htmlFor="first_name">First Name</label>
                        <input type="text"
                               id='first_name'
                               name='first_name'
                               defaultValue={ patient.first_name }
                               onBlur={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field last_name">
                        <label htmlFor="last_name">Last Name</label>
                        <input type="text"
                               id='last_name'
                               name='last_name'
                               defaultValue={ patient.last_name }
                               onBlur={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field phone_number">
                        <label htmlFor="phone">Phone number</label>
                        <input type="tel"
                               ref={ phoneInput }
                               id='phone'
                               name='phone_number'
                               defaultValue={ patient.phone_number }
                               onChange={ ( e ) => {
                                   phoneHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ phoneError }</p>
                    </div>
                    <div className="form_field zip_code">
                        <label htmlFor="zip_code">Zip Code</label>
                        <input type="text"
                               id='zip_code'
                               name='zip'
                               defaultValue={ patient.zip }
                               onBlur={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field gender">
                        <label htmlFor="gender">Gender</label>
                        <select name="gender"
                                id="gender"
                                value={ String( patient.gender ) }
                                onChange={ ( e ) => {
                                    inputsHandler( e )
                                } }
                                className='form__input'>
                            <option value="">Choose</option>
                            <option value="0">Male</option>
                            <option value="1">Female</option>
                        </select>
                    </div>
                    <div className="form_field birthday">
                        <label htmlFor="birthday">Birthday</label>
                        <input type="date"
                               id='birthday'
                               max={'2099-12-31'}
                               min={'1900-01-01'}
                               name='birth_date'
                               defaultValue={ patient.birth_date ? patient.birth_date.split( 'T' )[ 0 ] : '' }
                               onChange={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <button className="save_btn btn wide_btn beige_btn"
                            onClick={ ( e ) => {
                                e.preventDefault()
                                if ( isRepAdmin ) {
                                    updateUserAccount()
                                } else {
                                    saveUserChanges()
                                }
                            } }>Send
                    </button>
                </form>
                { updated
                    ?
                    <PopUp popUpMessage={ updatedText }
                           closeBtnTitle={ 'Close' }
                           showPopUp={ setUpdated }
                           closePopUp={ setUpdated }/>
                    :
                    null
                }
            </div>
            { !preloader || <Preloader/> }
        </div>
    );
};

export default PatientEdit;