import React, { useEffect, useRef, useState } from 'react';
import IconMinus from "../../../Icons/IconMinus";
import IconPlus from "../../../Icons/IconPlus";
import { validatePhoneNumber } from "../../../../helperFunctions";
import './ClinicDetails.scss'
import { useClinics, useCompany } from "../../../../stores/adminStore";
import { updateSelectedClinic } from "../../../../requests/rep_selected_clinic_requests";
import PopUp from "../../../PopUp/PopUp";
import IMask from "imask";

const ClinicDetails = ( { currentLocation, hcp_admin } ) => {

    /******************** GLOBAL STORE ***********************/
    const currentClinicId = useClinics( state => state.currentClinicId )
    const companyId = useCompany( state => state.companyId )
    /********************************************************/

    const [ showClinicDetails, setShowClinicDetails ] = useState( false )
    const [ phoneError, setPhoneError ] = useState( '' )
    const [ emailError, setEmailError ] = useState( '' )
    const [ haveBeenChanges, setHaveBeenChanges ] = useState( false )
    const [ location, setLocation ] = useState( {
        company_id: null,
        store_id: null,
        name: null,
        website: null,
        address: '',
        lat: null,
        lng: null,
        phone: null,
        isEmailToBeSent: true
    } )
    const [ hcpAdmin, setHcpAdmin ] = useState( {
        first_name: '',
        last_name: '',
        email: '',
        full_name: ''
    } )
    const [ updated, setUpdated ] = useState( false )
    const [ updatedText, setUpdatedText ] = useState( '' )


    const phoneInput = useRef( null )

    useEffect( () => {
        let maskOptions = {
            mask: '+{1}(000)0000000'
        }
        let mask = IMask( phoneInput.current, maskOptions )
        return () => mask.destroy()
    } )

    useEffect( () => {
        if ( hcp_admin && Object.keys( hcp_admin ).length ) {
            setHcpAdmin( {
                ...hcpAdmin,
                first_name: hcp_admin.first_name,
                last_name: hcp_admin.last_name,
                email: hcp_admin.email,
                full_name: hcp_admin.first_name + ' ' + hcp_admin.last_name
            } )
        }
    }, [ hcp_admin ] )

    useEffect( () => {
        setLocation( {
            ...location,
            company_id: companyId,
            store_id: currentClinicId,
            name: currentLocation?.name,
            website: currentLocation?.website,
            address: currentLocation?.address,
            lat: currentLocation?.lat,
            lng: currentLocation?.lng,
            phone: inputPhoneNumber( currentLocation?.phone )
        } )
    }, [ currentLocation.id ] ) // PhoneMask Initialization

    const inputsHandler = ( e ) => {
        let name = e.target.name
        let value = e.target.value
        setLocation( { ...location, [ name ]: value } )
        setHaveBeenChanges( true )
    }

    const emailHandler = ( e ) => {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        if ( !re.test( String( e.target.value ).toLowerCase() ) ) {
            setEmailError( 'Invalid email' )
        } else {
            setHcpAdmin( { ...hcpAdmin, email: e.target.value } )
            setHaveBeenChanges( true )
            setEmailError( '' )
        }
    }

    const adminNameHandler = ( e ) => {
        let name = e.target.value.split( ' ' )
        setHcpAdmin( { ...hcpAdmin, first_name: name[ 0 ], last_name: name.slice( 1 ).join( ' ' ) } )
        setHaveBeenChanges( true )
    }

    const phoneHandler = ( e ) => {
        let { valid, cleanPhoneNumber } = validatePhoneNumber( e )
        if ( valid ) {
            setPhoneError( '' )
            setLocation( { ...location, phone: cleanPhoneNumber } )
            setHaveBeenChanges( true )
        } else {
            setPhoneError( 'Invalid phone number' )
        }
    }

    const addressAutoComplete = ( e ) => {
        let autocomplete = new window.google.maps.places.Autocomplete( e.target )
        window.google.maps.event.addListener( autocomplete, 'place_changed', function () {
            let place = autocomplete.getPlace()
            setLocation( {
                ...location,
                address: place.formatted_address,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            } )
            setHaveBeenChanges( true )
        } )
    }

    /********  To open and close a block  *********/
    const wrapper = useRef( null )
    const formBlock = useRef( null )
    useEffect( () => {
        if ( showClinicDetails ) {
            wrapper.current.style.height = formBlock.current.clientHeight + 'px'
        } else {
            wrapper.current.style.height = 0
        }
    }, [ showClinicDetails ] )
    /**********************************************/

    const updateClinic = () => {
        let clearPhone
        if ( Array.isArray( location.phone ) ) {
            clearPhone = location.phone
        } else {
            clearPhone = location.phone
        }
        const data = {
            ...location,
            phone: clearPhone,
            first_name: hcpAdmin.first_name,
            last_name: hcpAdmin.last_name,
            email: hcpAdmin.email
        }
        updateSelectedClinic( data ).then( data => {
            if ( 'error' in data ) {
                console.log( data )
            } else {
                setUpdatedText( 'Updated' )
                setUpdated( true )
                setHaveBeenChanges( false )
            }
        } )
    }

    const canBeUpdate = () => {
        if ( phoneError && emailError ) return true
        if ( !haveBeenChanges ) return true
    }

    const inputPhoneNumber = ( number ) => {
        if ( Array.isArray( number ) ) return number[ number.length - 1 ]
        return number
    }

    const [ key, setKey ] = useState( null )

    useEffect( () => {
        setKey( Math.random() )
        setHaveBeenChanges(false)
        setPhoneError('')
    }, [ currentLocation.id ] )

    return (
        <div className="component__wrap white">
            <div className='component__subtitle'>
                <h3>Clinic Details</h3>
                <button className="show__form_btn"
                        style={ { border: 'none' } }
                    // disabled={ buttonsBlock }
                        onClick={ () => {
                            setShowClinicDetails( !showClinicDetails )
                        } }
                >
                    { showClinicDetails ? <IconMinus/> : <IconPlus/> }
                </button>
            </div>
            <div ref={ wrapper } className='form__wrap'>
                <form ref={ formBlock }
                      className='clinic_details__form form'>
                    <div className="form_field clinic_name">
                        <label htmlFor="name">Clinic Name</label>
                        <input type="text"
                               id='name'
                               name='name'
                               value={ location?.name }
                               onChange={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field clinic_name">
                        <label htmlFor="name">Clinic Phone Number</label>
                        <input type="text" key={ key }
                               ref={ phoneInput }
                               name='phone'
                               defaultValue={ location?.phone }
                               onChange={ ( e ) => {
                                   phoneHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ phoneError }</p>
                    </div>
                    <div className="form_field hcp_admin">
                        <label htmlFor="hcp_admin">Primary Admin</label>
                        <input type="text"
                               id='hcp_admin'
                               name='hcp_admin'
                               defaultValue={ hcpAdmin.full_name }
                               onChange={ ( e ) => {
                                   adminNameHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field address_field">
                        <label htmlFor="address">Address</label>
                        <input type="text"
                               id='address'
                               autoComplete='new-password'
                               name='address'
                               defaultValue={ location?.address }
                               onChange={ ( e ) => {
                                   addressAutoComplete( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field website">
                        <label htmlFor="website">Website</label>
                        <input type="text"
                               id='website'
                               name='website'
                               defaultValue={ location?.website }
                               onChange={ ( e ) => {
                                   inputsHandler( e )
                               } }
                               className='form__input'/>
                    </div>
                    <div className="form_field email">
                        <label htmlFor="email">Primary Admin Email</label>
                        <input type="email"
                               id='email'
                               name='email'
                               defaultValue={ hcpAdmin.email }
                               onChange={ ( e ) => {
                                   emailHandler( e )
                               } }
                               className='form__input'/>
                        <p className="password__error">{ emailError }</p>
                    </div>
                    <button className="save_btn btn wide_btn beige_btn"
                            disabled={ canBeUpdate() }
                            onClick={ ( e ) => {
                                e.preventDefault()
                                updateClinic()
                            } }
                    >Save Changes
                    </button>
                </form>
            </div>
            { updated
                ?
                <PopUp popUpMessage={ updatedText }
                       closeBtnTitle={ 'Close' }
                       showPopUp={ setUpdated }
                       closePopUp={ setUpdated }/>
                :
                null
            }
        </div>
    );
};

export default ClinicDetails;