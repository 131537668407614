import React from 'react';
import DashboardCard from "../../Dashboard/DashboardCards/DashboardCard";
import IconSyringe from "../../../Icons/IconSyringe";
import IconRebates from "../../../Icons/IconRebates";
import IconBus from "../../../Icons/IconBus";
import IconCard from "../../../Icons/IconCard";
import IconLabel from "../../../Icons/IconLabel";
import IconGift from "../../../Icons/IconGift";

const Statistics = ( { company } ) => {
    return (
        <div className="component__wrap">
            <div className="cards">
                <DashboardCard icon={ <IconSyringe/> }
                               count={ (Math.floor( company?.purchased_syringes_remaining ) || '0')
                                   + '/'
                                   + (Math.floor( company?.available_credits ) || '0') }
                               title={ 'Purchased Syringes Remaining/Available Credit' }/>
                <DashboardCard icon={ <IconRebates/> }
                               count={ (company?.rebates_applied || '0') }
                               title={ 'Total Credits Used' }
                               link={ '/rebates-applied' }/>
                <DashboardCard icon={ <IconSyringe/> } count={ company?.reimbursements_owed }
                               title={ 'Rewards Syringes Earned' }/>
                <DashboardCard icon={ <IconBus/> } count={ company?.reimbursements_shipped }
                               title={ 'Rewards Syringes Shipped To-Date' }
                               link={ '/reimbursement-shipped' }/>
                <DashboardCard icon={ <IconCard/> } count={ company?.total_patients } title={ 'Total Patients' }/>
                <DashboardCard icon={ <IconLabel/> } count={ company?.promotion_purchased }
                               title={ 'Promotions Purchased' }
                               link={ '/promotion-purchased' }/>
                <DashboardCard icon={ <IconGift/> } count={ company?.promotion_redeemed }
                               title={ 'Promotions Redeemed' }
                               link={ '/promotion-redeemed' }/>
                <DashboardCard icon={ <IconCard/> } count={ company?.purchase_history } title={ 'Purchasing History' }
                               link={ '/purchasing-history' }/>
            </div>
        </div>
    )
}

export default Statistics;