import { getCookie } from "../helperFunctions";

const base_url = 'https://prolleniumelite.epoxyapp.com/api'

export async function getStoreInfo( id ) {
    const response = await fetch( base_url
        + '/v2/stores/'
        + id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } );
    return await response.json();
}

export async function updateStoreInfo( id, store ) {
    const response = await fetch( base_url
        + '/v2/stores/'
        + id, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: JSON.stringify( store )
    } );
    return await response.json();
}

export async function updateStoreLogo( id, image ) {
    let data = new FormData();
    data.append( 'store[logo]', image, image.name )
    data.append( 'store[id]', id )
    const response = await fetch( base_url
        + '/v2/stores/'
        + id, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        headers: {
            // 'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: data
    } )
    return await response.json();
}

export async function createStoreImage( id, image, order ) {
    let data = new FormData();
    data.append( 'slide[image]', image, image.name )
    data.append( 'slide[appearance_order]', order )
    const response = await fetch( base_url
        + '/v2/stores/'
        + id
        + '/pictures/create_slide', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            // 'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: data
    } );
    return await response.json();
}

export async function updateStoreImage( slide_id, image, order ) {
    let data = new FormData();
    data.append( 'slide[image]', image, image.name )
    data.append( 'slide[appearance_order]', order )
    const response = await fetch( base_url
        + '/v2/pictures/'
        + slide_id
        + '/update_slide', {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        headers: {
            // 'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: data
    } );
    return await response.json();
}

export async function getEmployees( storeId ) {
    const response = await fetch( base_url
        + '/v4/employee/employee_by_store?store_id='
        + storeId, {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
    } )
    return await response.json()
}

export async function updateEmployee( data ) {
    const response = await fetch( base_url
        + '/v2/employees/'
        + data.id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: JSON.stringify( data )
    } )
    return await response.json()
}

export async function deleteEmployee( id ) {
    const response = await fetch( base_url
        + '/v2/employees/'
        + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
    } )
    return await response.text()
}

export async function createNewEmployee( data ) {
    const response = await fetch( base_url
        + '/v2/employees', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        },
        body: JSON.stringify( data )
    } )
    return await response.json()
}

export async function downloadBadge( employeeId, storeId ) {
    const response = await fetch( base_url
        + '/v2/employees/'
        + employeeId
        + '/generate_badge?store_id='
        + storeId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': getCookie( 'token' )
        }
    } )
    return await response.blob()
}