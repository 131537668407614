import React, { useEffect, useState } from 'react';

const AddLocationToAdmin = ( { clinicLocations, addedLocations, addLocationToAdmin } ) => {

    const [ show, setShow ] = useState( false )
    const [ list, setList ] = useState( [] )

    const filterLocationFunc = ( adminList, fullList ) => {
        let arrFilter = []
        adminList.map( ( { id } ) => arrFilter.push( id ) )
        return fullList.filter( ( { id } ) => !arrFilter.includes( id ) )
    }

    useEffect( () => {
        setList( filterLocationFunc( addedLocations, clinicLocations ) )
    }, [ addedLocations ] )

    const addLocationToAdminFunc = ( id ) => {
        const selectedLocation = clinicLocations.filter( el => el.id === id )
        const newLocationsList = [ ...addedLocations, ...selectedLocation ]
        addLocationToAdmin( newLocationsList )
    }

    return (
        <div className='admin_locations_add'>
            Add new location
            <span className={ `action ${ show ? 'open' : '' }` }
                  onClick={ () => setShow( !show ) }>
                                <span className='line vert'/>
                                <span className='line horis'/>
                            </span>
            { show && <select className="location_list"
                              disabled={ !list.length }
                              onChange={ ( e ) => {
                                  addLocationToAdminFunc( +e.target.value )
                                  setShow( false )
                              } }>
                <option value={ null }>{ list.length ? 'choose a location' : 'all locations added' }</option>
                { list.map( ( el, index ) => {
                    return <option key={ index } value={ el.id }>{ el.name }</option>
                } ) }
            </select> }

        </div>
    );
};

export default AddLocationToAdmin;