import React, { useEffect, useState } from 'react';
import './DashboardPurchasedHistory.scss'
import { getPurchaseHistoryByStore } from "../../../../requests/requests";
import Preloader from "../../../Preloader/Preloader";
import { useAdminStore, useClinics, useCompany } from "../../../../stores/adminStore";
import { getPurchaseHistoryRepByStore } from "../../../../requests/requestd_selected_clinic_statistics";

const DashboardPurchasedHistory = () => {

    /************************STORE***************************/
    const isRepAdmin = useAdminStore( state => state.isRepAdmin )
    const currentClinicId = useClinics( state => state.currentClinicId )
    const companyId = useCompany( state => state.companyId )
    /********************************************************/

    const [ purchases, setPurchases ] = useState( [] )
    const [ preloader, setPreloader ] = useState( true )
    const [ currentStoreName, setCurrentStoreName ] = useState( '' )

    useEffect( () => {
        if ( companyId && currentClinicId ) {
            setPreloader( true )

            if ( isRepAdmin ) {
                getPurchaseHistoryRepByStore( companyId, currentClinicId )
                    .then( data => {
                        if ( !('error' in data) ) {
                            setPurchases( data?.purchases )
                            setCurrentStoreName( data.store?.name )
                        }
                    } )
                    .then( () => setPreloader( false ) )
            } else {
                getPurchaseHistoryByStore( companyId, currentClinicId )
                    .then( data => {
                        if ( !('error' in data) ) {
                            setPurchases( data?.purchases )
                            setCurrentStoreName( data.store?.name )
                        }
                    } )
                    .then( () => setPreloader( false ) )
            }
        }
    }, [ currentClinicId ] )

    return (
        <div className='purchased_history'>
            <div className='component__wrap'>
                <div className="dashboard__component_title">
                    <h2>PURCHASED HISTORY</h2>
                </div>
                <table className="purchased_history__table table">
                    <thead>
                    <tr>
                        <th>Clinic Name</th>
                        <th>Clinic Location</th>
                        <th>Admin</th>
                        <th>Product</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Date/Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    { purchases.length
                        ?
                        purchases.map( ( purchase, index ) => {
                            return <tr key={ index }>
                                <td>{ currentStoreName }</td>
                                <td>{ purchase?.location_name }</td>
                                <td>{ purchase?.admin_email }</td>
                                <td>{ purchase?.product }</td>
                                <td>{ purchase?.amount }</td>
                                <td>{ purchase?.type }</td>
                                <td>{ purchase?.date_time }</td>
                            </tr>
                        } ) : ''
                    }
                    </tbody>
                </table>
                { purchases.length
                    ?
                    null
                    :
                    <h3 style={ { marginTop: '20px', fontSize: '20px', textAlign: 'center' } }>Nothing found</h3> }
            </div>
            { !preloader || <Preloader/> }
        </div>
    );
};

export default DashboardPurchasedHistory;