import React, {useState, useCallback, useRef, useEffect} from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import './CropImage.scss'

const CropImage = (props) => {

    const [srcImg, setSrcImg] = useState(null)
    //save the image that used to be crop
    const [image, setImage] = useState(null)
    // the name of the incoming image
    const [imgName, setImgName] = useState(null)
    //change the aspect ratio of crop tool as you preferred
    const [crop, setCrop] = useState(
        {
            unit: 'px',
            aspect: 4 / 3,
            width: 600
        }
    )

    //save the resulted image
    const [result, setResult] = useState(null)

    useEffect(() => {
        // Src image for ReactCrop
        setSrcImg(URL.createObjectURL(props.image))
        // Image name from Props Image Object
        setImgName(props.image.name)
    }, [props])

    // Base64 to DataImage Object
    function dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    // Generate and record cropped images
    const getCroppedImg = async () => {
        try {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d")
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            )
            const base64Image = canvas.toDataURL("image/jpeg", 0.4).replace("image/png", "image/octet-stream")

            // base64 to image object
            const res = dataURLtoFile(base64Image, imgName || 'file')

            // set result to state
            setResult(res)

            if (props.fieldName === 'logo') {

                // Set new logo to state and preview
                let reader = new FileReader()
                reader.onload = (e) => {

                    // Set new logo to state
                    props.setLogoState({
                        ...props.logoState,
                        logo_from_inp: res, // for upload logo to DB
                        logo_url: e.target.result, // for new logo preview
                    })
                }
                reader.readAsDataURL(res)

            } else if (props.fieldName) {

                // Set new image to state and to preview
                let reader = new FileReader()
                reader.onload = (e) => {

                    // Set new image to state
                    props.setImagesState({
                        ...props.imagesState,
                        [props.fieldName + '_from_inp']: res, // for upload to DB
                        [props.fieldName]: e.target.result // for new preview
                    })
                }
                reader.readAsDataURL(res)

            }

            // close CropImage component
            props.closeElement(false)
        } catch (e) {
            console.log("crop the image")
        }
    }

    return (
        <div className="crop_image_wrap">
            <div className="crop_image_bg" onClick={() => {
                props.closeElement(false)
            }}>
            </div>
            <div className='crop_image'>
                {srcImg && (
                    <>
                        <ReactCrop
                            className='full_image'
                            style={{maxWidth: "100%"}}
                            src={srcImg}
                            onImageLoaded={setImage}
                            crop={crop}
                            onChange={setCrop}
                        />
                        <button className='btn beige_btn wide_btn' onClick={getCroppedImg}>Save</button>
                    </>
                )}
            </div>
        </div>
    )
}

export default CropImage