import { getCookie } from "../helperFunctions";

const base_url = 'https://prolleniumelite.epoxyapp.com/api/'

const saveXml = ( data, fileName ) => {
    const url = window.URL.createObjectURL( data )
    const link = document.createElement( "a" )
    link.href = url
    link.setAttribute( "download", `${ fileName }.xlsx` )
    document.body.appendChild( link )
    link.click()
    document.body.removeChild( link )
}

export const getClinicListReport = () => {
    fetch( base_url + 'v5/reports/clinic_list',
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } ).then( response => {
        return response.blob()
    } ).then( data => saveXml( data, 'clinic_list' ) )
        .catch( error => console.log( 'ERROR getClinicListReport', error ) )
}

export const getDetailedReport = ( start, end ) => {
    fetch( base_url + 'v2/statistics/detailed_report?end_date='
        + end
        + '&start_date='
        + start,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } ).then( response => {
        return response.blob()
    } ).then( data => saveXml( data, 'detail_report' ) )
        .catch( error => console.log( 'ERROR getDetailedReport', error ) )
}

export const getMonthlySummaryReport = ( company_id, start, end ) => {
    fetch( base_url + 'v2/statistics/monthly_summary_report?company_id='
        + company_id
        + '&end_date='
        + end
        + '&start_date='
        + start,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } ).then( response => {
        return response.blob()
    } ).then( data => saveXml( data, 'monthly_summary_report' ) )
        .catch( error => console.log( 'ERROR getMonthlySummaryReport', error ) )
}

export const getOrderListReport = ( company_id ) => {
    fetch( base_url + 'v5/reports/order_list?company_id='
        + company_id,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': getCookie( 'token' )
            }
        } ).then( response => {
        return response.blob()
    } ).then( data => saveXml( data, 'order_history' ) )
        .catch( error => console.log( error ) )
}