import React from 'react';

const PopUp = ( props ) => {
    return (
        <div className="popUp__wrap">
            <div className="popUp">
                <div className="popUp_background"
                     onClick={ () => {
                         props.showPopUp( false )
                     } }>
                </div>
                <div className="popUp_content">
                    <h2>{ props.popUpMessage }</h2>
                    <button className="btn wide_btn beige_btn"
                            onClick={ () => {
                                props.showPopUp( false )
                            } }>{ props.closeBtnTitle }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PopUp;