import React from 'react';

const IconSyringe = () => {
    return (
        <svg aria-hidden="true"
             focusable="false"
             width='100%'
             height='100%'
             role="img"
             viewBox="0 0 512 512">
            <path fill="currentColor"
                  d="M475.7 115.5c3.1 3.1 8.2 3.1 11.3 0l22.6-22.6c3.1-3.1 3.1-8.2 0-11.3L430.5 2.3c-3.1-3.1-8.2-3.1-11.3 0L396.5 25c-3.1 3.1-3.1 8.2 0 11.3l22.6 22.6-33.9 33.9L317.3 25c-3.1-3.1-8.2-3.1-11.3 0l-22.6 22.6c-3.1 3.1-3.1 8.2 0 11.3l11.3 11.3L78.9 286.1c-19 19-28.2 45.2-25.2 72l7.8 69.9-59.2 59c-3.1 3.1-3.1 8.2 0 11.3l11.3 11.3c3.1 3.1 8.2 3.1 11.3 0l59.1-59c74.8 8.3 73 8.3 79.8 8.3 23.2 0 45.4-9.1 62.1-25.8l215.8-215.8 11.3 11.3c3.1 3.1 8.2 3.1 11.3 0L487 206c3.1-3.1 3.1-8.2 0-11.3l-67.9-67.9L453 92.9l22.7 22.6zM192 399.2c-8.6 8.7-20.6 12.9-32.7 11.5l-52.2-5.8-5.8-52.1c-1.3-12.2 2.9-24.1 11.5-32.7l12.2-12.2 28.3 28.3c6.2 6.2 16.4 6.2 22.6 0l11.3-11.3c6.2-6.2 6.2-16.4 0-22.6L158.9 274l33.9-33.9 28.3 28.3c6.2 6.2 16.4 6.2 22.6 0l11.3-11.3c6.2-6.2 6.2-16.4 0-22.6L226.8 206l33.9-33.9 28.3 28.3c6.2 6.2 16.4 6.2 22.6 0L323 189c6.2-6.2 6.2-16.4 0-22.6l-28.3-28.3 33.9-33.9 79.2 79.2L192 399.2z"
                  className="">
            </path>
        </svg>
    );
};

export default IconSyringe;