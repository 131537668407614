import React, { useState } from 'react';
import { useClinics } from "../../../../stores/adminStore";
import { addNewEmployee } from "../../../../requests/rep_selected_clinic_requests";
import { generateScanCode } from "../../../../helperFunctions";

const AddNewEmployee = ( { getEmployeesListFunc } ) => {

    /******************** GLOBAL STORE ********************************/
    const currentClinicId = useClinics( state => state.currentClinicId )
    /******************************************************************/

    const [ newEmployee, setNewEmployee ] = useState( {
        first_name: '',
        last_name: ''
    } )

    const inputsHandler = ( e ) => {
        let name = e.target.name
        let value = e.target.value
        setNewEmployee( { ...newEmployee, [ name ]: value } )
    }

    const addNewEmployeeFunc = () => {
        const data = {
            store_id: currentClinicId,
            name: `${ newEmployee.first_name } ${ newEmployee.last_name }`,
            scan_code: generateScanCode(),
            isEmailToBeSent: false
        }
        addNewEmployee( data ).then( data => {
            if ( 'errors' in data ) {
                console.log( data.errors )
            } else {
                getEmployeesListFunc()
                setNewEmployee( { ...newEmployee, first_name: '', last_name: '' } )
            }
        } )
    }

    return (
        <div className='new_employee_inputs'>
            <div className='new_employee_inputs_wrap'>
                <div className="form_field email">
                    <label htmlFor="first_name">First Name</label>
                    <input type="text"
                           id='first_name'
                           name='first_name'
                           value={ newEmployee.first_name }
                           onChange={ ( e ) => {
                               inputsHandler( e )
                           } }
                           className='form__input'/>
                </div>
                <div className="form_field email">
                    <label htmlFor="last_name">Last Name</label>
                    <input type="text"
                           id='last_name'
                           name='last_name'
                           value={ newEmployee.last_name }
                           onChange={ ( e ) => {
                               inputsHandler( e )
                           } }
                           className='form__input'/>
                </div>
            </div>
            <button className='btn wide_btn beige_btn'
                    disabled={ !(newEmployee.first_name && newEmployee.last_name) }
                    onClick={ () => {
                        addNewEmployeeFunc()
                    } }>Add new employee
            </button>
        </div>
    );
};

export default AddNewEmployee;