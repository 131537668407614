import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import './Login.scss'
import picture from '../../images/VersaPicNew.png'
import logo from '../../images/Vertical_logo.png'
import { forgotPassword, getToken } from "../../requests/requests";
import { setCookie } from "../../helperFunctions";
import PopUp from "../PopUp/PopUp";

const Login = () => {

    const navigate = useNavigate()

    const [ email, setEmail ] = useState( '' )
    const [ password, setPassword ] = useState( '' )
    const [ emailDirty, setEmailDirty ] = useState( false )
    const [ passwordDirty, setPasswordDirty ] = useState( false )
    const [ emailError, setEmailError ] = useState( 'Email cannot be empty' )
    const [ passwordError, setPasswordError ] = useState( 'Password cannot be empty' )
    const [ rememberMe, setRememberMe ] = useState( false )
    const [ formValid, setFormValid ] = useState( false )
    const [ showPopUp, setShowPopUp ] = useState( false )
    const [ popUpMess, setPopUpMess ] = useState( 'PopUp' )

    useEffect( () => {
        if ( emailError || passwordError ) {
            setFormValid( false )
        } else {
            setFormValid( true )
        }
    }, [ emailError, passwordError ] )

    const emailHandler = ( e ) => {
        setEmail( e.target.value )
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        if ( !re.test( String( e.target.value ).toLowerCase() ) ) {
            setEmailError( 'Invalid email' )
        } else {
            setEmailError( '' )
        }
    }

    const passwordHandler = ( e ) => {
        let val = e.target.value
        val = val.replace( /\s+/gi, '' )
        setPassword( val )
        if ( val.length < 8 ) {
            setPasswordError( 'Invalid password. Min 8 characters.' )
            if ( !val ) {
                setPasswordError( 'This is a required field.' )
            }
        } else {
            setPasswordError( '' )
        }
    }

    const blurHandler = ( e ) => {
        switch ( e.target.name ) {
            case 'email':
                setEmailDirty( true )
                break
            case 'password':
                setPasswordDirty( true )
                break
            default:
                setPasswordDirty( false )
                setEmailDirty( false )
        }
    }

    const login = ( e ) => {
        e.preventDefault();
        const data = {
            email: email,
            password: password
        }
        getToken( data )
            .then( admin => {
                if ( !("errors" in admin) ) {
                    if ( (admin.role === 1) || (admin.role === 2) || (admin.role === 3) ) {
                        setCookie( 'token', admin.token, rememberMe ? 30 : 1 )
                        navigate( '/' )
                    } else {
                        window.location.href = "https://prolleniumelite.epoxyapp.com"
                    }
                } else {
                    // setEmailError( response.errors?.v2 )
                    // setPopUpMess( response.errors?.v2 )
                    setPopUpMess( 'The email or password is incorrect, please try again or reset the password' )
                    setShowPopUp( true )
                }
            } )
    }


    return (
        <div className='login'>

            <div className="login__logo">
                <img src={ logo } alt="logo"/>
            </div>

            <div className="login__form">
                <div className='login__form_wrap'>
                    <h1 className="login__form_title">LOGIN</h1>
                    <h2 className="login__form_description">ACCESS YOUR REVANESSE REWARDS PORTAL HERE</h2>

                    <form className='log_form'>
                        <div className='form__email'>
                            <label htmlFor="email">Email address</label>
                            <input type="email"
                                   name='email'
                                   autoComplete='new-password'
                                   id='email'
                                   value={ email }
                                   className='form__input'
                                   onInput={ e => emailHandler( e ) }
                                   onBlur={ e => blurHandler( e ) }
                            />
                            <div className="warning_field">
                                { (emailDirty && emailError) && <span>{ emailError }</span> }
                            </div>
                        </div>

                        <div className='form__password'>
                            <label htmlFor="password">Password</label>
                            <input type="password"
                                   name='password'
                                   id='password'
                                   autoComplete='new-password'
                                   value={ password }
                                   className='form__input'
                                   onInput={ e => passwordHandler( e ) }
                                   onBlur={ e => blurHandler( e ) }
                            />
                            <div className="warning_field">
                                { (passwordDirty && passwordError) && <span>{ passwordError }</span> }
                            </div>
                        </div>

                        <div className="form__forgot_password">
                            <div className={ (email && !emailError) ? 'active' : '' }
                                 onClick={ () => {
                                     if ( email && !emailError ) {
                                         const data = {
                                             email: email,
                                             admin: {
                                                 email: email
                                             }
                                         }
                                         forgotPassword( data ).then(
                                             () => {
                                                 setPopUpMess( 'a new password has been sent to your email' )
                                                 setShowPopUp( true )
                                                 setPassword( '' )
                                             }
                                         )
                                     }
                                 } }>Forgot password?
                            </div>
                        </div>
                        <div className='form__rememberMe'>
                            <input type="checkbox"
                                   id='remember_me'
                                   className='form__checkbox'
                                   checked={ rememberMe }
                                   onChange={ ( e ) => {
                                       setRememberMe( e.target.checked )
                                   } }
                            />
                            <label htmlFor="remember_me">Remember me</label>
                        </div>
                        <div>
                            <button type='submit'
                                    onClick={ (event => login( event )) }
                                    disabled={ !formValid || !email || !password || passwordError }
                                    className='btn black_btn'>Login
                            </button>
                        </div>
                    </form>
                </div>

            </div>

            <div className="login__image">
                <img src={ picture } alt="background"/>
            </div>
            {
                showPopUp
                    ?
                    <PopUp popUpMessage={ popUpMess }
                           closeBtnTitle={ 'Close' }
                           showPopUp={ setShowPopUp }
                           closePopUp={ setShowPopUp }/>
                    :
                    null
            }
        </div>
    );
};

export default Login;