import React, { useEffect, useState } from 'react';
import IconFilter from "../../../Icons/IconFilter";
import { getReimbursement } from "../../../../requests/requests";
import Preloader from "../../../Preloader/Preloader";
import { useAdminStore, useClinics, useCompany } from "../../../../stores/adminStore";
import { getReimbursementRep } from "../../../../requests/requestd_selected_clinic_statistics";

const DashboardSyringesShipped = ( ) => {

    /***************** From global store ******************************/
    const currentClinicId = useClinics( state => state.currentClinicId )
    const companyId = useCompany( state => state.companyId )
    const isRepAdmin = useAdminStore( state => state.isRepAdmin )
    /******************************************************************/

    const requestTypes = [ 'shipped', 'purchased', 'bonus' ]
    const [ preloader, setPreloader ] = useState( true )
    const [ reimbursementShipped, setReimbursementShipped ] = useState( [] )
    const [ sort, setSort ] = useState( {
        field: 'date',
        minToMax: true
    } )

    const addTypeProductByFetch = ( type, arr ) => {
        if ( !arr.length ) {
            return arr
        }
        for ( let el of arr ) {
            el.type_product = type
        }
        return arr
    }

    function sortProductsBy( field, minToMax ) {
        if ( field === 'date' ) {
            return ( a, b ) => Date.parse( a[ field ] ) < Date.parse( b[ field ] ) ? minToMax ? -1 : 1 : minToMax ? 1 : -1
        } else {
            return ( a, b ) => a[ field ] < b[ field ] ? minToMax ? -1 : 1 : minToMax ? 1 : -1
        }
    }

    async function getProduct() {
        const [ shipped, purchased, bonus ] = await Promise.all( [
            getReimbursement( requestTypes[ 0 ], companyId, currentClinicId ),
            // getReimbursement(requestTypes[1], companyId, currentClinicId),
            // getReimbursement(requestTypes[2], companyId, currentClinicId)
        ] )
        return [ ...addTypeProductByFetch( requestTypes[ 0 ], shipped ),
            // ...addTypeProductByFetch(requestTypes[1], purchased),
            // ...addTypeProductByFetch(requestTypes[2], bonus)
        ]
    }

    async function getProductRep() {
        const [ shipped, purchased, bonus ] = await Promise.all( [
            getReimbursementRep( requestTypes[ 0 ], companyId, currentClinicId ),
            // getReimbursement(requestTypes[1], companyId, currentClinicId),
            // getReimbursement(requestTypes[2], companyId, currentClinicId)
        ] )
        return [ ...addTypeProductByFetch( requestTypes[ 0 ], shipped ),
            // ...addTypeProductByFetch(requestTypes[1], purchased),
            // ...addTypeProductByFetch(requestTypes[2], bonus)
        ]
    }

    useEffect( () => {
        setReimbursementShipped( [ ...reimbursementShipped.sort( sortProductsBy( sort.field, sort.minToMax ) ) ] )
    }, [ sort ] )

    useEffect( () => {
        setPreloader( true )
        setReimbursementShipped( [] )
        if(isRepAdmin){
            getProductRep().then( data => {
                data.sort( sortProductsBy( sort.field, sort.minToMax ) )
                setReimbursementShipped( data )
                setPreloader( false )
            } )
        }else{
            getProduct().then( data => {
                data.sort( sortProductsBy( sort.field, sort.minToMax ) )
                setReimbursementShipped( data )
                setPreloader( false )
            } )
        }
    }, [ currentClinicId ] ) // Instead of the line above

    return (
        <div className='reimbursements'>
            <div className='component__wrap'>
                <div className="reimbursements__title dashboard__component_title">
                    <h2>SYRINGES SHIPPED</h2>
                </div>
                <table className="reimbursements__table table">
                    <thead>
                    <tr>
                        <th style={ { cursor: 'pointer' } } onClick={ () => {
                            setSort( { ...sort, field: 'type_product', minToMax: !sort.minToMax } )
                        } }>Type<IconFilter/></th>
                        <th style={ { cursor: 'pointer' } } onClick={ () => {
                            setSort( { ...sort, field: 'quantity', minToMax: !sort.minToMax } )
                        } }>Quantity (syringes)<IconFilter/></th>
                        <th style={ { cursor: 'pointer' } } onClick={ () => {
                            setSort( { ...sort, field: 'date', minToMax: !sort.minToMax } )
                        } }>Ship Date<IconFilter/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        reimbursementShipped.map( ( el, index ) => {
                            return <tr key={ index }>
                                <td>{ el.type_product }<br/>
                                    <span>{ (el.product_name) }</span></td>
                                <td>{ el.quantity }</td>
                                <td>{ el.date }</td>
                            </tr>
                        } )
                    }
                    </tbody>
                </table>
                { reimbursementShipped.length
                    ?
                    ''
                    :
                    <h3 style={ { marginTop: '20px', fontSize: '20px', textAlign: 'center' } }>Nothing found</h3> }
            </div>
            { !preloader || <Preloader/> }
        </div>
    );
};

export default DashboardSyringesShipped;