import create from 'zustand'

const useAdminStore = create( ( set ) => ({
    isRepAdmin: false,
    adminId: null,
    repAdminLogo: '',
    isRoleChecked: false,
    saveRole: ( role ) => set( state => ((role === 3) ? { isRepAdmin: true } : { isRepAdmin: false }) ),
    resetRole: ( role ) => set( state => ({ isRepAdmin: false }) ),
    saveAdminId: ( id ) => set( state => ({ adminId: id }) ),
    setRepAdminLogo: ( url ) => set( state => ({ repAdminLogo: url }) ),
    checkRole: () => set( state => ({ isRoleChecked: true }) )
}) )

const useAdminRegionStore = create( ( set ) => ({
    adminRegionId: null,
    adminRegion: 'not determined',
    setAdminRegionId: ( regId ) => set( state => ({ adminRegionId: regId }) ),
    setAdminRegion: ( region ) => set( state => ({ adminRegion: region }) )
}) )

// const useCurrentClinic = create( ( set ) => ({
//     clinicId: [],
//     currentClinicIndex: 0,
//     setClinicIds: ( arr ) => set( state => ({ clinicIds: arr }) ),
//     setCurrentClinicIndex: ( index ) => set( state => ({ currentClinicIndex: index }) )
// }) )

const useAdminInfoUpdated = create( ( set ) => ({
    isUpdated: false,
    updateAdmin: () => set( state => ({ isUpdated: (!state.isUpdated) }) )
}) )

const useClinics = create( ( set ) => ({
    clinics: [],
    currentClinicId: null,
    setClinics: ( arrClinics ) => set( state => ({ clinics: arrClinics }) ),
    setCurrentClinicId: ( clinicId ) => set( state => ({ currentClinicId: clinicId }) )
}) )

const useCompany = create( ( set ) => ({
    companyId: null,
    setCompanyId: ( id ) => set( state => ({ companyId: id }) )
}) )

export { useAdminStore, useAdminRegionStore, useAdminInfoUpdated, useClinics, useCompany };