import React, { useEffect } from 'react'
import './Dashboard.scss'
import DashboardCards from "./DashboardCards/DashboardCards"
import DashboardPromoBox from "./DashboardPromoBox/DashboardPromoBox"

const Dashboard = ( { company, updateCompanyFunc, purchasingHistory } ) => {

    useEffect( () => {
        updateCompanyFunc()
    }, [] )

    return (
        <div className='dashboard'>
            <div className='component__wrap'>
                <div className="dashboard__title dashboard__component_title">
                    <h2>DASHBOARD</h2>
                </div>
                <DashboardPromoBox/>
                <DashboardCards company={ company }
                                purchasingHistory={ purchasingHistory }/>
            </div>
        </div>
    )
}

export default Dashboard