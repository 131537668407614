import React from 'react';
import './Preloader.scss'

const Preloader = () => {
    return (
        <div className='preloader'>
            <div className="loader">Loading...</div>
        </div>
    );
};

export default Preloader;